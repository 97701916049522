import { createTheme, colors } from '@mui/material';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#f4f6fb',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#FB656B'
    },
    secondary: {
      main: '#FF9DAC'
    },
    green: {
      main: '#3AD9B4'
    },
    text: {
      primary: colors.grey[700],
      secondary: colors.grey[600]
    },
    success: {
      main: '#3AD9B4'
    },
    warning: {
      main: '#FFEC17'
    },
    info: {
      main: '#2DB3CE'
    },
    disabled: {
      main: '#e6edef',
      pink: '#f0c6c6'
    },
    stage: {
      newbiz: '#FF9DAC',
      live: '#3AD9B4',
      complete: '#2DB3CE',
      cancelled: '#FB656B',
      removed: '#000000'
    }
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '13px'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: '2px',
          backgroundColor: 'rgb(244 246 251)'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
          // background: '#fff',
          // border: '2px solid #f8bbd0',
          // color: '#ffffff'
        },
        containedPrimary: {
          color: '#ffffff'
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: 0
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          backgroundColor: '#f4f6f8',
          height: '100%',
          width: '100%'
        },
        a: {
          textDecoration: 'none',
          color: '#717171'
        },
        '#root': {
          height: '100%',
          width: '100%'
        }
      }
    }
  },

  shadows: {
    0: 'none',
    1: 'none',
    2: 'none',
    3: 'none',
    4: 'none',
    5: 'none',
    6: 'none',
    7: 'none',
    8: 'none',
    16: 'none',
    24: 'none'
  },
  typography
});

export default theme;
