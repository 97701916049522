import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
// import { makeStyles } from 'tss-react/mui';
import { makeStyles } from '@mui/styles';
import GoogleDriveUpload from './GoogleDriveUpload';
import GoogleDriveFileTree from './GoogleDriveFileTree';

const useStyles = makeStyles({
  // return {
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  rightIcons: {
    marginLeft: 'auto',
    display: 'flex'
  },
  shareIcon: {
    cursor: 'pointer'
  }
  // };
});

function GoogleDriveFolder(props) {
  // const { classes } = props;
  const classes = useStyles();
  const [driveFolder, setDriveFolder] = useState([]);
  const [uploadedFile, setUploadedFile] = useState('');

  const folderID = props.folderID ? props.folderID : '';

  const uploadCallbackFunction = uploadFile => {
    setUploadedFile(uploadFile.id);
  };

  useEffect(() => {
    if (folderID)
      axios
        .get('/getTheCurrentFolder', {
          params: {
            folderID: folderID
          }
        })
        .then(rest => {
          setDriveFolder(rest.data);
        });
  }, []);

  return (
    <>
      {driveFolder && (
        <Grid item xs={12}>
          {/* <GoogleTestUploadComponent /> */}
          <Card>
            <CardHeader
              title={
                <div className={classes.labelRoot}>
                  <div className={classes.labelText}>
                    {driveFolder && driveFolder.name}
                    {' - '}
                    Google Drive
                  </div>
                  {driveFolder && (
                    <div className={classes.rightIcons}>
                      <Typography variant="caption" color="inherit">
                        <a
                          href={driveFolder.webViewLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {<LaunchIcon />}
                        </a>
                      </Typography>
                    </div>
                  )}
                </div>
              }
            />
            <Divider />
            <CardContent>
              <GoogleDriveFileTree
                key={uploadedFile}
                driveFolder={driveFolder.children}
                folderID={driveFolder.id}
                uploadedFile={uploadedFile}
              />
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <GoogleDriveUpload
                uploadCallback={uploadCallbackFunction}
                buttonName="Upload file"
                folderID={driveFolder.id}
              />
              {/* {driveFolder.id} --  */}
              {/* <GoogleDriveUpload uploadCallback={uploadCallbackFunction} buttonName="Upload file" folderName={driveFolder.name} parent={parent}/> */}
            </Box>
            {/* <GoogleDrivePlayground buttonName="Upload file" folderName={driveFolder.name} parent={parent} /> */}
          </Card>
        </Grid>
      )}
    </>
  );
}
export default GoogleDriveFolder;
