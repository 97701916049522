import React, { useState, useMemo } from 'react';

import MaterialReactTable from 'material-react-table';

import { useMutation } from '@apollo/client';
import { updateRates } from 'src/queries/query';

const RatesDetails = ({
  className,
  id,
  department,
  rates,
  var_approved,
  ...rest
}) => {
  const [datarates, setDatarates] = useState(rates);

  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);

  const [update_Rates, { error: mutationError }] = useMutation(updateRates, {
    onCompleted() {
      console.log('update_Rates Completed');
    },
    onError: err => {
      setError(err);
      console.log(err);
    }
  });

  const handleSaveCell = (cell, value) => {
    console.log(cell.row.original._id);
    // datarates[cell.row.index][cell.column.id] = value;

    // 1. Make a shallow copy of the items
    const rateitems = [...datarates];
    // 2. Make a shallow copy of the rateitem you want to mutate
    const rateitem = { ...rateitems[cell.row.index] };
    // 3. Replace the property you're intested in
    rateitem[cell.column.id] = value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here,
    //    but that's why we made a copy first
    rateitems[cell.row.index] = rateitem;
    // 5. Set the state to our new copy

    update_Rates({
      variables: {
        ref: cell.row.original._id,
        value: parseFloat(value)
      }
    });
    setDatarates([...rateitems]); //set new data
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'role',
        header: 'Role',
        enableEditing: false
      },
      {
        accessorKey: 'perhour',
        header: 'Rate',
        enableEditing: !var_approved,
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </>
        )
      }
    ],
    []
  );

  return (
    <>
      {/* https://www.material-react-table.com/docs/guides/editing#cell-editing-mode */}
      <MaterialReactTable
        columns={columns}
        data={datarates}
        editingMode="cell"
        enableEditing
        enablePagination={false}
        enableColumnActions={false}
        enableDensityToggle={false}
        initialState={{ density: 'compact' }}
        enableFullScreenToggle={false}
        enableHiding={false}
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          //onBlur is more efficient, but could use onChange instead
          onBlur: event => {
            handleSaveCell(cell, event.target.value);
          }
        })}
      />
    </>
  );
};

export default RatesDetails;
