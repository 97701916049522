import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import { Mutation } from '@apollo/client/react/components';

import gql from 'graphql-tag';

import { useMutation, useQuery } from '@apollo/client';
import { addRates } from 'src/queries/query';

// const get_SubDepartments = gql`
//   query Departments($is_sub_department: Boolean) {
//     Departments(is_sub_department: $is_sub_department) {
//       _id
//       name
//     }
//   }
// `;

const get_SubDepartments = gql`
  query Departments($is_sub_department: Boolean) {
    Departments(search: { is_sub_department: $is_sub_department }) {
      data {
        _id
        name
      }
    }
  }
`;

const get_DepartmentRole = gql`
  query Departments($id: ID) {
    Departments(search: { _id: $id }) {
      data {
        roles {
          title
          _id
        }
      }
    }
  }
`;

const ProfileDetails = ({ className, rate_card_id, ...rest }) => {
  const [state, setState] = useState({
    department_reference: '',
    role_reference: '',
    skip_query: true,
    roles: null
  });

  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const [open, setOpen] = React.useState(false);

  //--------------------------------------------------------------------------
  // Gettine Sub-Department Data
  //--------------------------------------------------------------------------

  const { data } = useQuery(get_SubDepartments, {
    variables: { is_sub_department: true }
  });
  const dataDep = data;
  //--------------------------------------------------------------------------
  // End Gettine Sub-Department Data
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  // Gettine Role data based on selected Sub-Department
  //--------------------------------------------------------------------------
  // Used skip method
  //
  const { role_data } = useQuery(get_DepartmentRole, {
    skip: state.skip_query,
    variables: { id: state.department_reference },
    onCompleted: data => {
      setState({
        ...state,
        roles: data
      });
    }
  });

  const [add_Rates, { error: mutationError }] = useMutation(addRates, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        setShowBtns(true);
        navigate('/ratecards/ratecard/' + rate_card_id);
      }, 2500);
    },
    onError: err => {
      setError(err);
      // setErrorMessage(error.message.split('GraphQL error: ')[1])
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Mutation mutation={addRates}>
        {(addRates, { loading, error }) => (
          <form
            autoComplete="off"
            noValidate
            {...rest}
            onSubmit={e => {
              e.preventDefault();
              add_Rates({
                variables: {
                  ref: rate_card_id,
                  data: {
                    department_reference: state.department_reference,
                    department_name: e.target.department_name.value,
                    role: e.target.role_title.value,
                    perhour: parseFloat(e.target.perhour.value)
                  }
                }
              });
            }}
          >
            <Card>
              <CardHeader
                subheader="The information can be edited"
                title="Add New Rate"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {/* Control for the sub-departments */}
                  <Grid item md={6} xs={12}>
                    {dataDep && (
                      <Autocomplete
                        id="departmentlist"
                        options={dataDep.Departments.data}
                        getOptionLabel={option => option.name}
                        onChange={(event, values) => {
                          if (values) {
                            setState({
                              ...state,
                              department_reference: values._id,
                              skip_query: false
                            });
                          } else {
                            setState({
                              ...state,
                              department_reference: ''
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Department Name"
                            name="department_name"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </Grid>
                  {/* Rate TextField        */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Rate perhour"
                      name="perhour"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  {/* Control for Roles of the selected sub-department */}
                  <Grid item xs={12}>
                    {state.roles && state.roles.Departments && (
                      <Autocomplete
                        id="roleslist"
                        options={state.roles.Departments.data[0].roles}
                        getOptionLabel={option => option.title}
                        onChange={(event, values) => {
                          if (values) {
                            setState({
                              ...state,
                              role_reference: values._id
                            });
                          } else {
                            setState({
                              ...state,
                              role_reference: ''
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Role Title"
                            name="role_title"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {askareyousure ? (
                  <button
                    type="submit"
                    id="button"
                    className={`btn-animate ${isloading ? 'onclic' : null} ${
                      !isloading && completed ? 'validate' : null
                    } ${iserror ? 'novalidate' : null}`}
                  ></button>
                ) : (
                  <button
                    onClick={ask}
                    id="ask"
                    className={`btn-animate ask`}
                  ></button>
                )}
              </Box>
            </Card>
          </form>
        )}
      </Mutation>
    </>
  );
};

export default ProfileDetails;
