import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@apollo/client';
import AuthContext from 'src/components/AuthContext';
import gql from 'graphql-tag';
import TimesheetForm from './TimesheetForm';

const CurrentJobsQuery = gql`
  query DashboardJobs($id: String) {
    DashboardJobs(user_reference: $id) {
      _id
      subscription_key
      title
      url
      description
      cloud_portal
      job_type
      retainer_budget
      allotted_budget
      container_ref
      container_title
      stage
      department
      start_date
      end_date
      owner_reference
      owner_name
      ratecard_reference
      estimated_time
      estimated_ftc
      total_revrec
      available_for_estimate
      available_for_revrec
      supplier_po_total
      client {
        client_name
      }
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      justifyContent: 'flex-end'
    }
  };
});

const CurrentJobs = ({ className, postoperationfnc, ...rest }) => {
  const { classes, cx } = useStyles();
  const { userData } = useContext(AuthContext);
  const [currentJobsList, setCurrentJobsList] = useState([]);

  const [activeRow, setActiveRow] = useState('');
  const [job_id, setJob_id] = useState('');
  const [job_title, setJob_title] = useState('');
  const [open, setOpen] = useState(false);

  const CurrentJobsResults = useQuery(CurrentJobsQuery, {
    variables: { id: userData._id }
  });

  useEffect(() => {
    if (!CurrentJobsResults.loading) {
      setCurrentJobsList(CurrentJobsResults.data.DashboardJobs);
    }
  }, [CurrentJobsResults.data]);

  const handleClickOpen = row => {
    setJob_id(row._id);
    setJob_title(row.title);
    setActiveRow(row);
    setOpen(true);
  };
  const handleClose = () => {
    setJob_id(0);
    setJob_title('');
    setActiveRow([]);
    setOpen(false);
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <CardHeader title="Current Jobs" />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Container</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Team Leader</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>TX Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentJobsList &&
              currentJobsList.map((job, i) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <Link to={`/jobunits/jobunit/${job._id}`}>{job.title}</Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/jobs/job/${job.container_ref}`}>
                      {job.container_title}
                    </Link>
                  </TableCell>
                  <TableCell>{job.client.client_name}</TableCell>
                  <TableCell>{job.owner_name}</TableCell>
                  <TableCell>{job.stage}</TableCell>
                  <TableCell>
                    {job.end_date
                      ? moment.unix(job.end_date / 1000).format('DD.MM.YYYY')
                      : '-'}
                  </TableCell>

                  <TableCell align={'right'} style={{ width: '200px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickOpen(job)}
                    >
                      Add Timesheet
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      {activeRow && (
        <TimesheetForm
          open={open}
          handleClose={handleClose}
          activeRow={activeRow}
          operation="Add"
          postoperationfnc={postoperationfnc}
          job_id_param={job_id}
          job_title_param={job_title}
        />
      )}
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

CurrentJobs.propTypes = {
  className: PropTypes.string
};

export default CurrentJobs;
