import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Client, addRatecard } from 'src/queries/query';

const ProfileDetails = ({ className, ...rest }) => {
  //--------------------------------------------------------------------------
  // Setting States Variables
  //--------------------------------------------------------------------------
  //
  //
  //

  const navigate = useNavigate();
  const [client_reference, setClient_reference] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const [open, setOpen] = React.useState(false);

  const [add_Ratecard, { error: mutationError }] = useMutation(addRatecard, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        setShowBtns(true);
        navigate('/ratecards/');
      }, 2500);
    },
    onError: err => {
      setError(err);
      // setErrorMessage(error.message.split('GraphQL error: ')[1])
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <form
        autoComplete="off"
        // noValidate //
        {...rest}
        onSubmit={e => {
          console.log('submit called');
          e.preventDefault();
          add_Ratecard({
            variables: {
              ref: '',
              data: {
                subscription_key: '5df7b2704fa93209ad8eca03',
                title: e.target.title.value,
                // version: e.target.version.value,
                // parent_reference: this.state.client_reference,
                client_reference: client_reference,
                client_name: e.target.client_name.value,
                note: e.target.note.value
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Add New Ratecard"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the title"
                  label="Title"
                  name="title"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Query query={Client}>
                  {({ loading, data }) =>
                    !loading && (
                      <Autocomplete
                        id="clientlist"
                        options={data.Client}
                        getOptionLabel={option => option.name}
                        onChange={(event, values) => {
                          if (values) {
                            setClient_reference(values._id);
                          } else {
                            setClient_reference(null);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Client Name"
                            name="client_name"
                            variant="outlined"
                          />
                        )}
                      />
                    )
                  }
                </Query>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Note"
                  name="note"
                  type="textarea"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
