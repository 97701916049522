import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  Table,
  TableCell,
  TextField,
  TableHead,
  TableRow
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { useMutation } from '@apollo/client';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Alert, AlertTitle } from '@mui/lab';

import moment from 'moment';

import gql from 'graphql-tag';

// Setting Up Queries

const Users = gql`
  query Users {
    Users {
      _id
      first_name
      last_name
      email
      designation
    }
  }
`;

const addFTC = gql`
  mutation addFTC($ref: ID!, $title: String!, $cost: Float!) {
    addFTC(ref: $ref, data: { title: $title, cost: $cost }) {
      _id
      estimated_ftc
      fixed_task_cost {
        _id
        title
        cost
        created
        user_reference
        user_name
      }
    }
  }
`;

const removeFTC = gql`
  mutation removeFTC($ref: ID!) {
    removeFTC(ref: $ref) {
      _id
      estimated_ftc
      fixed_task_cost {
        _id
        title
        cost
        created
        user_reference
        user_name
      }
    }
  }
`;

const updateFTC = gql`
  mutation updateFTC($ref: ID!, $title: String!, $cost: Float!) {
    updateFTC(ref: $ref, data: { title: $title, cost: $cost }) {
      _id
      estimated_ftc
      fixed_task_cost {
        _id
        title
        cost
        created
        user_reference
        user_name
      }
    }
  }
`;

const TeamDetails = ({ className, element, stage, ...rest }) => {
  // Default form's state values for adding a team member

  const formDefaults = {
    title: '',
    cost: 0
  };

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [formState, setFormState] = useState(formDefaults);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [editRow, setEditRow] = useState(false);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.FixedTaskCostInputDetails === false ? false : true
  );

  //-----------------------
  const [rowID, setrowID] = useState(null);
  const [openDel, setOpenDel] = useState(false);

  //-----------------------

  const jobref = element[0]._id;
  const [errorallert, setErrorallert] = useState('');

  const submitRecord = () => {
    if (formState.title && formState.cost !== 0) {
      add_Team({
        variables: {
          ref: jobref,
          title: formState.title,
          cost: parseFloat(formState.cost)
        }
      });
      // setFormState(formDefaults);
      setAskareyousure(false);
    } else {
      setErrorMsg('Please add the necessary information');
      // setOpendialog(true);
    }
  };

  const [add_Team] = useMutation(addFTC, {
    onCompleted() {
      setFormState(formDefaults);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      console.log(msg);
      setErrorallert(msg);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };
  //---------------------------------------
  // Remove
  //---------------------------------------
  const handleClickOpenDel = id => {
    setrowID(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setrowID(null);
    setOpenDel(false);
  };

  const handleYesDel = () => {
    if (rowID) {
      console.log(' delete row called for ' + rowID);
      remove_FTC({
        variables: {
          ref: rowID
        }
      });
    }
    setOpenDel(false);
  };

  const [remove_FTC] = useMutation(removeFTC, {
    onCompleted() {
      console.log('row deleted');
      handleCloseDel();
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      setErrorallert(msg);
    }
  });

  //---------------------------------------
  // Remove Ends
  //---------------------------------------

  //---------------------------------------
  // Edit
  //---------------------------------------

  const handleOpenEditDialog = (_id, title, cost) => {
    setrowID(_id);
    setFormState({
      title: title,
      cost: cost
    });
    setEditRow(true);
  };

  const handleCloseEditDialog = () => {
    setEditRow(false);
  };

  const handleSaveEditDialog = () => {
    updateRow();
    setEditRow(false);
  };

  const updateRow = () => {
    if (formState.title && formState.cost !== 0) {
      update_FTC({
        variables: {
          ref: rowID,
          title: formState.title,
          cost: parseFloat(formState.cost)
        }
      });
      setFormState(formDefaults);
    } else {
      console.log('fail!');
    }
  };

  const [update_FTC] = useMutation(updateFTC, {
    onCompleted(data) {
      console.log('DATA', data);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      setErrorallert(msg);
      setOpendialog(true);
      setErrorMsg(msg);
    }
  });

  const hideError = () => {
    setErrorMsg('');
    setErrorallert(false);
  };
  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.FixedTaskCostInputDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };
  // Form Query Actions end

  //---------------------------------------
  // Edit Ends
  //---------------------------------------

  return (
    <Card>
      <CardHeader
        // subheader="The information can be edited"
        title="Fixed Tasks Cost"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {element[0].fixed_task_cost.length > 0 && (
          <>
            <Divider />
            <Grid item md={12} xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Added by</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {element[0].fixed_task_cost.map(
                    item =>
                      item.cost && (
                        <TableRow hover key={item.title}>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>{item.cost}</TableCell>
                          <TableCell>{item.user_name}</TableCell>
                          <TableCell>
                            {moment
                              .unix(item.created * 0.001)
                              .format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell>
                            <IconButton aria-label="edit" size="small">
                              <DeleteIcon
                                onClick={() => handleClickOpenDel(item._id)}
                                style={{ cursor: 'pointer' }}
                              />
                            </IconButton>
                            <IconButton aria-label="edit" size="small">
                              <EditRoundedIcon
                                onClick={() =>
                                  handleOpenEditDialog(
                                    item._id,
                                    item.title,
                                    item.cost
                                  )
                                }
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableHead>
              </Table>
            </Grid>
          </>
        )}
        {/* Dialog for SPO Edit */}
        <Dialog
          open={editRow}
          onClose={handleCloseEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Edit FTC
            </DialogContentText>

            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  variant="outlined"
                  value={formState.title}
                  onChange={changeHandler}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Cost"
                  name="cost"
                  variant="outlined"
                  value={formState.cost || ''}
                  onChange={changeHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary" autoFocus>
              Close
            </Button>
            <Button onClick={handleSaveEditDialog} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for FTC Edit */}
        {/* Dialog For Delete Confirmation */}
        <Dialog
          open={openDel}
          onClose={handleCloseDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this row
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDel} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleYesDel} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog For Delete Confirmation End */}
        {stage !== 'complete' && (
          <CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    variant="outlined"
                    value={formState.title}
                    onChange={changeHandler}
                  />
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    label="Cost"
                    name="cost"
                    variant="outlined"
                    value={formState.cost || ''}
                    onChange={changeHandler}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={2}
            >
              {errorallert && (
                <Alert severity="error" onClose={hideError}>
                  <AlertTitle>{errorallert}</AlertTitle>
                </Alert>
              )}
              {askareyousure ? (
                <button
                  onClick={submitRecord}
                  id="button"
                  className={`btn-animate ${isloading ? 'onclic' : null} ${
                    !isloading && completed ? 'validate' : null
                  } ${iserror ? 'novalidate' : null}`}
                ></button>
              ) : (
                <button
                  onClick={ask}
                  id="ask"
                  className={`btn-animate ask`}
                ></button>
              )}
            </Box>
            <Divider />
          </CardContent>
        )}
      </Collapse>
    </Card>
  );
};

export default TeamDetails;
