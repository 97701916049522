import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';

import { Mutation } from '@apollo/client/react/components';
import { updateSupplier } from 'src/queries/query';

const ProfileDetails = ({ className, element, ...rest }) => {
  return (
    <Mutation mutation={updateSupplier}>
      {(updateSupplier, { loading, error }) => (
        <form
          autoComplete="off"
          noValidate
          {...rest}
          onSubmit={e => {
            e.preventDefault();
            updateSupplier({
              variables: {
                ref: element._id,
                data: {
                  company_name: e.target.company_name.value,
                  first_name: e.target.first_name.value,
                  last_name: e.target.last_name.value,
                  email: e.target.email.value,
                  phone: e.target.phone.value,
                  fax: e.target.fax.value,
                  address_line1: e.target.address_line1.value,
                  address_line2: e.target.address_line2.value,
                  city: e.target.city.value,
                  county: e.target.county.value,
                  country: e.target.country.value,
                  postcode: e.target.postcode.value,
                  url: e.target.url.value,
                  other: e.target.other.value
                }
              }
            });
          }}
        >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
              title="Profile"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the first name"
                    label="Company Name"
                    name="company_name"
                    required
                    defaultValue={element.company_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the first name"
                    label="First Name"
                    name="first_name"
                    required
                    defaultValue={element.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the first name"
                    label="Last Name"
                    name="last_name"
                    required
                    defaultValue={element.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    required
                    defaultValue={element.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    type="number"
                    defaultValue={element.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Fax"
                    name="fax"
                    defaultValue={element.fax}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="address_line1"
                    type="text"
                    defaultValue={element.address_line1}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="address_line2"
                    type="text"
                    defaultValue={element.address_line2}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    type="text"
                    defaultValue={element.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="County"
                    name="county"
                    type="text"
                    defaultValue={element.county}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    type="text"
                    defaultValue={element.country}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="postcode"
                    type="text"
                    defaultValue={element.postcode}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="URL"
                    name="url"
                    type="text"
                    defaultValue={element.url}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Other Info"
                    name="other"
                    type="textarea"
                    defaultValue={element.other}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Mutation>
  );
};

export default ProfileDetails;
