import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const Account = () => {
  const { classes } = useStyles();
  const clientId = useParams();

  return (
    <Page className={classes.root} title="New Job">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProfileDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
