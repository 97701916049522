import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';

import { useQuery } from '@apollo/client';

import { User } from 'src/queries/query';

import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Toolbar from './Toolbar';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const SingleUser = () => {
  const { classes } = useStyles();
  const elementId = useParams();

  console.log('elementId', elementId);

  const { data: dataUser, loading: loadingUser, error: errorUser } = useQuery(
    User,
    {
      variables: { id: elementId.id },
      fetchPolicy: 'no-cache'
    }
  );

  // callback for user query
  useEffect(() => {
    const onCompleted = dataUser => {
      console.log('query dataUser', dataUser.User);
    };
    const onError = errorUser => {
      console.log('query errorUser', errorUser);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingUser && !errorUser) {
        onCompleted(dataUser);
      } else if (onError && !loadingUser && errorUser) {
        onError(errorUser);
      }
    }
  }, [loadingUser, dataUser, errorUser]);

  return (
    <Page className={classes.root} title="User">
      {!loadingUser && dataUser && (
        <Container maxWidth="lg">
          <Toolbar id={elementId.id} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Profile element={dataUser.User} />
            </Grid>
            <Grid item xs={12}>
              <ProfileDetails element={dataUser.User} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default SingleUser;
