import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useMutation } from '@apollo/client';
import { addClient } from 'src/queries/query';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({ className, ...rest }) => {
  const link = '/clients/add-new';
  const linkList = '/clients';

  const [state, setState] = useState({
    usePo: false
  });

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const [add_Client, { error: mutationError }] = useMutation(addClient, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        setShowBtns(true);
      }, 2500);
    },
    onError: err => {
      setError(err);
      // setErrorMessage(error.message.split('GraphQL error: ')[1])
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });
  const handleChangeSwitch = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form
        {...rest}
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_Client({
            variables: {
              ref: '',
              data: {
                subscription_key: '5df7b2704fa93209ad8eca03',
                name: e.target.name.value,
                use_po: state.usePo,
                company_contact_info: {
                  email: e.target.email.value,
                  phone: e.target.phone.value,
                  fax: e.target.fax.value,
                  address_line1: e.target.address_line1.value,
                  address_line2: e.target.address_line2.value,
                  city: e.target.city.value,
                  county: e.target.county.value,
                  country: e.target.country.value,
                  postcode: e.target.postcode.value,
                  url: e.target.url.value,
                  fb: e.target.fb.value,
                  twitter: e.target.twitter.value,
                  instagram: e.target.instagram.value,
                  youtube: e.target.youtube.value,
                  other: e.target.other.value
                }
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Add New Client"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the name"
                  label="Client Name"
                  name="name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.usePo}
                          onChange={handleChangeSwitch}
                          name="usePo"
                        />
                      }
                      label="Uses Po"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fax"
                  name="fax"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 1"
                  name="address_line1"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 2"
                  name="address_line2"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="City"
                  name="city"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="County"
                  name="county"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Postcode"
                  name="postcode"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="URL"
                  name="url"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Facebook"
                  name="fb"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Twitter"
                  name="twitter"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Instagram"
                  name="instagram"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="YouTube"
                  name="youtube"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Other Info"
                  name="other"
                  type="textarea"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            p={2}
          >
            {showBtns && (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  component={RouterLink}
                  to={link}
                >
                  Add New User
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  component={RouterLink}
                  to={linkList}
                >
                  Clients List
                </Button>
              </>
            )}

            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              />
            ) : (
              <button onClick={ask} id="ask" className={`btn-animate ask`} />
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
