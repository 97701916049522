import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { updateUser } from 'src/queries/query';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import AuthContext from 'src/components/AuthContext';
import GoogleDriveUpload from '../../../components/googleComponents/GoogleDriveUpload';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'London',
  country: 'UK',
  jobTitle: 'Senior Developer',
  // name: 'Doriana Ciccarelli',
  timezone: 'GTM'
};

const useStyles = makeStyles()(() => {
  return {
    root: {},
    avatar: {
      borderRadius: '50%',
      height: 100,
      width: 100
    }
  };
});

const Profile = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const [userAvatar, setUserAvatar] = useState(user.avatar);
  const [avatarID, setAvatarID] = useState('');
  const [userID, setUserID] = useState('');
  const { userData } = useContext(AuthContext);

  const [update_User] = useMutation(updateUser);

  useEffect(() => {
    console.log(userData, 'userData');
    if (userData._id) setUserID(userData._id);
    if (userData.thumbnail) {
      const thumburl = JSON.parse(userData.thumbnail).src;
      console.log(thumburl, 'thumburl123');
      setUserAvatar(thumburl);
    }

    // var localStorageAvatar = localStorage.getItem('gResp.imageUrl');
    // console.log('localStorageAvatar', localStorageAvatar);
    // setUserAvatar(localStorageAvatar);
  }, [userData]);

  const uploadCallbackFunction = async uploadFile => {
    removeAvatar(avatarID, true);
    setUserAvatar(uploadFile.thumbnailLink);
    updateUserAvatar(uploadFile);
    setAvatarID(uploadFile.id);
    setTimeout(() => {
      setUserAvatar(uploadFile.thumbnailLink);
    }, 3000);
  };

  const updateUserAvatar = uploadFile => {
    // var thumbSrc = uploadFile.thumbnailLin? uploadFile.thumbnailLin: '';
    // var thumbId = uploadFile.id? uploadFile.id : '';
    // uploadFile
    const thumbnailJSON = uploadFile
      ? JSON.stringify({ src: uploadFile.thumbnailLink, id: uploadFile.id })
      : '';

    console.log('SAVE TO DB', userID, thumbnailJSON);
    update_User({
      variables: {
        ref: userID,
        data: {
          thumbnail: thumbnailJSON
        }
      }
    });
  };

  const removeAvatar = (imgID, dontClear) => {
    axios
      .delete('/deleteFile', {
        params: {
          fileID: imgID
        }
      })
      .then(res => {
        if (!dontClear) {
          updateUserAvatar();
          setUserAvatar(user.avatar);
          setAvatarID('');
        }
        // console.log("DELETED")
        // console.log(res.data)
      });
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          {/* <Avatar className={classes.avatar} src={userAvatar} /> */}
          <img className={classes.avatar} src={userAvatar} alt="avatar" />
          {avatarID && (
            <div
              onClick={() => removeAvatar(avatarID)}
              style={{ cursor: 'pointer' }}
            >
              &#xd7;
            </div>
          )}
          {/* <AuthContext.Consumer>
            {({ getUserData }) => {
              var userData = getUserData();
              var username = user.name;
              if (typeof userData != 'undefined') {
                username = userData.name;
                var user_ref = userData.user_ref;
                setUserID(userData.user_ref);
                userData.thumbnail && setUserAvatar(userData.thumbnail);
                return (
                  <Query query={User} variables={{ id: user_ref }}>
                    {({ loading, data }) => {
                      var name = '';
                      if (!loading) {
                        var userData = data.User[0];
                        try {
                          var thumb = JSON.parse(userData.thumbnail);
                          thumb && thumb.src && setUserAvatar(thumb.src);
                          thumb && thumb.id && setAvatarID(thumb.id);
                        } catch (e) {}
                      }
                      return <React.Fragment>123</React.Fragment>;
                    }}
                  </Query>
                );
              }
            }}
          </AuthContext.Consumer> */}

          {/*<AuthContext.Consumer>
            {({ userData }) => {
              // var userData = userData;
              console.log(userData, 'userData');
              if (typeof userData != 'undefined') {
                var user_ref = userData.user_ref;
                return (
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    <Query query={User} variables={{ id: user_ref }}>
                      {({ loading, data }) => {
                        var name = '';
                        if (!loading) {
                          var userData = data.User[0];
                          name = userData.first_name + ' ' + userData.last_name;
                        }
                        return <React.Fragment>{name}</React.Fragment>;
                      }}
                    </Query>
                  </Typography>
                );
              }
            }}
          </AuthContext.Consumer>*/}
          {/* {userAvatar} */}

          <Typography color="textPrimary" gutterBottom variant="h3">
            {userData.first_name} {userData.last_name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user.city} ${user.country}`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <GoogleDriveUpload
          uploadCallback={img => uploadCallbackFunction(img)}
          buttonName="Upload Picture"
          folderName="Accounts/Users"
          variant="text"
        />
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
