import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { updateUser } from '../queries/query';

const loginPath = '/login';

const User = gql`
  query User($id: ID!) {
    User(_id: $id) {
      _id
      subscription_key
      first_name
      last_name
      thumbnail
    }
  }
`;

function CheckAuth(props, ref) {
  const defaultUser = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: 'UK',
    thumbnail: ''
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const sendUserData = props.setUserData;
  const [userRef, setUserRef] = useState('');
  const [userData, setUserData] = useState(defaultUser);
  const googleUser = JSON.parse(localStorage.getItem('googleUser'));

  const authToken = localStorage.getItem('authToken');

  const { data } = useQuery(User, {
    variables: { id: userRef }
  });

  // restrictedRoutes
  const allowedRoutes = [
    '/restricted',
    '/dashboard',
    '/users',
    '/clients',
    '/departments',
    '/ratecards',
    '/jobs',
    '/jobunits',
    '/suppliers',
    '/account',
    '/settings'
  ];

  const restrictedRoutes = ['/suppliers'];

  localStorage.setItem('restrictedRoutes', restrictedRoutes);
  localStorage.setItem('allowedRoutes', allowedRoutes);
  const [update_User] = useMutation(updateUser, {
    onCompleted() {},
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: NotesList }]
  });
  function testUserData(userData) {
    if (googleUser) {
      // console.log('icokejh', userData);
      // console.log('googleUser', googleUser);
      const thumbnail = JSON.parse(userData.thumbnail);
      if (
        !(
          userData.firstName &&
          userData.lastName &&
          userData.email &&
          thumbnail.src
        )
      ) {
        // console.log('googleUser.thumbnail', googleUser.thumbnail);
        const googleUserThumbnail = JSON.stringify({
          src: googleUser.thumbnail
        });
        // console.log('googleUserThumbnail', googleUserThumbnail);

        const data = {
          first_name: userData.first_name
            ? userData.first_name
            : googleUser.firstName,
          last_name: userData.last_name
            ? userData.last_name
            : googleUser.lastName,
          email: userData.email ? userData.email : googleUser.email,
          // thumbnail:
          //   thumbnail && thumbnail.src
          //     ? userData.thumbnail
          //     : googleUserThumbnail,
          thumbnail: googleUserThumbnail
        };

        // console.log('datadatadatadata', userData);
        // console.log('datadatadatadata', googleUserThumbnail);
        // console.log('datadatadatadata', data);

        update_User({
          variables: {
            ref: userData._id,
            data: data
          }
        });
      }
    }
    return userData;
  }

  // Logout function - removes the Token and redirects to the login page
  const doLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('googleUser');
    if (pathname !== loginPath) {
      window.location.href = loginPath;
    }
  };
  // [1] First check if there is a authToken, if not, logout
  useEffect(() => {
    // setUserRef('5fd3d0a5d2522906b70ef19f');
    // return;
    if (!authToken) {
      console.log('Missing out token - logout 1');
      doLogout();
      //   setUserData(defaultUser);
    } else {
      axios
        .get('getUserData', {
          headers: {
            'x-access-token': authToken
          }
        })
        .then(response => {
          setUserRef(response.data.user_ref);
        })
        .catch(function(error) {
          console.log(
            "Axious didn't get info from getUserData from server - logout 2"
          );
          doLogout();
          return false;
        });
    }
  }, []);

  // [2] If there is a token, get the user data, if not, logout.
  useEffect(() => {
    if (data && data.User !== userData) {
      // console.log('data.User');
      const newUserData = testUserData(data.User);
      setUserData(newUserData);
    } else {
      console.log(
        "User data does not exist in the database or hasn't loaded yet - waiting for the real user, setting the default user"
      );
      //   doLogout();
      setUserData(defaultUser);
    }
  }, [data]);

  // [3] If there is user data, navigate to the Dashboard, if not, logout
  useEffect(() => {
    if (authToken) {
      sendUserData(userData);
      if (pathname === loginPath) navigate('/');
    } else {
      console.log('Local storage missing authToken - logout 4');
      //   doLogout();
      //   if (pathname !== loginPath) navigate(loginPath);
    }
  }, [userData]);

  //   Function calls from outside
  useImperativeHandle(
    ref,
    () => ({
      LogoutUser: () => {
        navigate(loginPath);
        sendUserData(defaultUser);
        axios
          .get('logOut', {
            headers: {
              'x-access-token': authToken
            }
          })
          .then(response => {
            doLogout();
            return response.data;
          })
          .catch(function(error) {
            return error;
          });
      },
      loginUser: () => {
        if (pathname === loginPath) {
          window.location.href = '/';
        }
      }
    }),
    []
  );

  // Test button function
  const buttonTask = () => {
    console.log('userRef', userRef);
    console.log('userData', userData);
    //   sendUserData(userData);
  };

  return (
    <>
      {/* <button
        style={{ position: 'absolute', zIndex: '10000' }}
        onClick={() => buttonTask()}
      >
        Check User Data
      </button> */}
    </>
  );
}
export default forwardRef(CheckAuth);
