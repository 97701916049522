import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const Jobs = gql`
  query JobsList($search: jobListArgs, $ops: functionalArgs) {
    JobsList(search: $search, ops: $ops) {
      info {
        total_records
        restricted_fields
      }
      data {
        _id
        title
        stage
        client {
          client_name
        }
        department
        end_date
        start_date
        container_title
        container_ref
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const SubResults = ({ className, stagevalue, ...rest }) => {
  const { classes, cx } = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  const [restrictedfields, setRestrictedfields] = useState('');

  // get Job Containers by Stage (stagevalue)
  const {
    loading: loadingJobs,
    data: dataJobs,
    error: errorJobs,
    refetch
  } = useQuery(Jobs, {
    variables: {
      search: { stage: stagevalue },
      ops: { page: 0, perpage: 10 }
    },
    fetchPolicy: 'no-cache'
  });

  // callback for Job Containers query
  useEffect(() => {
    const onCompleted = dataJobs => {
      setLength(dataJobs.JobsList.info.total_records);
      setRestrictedfields(dataJobs.JobsList.info.restricted_fields);
    };
    const onError = errorJobs => {
      console.log('query errorJobs', errorJobs);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingJobs && !errorJobs) {
        onCompleted(dataJobs);
      } else if (onError && !loadingJobs && errorJobs) {
        onError(errorJobs);
      }
    }
  }, [loadingJobs, dataJobs, errorJobs]);

  const handleLimitChange = event => {
    setPage(0);
    setLimit(event.target.value);
    refetch({
      search: { stage: stagevalue },
      ops: { page: 0, perpage: event.target.value }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    refetch({
      search: { stage: stagevalue },
      ops: { page: newPage, perpage: limit }
    });
  };

  // const currencyformatter = new Intl.NumberFormat('en-UK', {
  //   style: 'currency',
  //   currency: 'GBP',
  //   maximumFractionDigits: 0
  // });

  return (
    <Card elevation={0} className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Container</TableCell>
              <TableCell>Client</TableCell>

              {!restrictedfields.includes('department') && (
                <TableCell>Department</TableCell>
              )}
              <TableCell>TX Date</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataJobs &&
              dataJobs.JobsList.data.map(element => (
                <TableRow hover key={element._id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={element.avatarUrl}
                      >
                        {getInitials(element.title)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <Link to={`/jobunits/jobunit/${element._id}`}>
                          {element.title}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Link
                      to={{
                        pathname: `../jobs/job/${element.container_ref}`
                      }}
                    >
                      {element.container_title}
                    </Link>
                  </TableCell>
                  <TableCell>{element.client.client_name}</TableCell>
                  {!restrictedfields.includes('department') && (
                    <TableCell>{element.department}</TableCell>
                  )}

                  <TableCell>
                    {element.end_date
                      ? new Date(
                          parseInt(element.end_date)
                        ).toLocaleDateString()
                      : '-'}
                  </TableCell>

                  <TableCell>
                    <Link to={`/jobunits/jobunit/${element._id}`}>
                      <VisibilityIcon style={{ color: '#bdbdbd' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

SubResults.propTypes = {
  className: PropTypes.string
};

export default SubResults;
