import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    importButton: {
      marginRight: theme.spacing(1),
      marginLeft: 'auto'
    },
    exportButton: {
      marginRight: theme.spacing(1)
    }
  };
});

const Toolbar = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box display="flex">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Job List
        </Typography>
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/jobs/add-new"
        >
          Add New Biz
        </Button>
       <a href="/jobs/add-new">This link reload the page</a>
        <br />
        <Link to="/jobs/add-new">This link does not reload the page</Link>
        <Button
          label="reload the page"
          primary={true}
          linkButton={true}
          href="/jobs/add-new"
        />
        <Button
          label="does not"
          primary={true}
          containerElement={<Link to={'jobs/add-new'} />}
        />
        <Button
          label="does not"
          primary={true}
          component={RouterLink}
          to="/jobs/add-new"
        /> */}
      </Box>
      {/*<Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Job"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>*/}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
