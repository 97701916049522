import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';

import ClientListView from 'src/views/client/ClientListView';
import SingleClientView from 'src/views/client/SingleClientView';
import AddClientView from 'src/views/client/AddClientView';
import AddClientContactView from 'src/views/client/AddClientContactView';

import UserListView from 'src/views/user/UserListView';
import SingleUserView from 'src/views/user/SingleUserView';
import AddUserView from 'src/views/user/AddUserView';

import DepartmentListView from 'src/views/department/DepartmentListView';
import SingleDepartmentView from 'src/views/department/SingleDepartmentView';
import AddDepartmentView from 'src/views/department/AddDepartmentView';

import RevrecListView from 'src/views/revrec/RevrecListView';

import JobListView from 'src/views/job/JobListView';
import JobUnitsListView from 'src/views/job/JobUnitsListView';
import SingleJobView from 'src/views/job/SingleJobView';
import SingleJobUnitView from 'src/views/job/SingleJobUnitView';
import AddJobView from 'src/views/job/AddJobView';
import AddJobUnitView from 'src/views/job/AddJobUnitView';

import SupplierListView from 'src/views/supplier/SupplierListView';
import SingleSupplierView from 'src/views/supplier/SingleSupplierView';
import AddSupplierView from 'src/views/supplier/AddSupplierView';

import RatecardListView from 'src/views/ratecard/RatecardListView';
import SingleRatecardView from 'src/views/ratecard/SingleRatecardView';
import AddRatecardView from 'src/views/ratecard/AddRatecardView';
import AddRatesView from 'src/views/ratecard/AddRatesView';

import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import RestrictedView from 'src/views/auth/RestrictedView';
import SettingsView from 'src/views/settings/SettingsView';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },

      { path: 'clients', element: <ClientListView /> },
      { path: 'clients/client/:id', element: <SingleClientView /> },
      { path: 'clients/add-new', element: <AddClientView /> },

      {
        path: 'clients/client/:id/add-new-contact',
        element: <AddClientContactView />
      },

      { path: 'users', element: <UserListView /> },
      { path: 'users/user/:id', element: <SingleUserView /> },
      { path: 'users/add-new', element: <AddUserView /> },

      {
        path: 'departments',
        element: <DepartmentListView />
      },
      {
        path: 'departments/department/:id',
        element: <SingleDepartmentView />
      },
      { path: 'departments/add-new', element: <AddDepartmentView /> },

      { path: 'revrec', element: <RevrecListView /> },

      { path: 'jobs', element: <JobListView /> },
      { path: 'jobunits', element: <JobUnitsListView /> },
      { path: 'jobs/job/:id', element: <SingleJobView /> },
      { path: 'jobunits/jobunit/:id', element: <SingleJobUnitView /> },
      { path: 'jobs/add-new', element: <AddJobView /> },

      { path: 'jobs/job/:id/add-new-jobunit', element: <AddJobUnitView /> },

      { path: 'ratecards', element: <RatecardListView /> },
      { path: 'ratecards/ratecard/:id', element: <SingleRatecardView /> },
      { path: 'ratecards/add-new', element: <AddRatecardView /> },

      {
        path: 'ratecards/ratecard/:id/add-new-rate',
        element: <AddRatesView />
      },

      { path: 'suppliers', element: <SupplierListView /> },
      { path: 'suppliers/supplier/:id', element: <SingleSupplierView /> },
      { path: 'suppliers/add-new', element: <AddSupplierView /> },

      { path: 'ratecards', element: <RatecardListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: '/', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'restricted', element: <RestrictedView /> },
      // { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> }
      // { path: '/*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    // element: <DashboardLayout />,
    children: [{ path: 'login', element: <LoginView /> }]
  }
];

export default routes;
