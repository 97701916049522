import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableCell,
  TextField,
  TableHead,
  TableRow,
  Collapse,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { useMutation, useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, AlertTitle } from '@mui/lab';

import moment from 'moment';

import currencyformatter from 'src/utils/currencyformatter';
import groupBy from 'src/utils/groupBy';
import JUBudgetDetails from 'src/components/JUBudgetDetails';
import ReadMore from 'src/components/ReadMore';

import gql from 'graphql-tag';

const DepartmentQuery = gql`
  query Ratecard($id: ID!) {
    Ratecard(_id: $id) {
      _id
      rates {
        department_name
        role
        perhour
      }
    }
  }
`;

const addJobEstimate = gql`
  mutation addJobEstimate(
    $ref: ID!
    $department_name: String!
    $role: String!
    $perhour: Float!
    $note: String
    $hours: Float!
  ) {
    addJobEstimate(
      ref: $ref
      data: {
        department_name: $department_name
        role: $role
        rate: $perhour
        hours: $hours
        note: $note
      }
    ) {
      _id
      time_estimate {
        _id
        department_name
        role
        rate
        hours
        note
        created
        user_reference
        user_name
        amount
      }
    }
  }
`;

const removeJobEstimate = gql`
  mutation removeJobEstimate($ref: ID!) {
    removeJobEstimate(ref: $ref) {
      _id
      time_estimate {
        _id
        department_name
        role
        rate
        hours
        note
        created
        user_reference
        user_name
        amount
      }
    }
  }
`;

const updateJobEstimate = gql`
  mutation updateJobEstimate($ref: ID!, $hours: Float!) {
    updateJobEstimate(ref: $ref, data: { hours: $hours }) {
      _id
      time_estimate {
        _id
        department_name
        role
        rate
        hours
        note
        created
        user_reference
        user_name
        amount
      }
    }
  }
`;

const TimeEstimateDetails = ({
  className,
  element,
  containerTotalbudget,
  updatedsingleAllocated,
  containerAllocated,
  stage,
  ...rest
}) => {
  // Default form's state values

  const jobref = element[0]._id;

  const [updatedTimeEstimate, setUpdatedTimeEstimate] = useState(
    element[0].estimated_time
  );
  const [updatedEstimated_ftc, setUpdatedEstimated_ftc] = useState(
    element[0].estimated_ftc
  );

  let time_estimate_sum = '';
  // let ftc_estimate_sum = '';

  useEffect(() => {
    setUpdatedEstimated_ftc(element[0].estimated_ftc);
  }, [element[0]]);

  const DepartmentResult = useQuery(DepartmentQuery, {
    variables: { id: element[0].ratecard_reference }
  });

  const [rateList, setRateList] = useState([]);
  useEffect(() => {
    if (!DepartmentResult.loading) {
      setRateList(DepartmentResult.data.Ratecard);
    }
  }, [DepartmentResult.data]);

  const formDefaults = {
    department_name: '',
    role: '',
    perhour: 0,
    hours: 0,
    var_notes: ''
  };

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [formState, setFormState] = useState(formDefaults);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [editRow, setEditRow] = useState(false);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.TimeEstimateDetails === false ? false : false
  );

  const [totalHours, setTotalHours] = useState(0);
  const [totalCost, setTotalCost] = useState(false);
  const [totalAmount, setTotalAmount] = useState(false);

  //-----------------------
  const [rowID, setrowID] = useState(null);
  const [openDel, setOpenDel] = useState(false);

  //-----------------------

  const [errorallert, setErrorallert] = useState('');
  const [randkey, setRandkey] = useState(
    moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
  );

  const [add_JobEstimate] = useMutation(addJobEstimate, {
    onCompleted: data => {
      setFormState(formDefaults);
      setErrorallert('');
      // console.log(data.addJobEstimate.time_estimate);
      time_estimate_sum = data.addJobEstimate.time_estimate.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );
      setUpdatedTimeEstimate(time_estimate_sum);
      setRandkey(
        moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
      );
      // console.log(sum);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      console.log(msg);
      setErrorallert(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const submitRecord = () => {
    if (formState.department_name && formState.hours !== 0) {
      add_JobEstimate({
        variables: {
          ref: jobref,
          department_name: formState.department_name,
          role: formState.role,
          perhour: parseFloat(formState.perhour),
          hours: parseFloat(formState.hours),
          note: formState.var_notes
        }
      });
      // setFormState(formDefaults);
      setAskareyousure(false);
    } else {
      setErrorMsg('Please add the necessary information');
      // setOpendialog(true);
    }
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };
  //---------------------------------------
  // Remove
  //---------------------------------------
  const handleClickOpenDel = id => {
    setrowID(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setrowID(null);
    setOpenDel(false);
  };

  const [remove_JobEstimate] = useMutation(removeJobEstimate, {
    onCompleted: data => {
      console.log('row deleted');
      handleCloseDel();
      time_estimate_sum = data.removeJobEstimate.time_estimate.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );
      setUpdatedTimeEstimate(time_estimate_sum);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
    }
  });

  const handleYesDel = () => {
    if (rowID) {
      console.log(' delete row called for ' + rowID);
      remove_JobEstimate({
        variables: {
          ref: rowID
        }
      });
    }
    setOpenDel(false);
  };

  //---------------------------------------
  // Remove Ends
  //---------------------------------------

  //---------------------------------------
  // Edit
  //---------------------------------------

  const handleOpenEditDialog = (_id, hours) => {
    setrowID(_id);
    setFormState({
      hours: hours
    });
    setEditRow(true);
  };

  const handleCloseEditDialog = () => {
    setEditRow(false);
  };

  const [update_JobEstimate] = useMutation(updateJobEstimate, {
    onCompleted: data => {
      console.log('DATA', data);
      time_estimate_sum = data.updateJobEstimate.time_estimate.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );
      setUpdatedTimeEstimate(time_estimate_sum);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');

      setOpendialog(true);
      setErrorMsg(msg);
    }
  });

  const updateRow = () => {
    if (formState.hours !== 0) {
      update_JobEstimate({
        variables: {
          ref: rowID,
          hours: parseFloat(formState.hours)
        }
      });
      setFormState(formDefaults);
    } else {
      console.log('fail!');
    }
  };

  const handleSaveEditDialog = () => {
    updateRow();
    setEditRow(false);
  };

  const hideError = () => {
    setErrorMsg('');
    setErrorallert(false);
  };
  const arrayTest = [];
  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.TimeEstimateDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  // Form Query Actions end

  //---------------------------------------
  // Edit Ends
  //---------------------------------------

  return (
    <Card>
      <CardHeader
        // subheader="The information can be edited"
        title="Time Estimate"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />

        {element[0].time_estimate.length > 0 && (
          <>
            <Grid item md={12} xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Department</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Estimated By</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell>Date</TableCell>
                    {stage !== 'complete' && <TableCell></TableCell>}
                  </TableRow>
                  {element[0].time_estimate.map(item => (
                    <TableRow>
                      <TableCell>{item.department_name}</TableCell>
                      <TableCell sx={{ lineHeight: '1.2em' }}>
                        {item.role}
                      </TableCell>
                      <TableCell>{item.hours}</TableCell>
                      <TableCell>{item.rate}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell sx={{ lineHeight: '1.2em' }}>
                        {item.user_name}
                      </TableCell>
                      <TableCell sx={{ lineHeight: '1.2em' }}>
                        {/* {item.note} */}
                        <ReadMore maxCharacters={50}>{item.note}</ReadMore>
                      </TableCell>
                      <TableCell>
                        {moment.unix(item.created * 0.001).format('DD/MM/YYYY')}
                      </TableCell>
                      {stage !== 'complete' && (
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <IconButton aria-label="delete" size="small">
                              <DeleteIcon
                                onClick={() => handleClickOpenDel(item._id)}
                                style={{ cursor: 'pointer' }}
                              />
                            </IconButton>
                            <IconButton aria-label="edit" size="small">
                              <EditRoundedIcon
                                onClick={() =>
                                  handleOpenEditDialog(item._id, item.hours)
                                }
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableHead>
              </Table>
            </Grid>
          </>
        )}
        {/* Dialog for SPO Edit */}
        <Dialog
          open={editRow}
          onClose={handleCloseEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Edit Estimate
            </DialogContentText>

            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Hours"
                  name="hours"
                  variant="outlined"
                  value={formState.hours || ''}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary" autoFocus>
              Close
            </Button>
            <Button onClick={handleSaveEditDialog} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for FTC Edit */}
        {/* Dialog For Delete Confirmation */}
        <Dialog
          open={openDel}
          onClose={handleCloseDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this row
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDel} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleYesDel} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog For Delete Confirmation End */}
        {stage !== 'complete' && (
          <>
            {/* <Divider /> */}
            <CardContent>
              <Grid container spacing={4} alignItems="flex-start">
                {rateList.length > 0 && (
                  <Grid container item md={6} xs={12} spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="departmentlist"
                        key={randkey}
                        options={Object.keys(
                          groupBy(rateList[0].rates, 'department_name')
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            setFormState({
                              ...formState,
                              department_name: value
                            });
                          } else {
                            setFormState({
                              ...formState,
                              department_name: ''
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Department"
                            name="department_roles"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    {formState.department_name && (
                      <Grid item md={12} xs={12}>
                        <Autocomplete
                          id="rolelist"
                          options={
                            groupBy(rateList[0].rates, 'department_name')[
                              formState.department_name
                            ]
                          }
                          getOptionLabel={option => option[0].role}
                          onChange={(event, value) => {
                            if (value) {
                              setFormState({
                                ...formState,
                                role: value[0].role,
                                perhour: value[0].perhour
                              });
                            } else {
                              setFormState({
                                ...formState,
                                role: '',
                                perhour: ''
                              });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Role"
                              name="roles"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        label="Hours"
                        name="hours"
                        variant="outlined"
                        value={formState.hours || ''}
                        onChange={changeHandler}
                      />
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="Rate"
                        name="rate"
                        // disabled
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                        value={formState.perhour || ''}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        fullWidth
                        label="Amount"
                        name="amount"
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                        value={currencyformatter.format(
                          formState.hours * formState.perhour || ''
                        )}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Notes"
                        name="var_notes"
                        variant="outlined"
                        value={formState.var_notes}
                        onChange={changeHandler}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <JUBudgetDetails
                    budget={updatedsingleAllocated}
                    estimated_time={updatedTimeEstimate}
                    estimated_ftc={updatedEstimated_ftc}
                    available_for_estimate={element[0].available_for_estimate}
                    containerID={element[0].container_ref}
                    elementID={element[0]._id}
                    showForm={false}
                    showContainer={false}
                  />
                  {/* <JUBudgetDetails
                  budget={updatedsingleAllocated}
                  estimated_time={element[0].estimated_time}
                  estimated_ftc={element[0].estimated_ftc}
                  available_for_estimate={element[0].available_for_estimate}
                  containerID={element[0].container_ref}
                  elementID={element[0]._id}
                  showForm={false}
                /> */}
                </Grid>
              </Grid>
            </CardContent>

            <CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {errorallert && (
                  <Alert severity="error" onClose={hideError}>
                    <AlertTitle>{errorallert}</AlertTitle>
                  </Alert>
                )}
                {askareyousure ? (
                  <button
                    onClick={submitRecord}
                    id="button"
                    className={`btn-animate ${isloading ? 'onclic' : null} ${
                      !isloading && completed ? 'validate' : null
                    } ${iserror ? 'novalidate' : null}`}
                  ></button>
                ) : (
                  <button
                    onClick={ask}
                    id="ask"
                    className={`btn-animate ask`}
                  ></button>
                )}
              </Box>

              <Divider />
            </CardContent>
          </>
        )}
      </Collapse>
    </Card>
  );
};

export default TimeEstimateDetails;
