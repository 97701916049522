import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const Departments = gql`
  query Departments($search: departmentArgs, $ops: functionalArgs) {
    Departments(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        parent_ref
        name
        is_sub_department
        roles {
          _id
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const SubResults = ({ className, parent, ...rest }) => {
  const { classes, cx } = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  // console.log(parent);

  // get sub departments
  const {
    loading: loadingSubDep,
    data: dataSubDep,
    error: errorSubDep,
    refetch
  } = useQuery(Departments, {
    variables: {
      search: { is_sub_department: true, parent_ref: parent },
      ops: { page: 0, perpage: 10 }
    }
  });

  // callback for sub departments query
  useEffect(() => {
    const onCompleted = dataSubDep => {
      // console.log('dataSubDep', dataSubDep.Departments.data);
      setLength(dataSubDep.Departments.info.total_records);
    };
    const onError = errorSubDep => {
      console.log('query errorSubDep', errorSubDep);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingSubDep && !errorSubDep) {
        onCompleted(dataSubDep);
      } else if (onError && !loadingSubDep && errorSubDep) {
        onError(errorSubDep);
      }
    }
  }, [loadingSubDep, dataSubDep, errorSubDep]);

  const handleLimitChange = event => {
    setPage(0);
    setLimit(event.target.value);
    refetch({
      search: { is_sub_department: true, parent_ref: parent },
      ops: { page: 0, perpage: event.target.value }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    refetch({
      search: { is_sub_department: true, parent_ref: parent },
      ops: { page: newPage, perpage: limit }
    });
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSubDep &&
              dataSubDep.Departments.data.map(element => (
                <TableRow hover key={element._id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={element.avatarUrl}
                      >
                        {getInitials(element.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <Link to={{ pathname: `department/${element._id}` }}>
                          {element.name}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{element.roles.length}</TableCell>
                  <TableCell>
                    <Link to={{ pathname: `department/${element._id}` }}>
                      <VisibilityIcon style={{ color: '#bdbdbd' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

SubResults.propTypes = {
  className: PropTypes.string
};

export default SubResults;
