import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    importButton: {
      marginRight: theme.spacing(1)
    },
    exportButton: {
      marginRight: theme.spacing(1)
    }
  };
});

const Toolbar = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box display="flex">
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h3"
          style={{ marginRight: 'auto', marginBottom: 0, padding: '6px 8px' }}
        >
          Rev Rec
        </Typography>
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
      </Box>
      {/*<Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Job"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>*/}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
