import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';

import { useMutation } from '@apollo/client';
import { updateClient } from 'src/queries/query';

import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ProfileDetails = ({ className, element, ...rest }) => {
  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();
  const timer3 = React.useRef();

  const [state, setState] = useState({
    usePo: false
  });

  React.useEffect(() => {
    setState({
      usePo: element[0].use_po
    });
  }, []);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const [
    update_Client,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateClient, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const handleChangeSwitch = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };
  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <form
      autoComplete="off"
      {...rest}
      onSubmit={e => {
        e.preventDefault();
        update_Client({
          variables: {
            ref: element[0]._id,
            data: {
              name: e.target.name.value,
              use_po: state.usePo,
              company_contact_info: {
                email: e.target.email.value,
                phone: e.target.phone.value,
                fax: e.target.fax.value,
                address_line1: e.target.address_line1.value,
                address_line2: e.target.address_line2.value,
                city: e.target.city.value,
                county: e.target.county.value,
                country: e.target.country.value,
                postcode: e.target.postcode.value,
                url: e.target.url.value,
                fb: e.target.fb.value,
                twitter: e.target.twitter.value,
                instagram: e.target.instagram.value,
                youtube: e.target.youtube.value,
                other: e.target.other.value
              }
            }
          }
        });
        setIsloading(true);
      }}
    >
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="Client Name"
                name="name"
                required
                defaultValue={element[0].name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                required
                defaultValue={element[0].company_contact_info.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.usePo}
                        onChange={handleChangeSwitch}
                        name="usePo"
                      />
                    }
                    label="Uses Po"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                type="number"
                defaultValue={element[0].company_contact_info.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Fax"
                name="fax"
                defaultValue={element[0].company_contact_info.fax}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="address_line1"
                type="text"
                defaultValue={element[0].company_contact_info.address_line1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="address_line2"
                type="text"
                defaultValue={element[0].company_contact_info.address_line2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="city"
                type="text"
                defaultValue={element[0].company_contact_info.city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="County"
                name="county"
                type="text"
                defaultValue={element[0].company_contact_info.county}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                type="text"
                defaultValue={element[0].company_contact_info.country}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Postcode"
                name="postcode"
                type="text"
                defaultValue={element[0].company_contact_info.postcode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="URL"
                name="url"
                type="text"
                defaultValue={element[0].company_contact_info.url}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Facebook"
                name="fb"
                type="text"
                defaultValue={element[0].company_contact_info.fb}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Twitter"
                name="twitter"
                type="text"
                defaultValue={element[0].company_contact_info.twitter}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Instagram"
                name="instagram"
                type="text"
                defaultValue={element[0].company_contact_info.instagram}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="YouTube"
                name="youtube"
                type="text"
                defaultValue={element[0].company_contact_info.youtube}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Other Info"
                name="other"
                type="textarea"
                defaultValue={element[0].company_contact_info.other}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {askareyousure ? (
            <button
              type="submit"
              id="button"
              className={`btn-animate ${isloading ? 'onclic' : null} ${
                !isloading && completed ? 'validate' : null
              } ${iserror ? 'novalidate' : null}`}
            />
          ) : (
            <button onClick={ask} id="ask" className={`btn-animate ask`} />
          )}
        </Box>
      </Card>
    </form>
  );
};

export default ProfileDetails;
