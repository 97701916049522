import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { updateJobContainer } from 'src/queries/query';
import GoogleDriveFolder from '../../../components/googleComponents/GoogleDriveFolder';

export default function CloudPortal(props) {
  //   const folderID = props.job.cloud_portal ? props.job.cloud_portal : '';
  const folderName = props.job.title ? props.job.title : '';
  const jobID = props.job._id ? props.job._id : '';
  const parent = props.parent ? props.parent : 'Jobs';

  const [folderID, setFolderID] = useState(props.job.cloud_portal);
  const [parentID, setParentID] = useState(props.parentID);

  useEffect(() => {
    if (!folderID) {
      if (!parentID) {
        axios
          .get('/getFolderIDByName', {
            params: {
              folderName: parent
            }
          })
          .then(rest => {
            alert(rest.data);
            if (rest.data) {
              setParentID(rest.data);
            }
          });
      } else {
        axios
          .get('/getFolderIDByName', {
            params: {
              folderName: folderName,
              parentID: parentID
            }
          })
          .then(rest => {
            if (rest.data) {
              addFolderID(rest.data, jobID);
              setFolderID(rest.data);
            }
          });
      }
    }
  }, [folderID, parentID]);

  const addFolderID = (folderID, jobID) => {
    update_JobContainer({
      variables: {
        ref: jobID,
        data: {
          cloud_portal: folderID
        }
      }
    });
  };

  const [
    update_JobContainer,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateJobContainer, {
    onCompleted() {},
    onError: err => {
      console.log(err);
    }
  });

  return (
    <>
      {folderID && (
        <GoogleDriveFolder job={props.job} parent="Jobs" folderID={folderID} />
      )}
    </>
  );
}
