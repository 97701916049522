import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export function GoogleDeleteFile(fileID, deleteCallback) {
  axios
    .delete('/deleteFile', {
      params: {
        fileID: fileID
      }
    })
    .then(() => {
      if (deleteCallback) deleteCallback(fileID);
    });
}

export default function GoogleDriveDeleteIcon(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    GoogleDeleteFile(props.file.id, deleteCallback);
  };

  const deleteCallback = () => {
    handleClose();
    props.response(props.file.id);
  };

  return (
    <div>
      {/* <DeleteIcon onClick={() => this.removeFile(item.id)} className={classes.binIcon} /> */}

      <IconButton aria-label="delete" size="small">
        <DeleteIcon onClick={handleClickOpen} style={{ cursor: 'pointer' }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete file &quot{props.file.name}&quot from Google Drive?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleYes} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
