import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Checkbox,
  ListItemIcon
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Autocomplete from '@mui/material/Autocomplete';

import AuthContext from 'src/components/AuthContext';

import Notesvg from 'src/utils/Notesvg';

import TextEditor from 'src/components/TextEditor';

import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
const Users = gql`
  query Users {
    Users {
      _id
      subscription_key
      first_name
      last_name
    }
  }
`;

const drawerWidth = 528;
let status = 'Pending';
let data;
const members = [];

const TasksList = gql`
  query Tasks($collectionl_ref: String, $collection_name: String) {
    Tasks(
      collectionl_ref: $collectionl_ref
      collection_name: $collection_name
    ) {
      _id
      subscription_key
      title
      description
      collection_name
      collectionl_ref
      assigned_to_ref
      assigned_to_name
      assigned_by_ref
      assigned_by_name
      due_by
      completed_date
      status
    }
  }
`;

const addTask = gql`
  mutation addTask(
    $collection_name: String
    $title: String
    $collection_title: String
    # $subscription_key: String
    $assigned_to_ref: String
    $assigned_to_name: String
    # $assigned_by_ref: String
    # $assigned_by_name: String
    $collectionl_ref: String
    $due_by: String
  ) {
    addTask(
      data: {
        collection_name: $collection_name
        title: $title
        collection_title: $collection_title
        # subscription_key: $subscription_key
        assigned_to_ref: $assigned_to_ref
        assigned_to_name: $assigned_to_name
        # assigned_by_ref: $assigned_by_ref
        # assigned_by_name: $assigned_by_name
        collectionl_ref: $collectionl_ref
        due_by: $due_by
      }
    ) {
      _id
    }
  }
`;

const updateTask = gql`
  mutation updateTask($ref: ID!, $status: String, $completed_date: String) {
    updateTask(
      ref: $ref
      data: { status: $status, completed_date: $completed_date }
    ) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      justifyContent: 'flex-end'
    },
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 10,
      color: '#aaa'
    },
    drawerPaper: {
      width: drawerWidth,
      padding: '20px'
    },
    taskList: {
      maxHeight: '100',
      overflowY: 'scroll'
    },
    noteTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.2em',
      marginBottom: 'auto',
      paddingBottom: '20px'
    }
  };
});

const Tasks = props => {
  const { classes, cx } = useStyles();
  const noteChat = useRef(null);
  // const noteInput = useRef(null);
  const { userData } = useContext(AuthContext);
  const [value, setValue] = useState({ assigned_reference: userData._id });
  const [open, setOpen] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [count, setCount] = useState(0);
  const [jobDesc, setJobDesc] = useState('');

  const TasksResults = useQuery(TasksList, {
    variables: {
      collectionl_ref: props.element._id,
      collection_name: props.type
    }
  });

  const inView = () => {
    setTimeout(() => {
      if (noteChat.current) {
        noteChat.current.scrollIntoView();
      }
    }, 100);
  };

  const [update_Task] = useMutation(updateTask, {
    onCompleted() {
      TasksResults.refetch();
      inView();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: TasksList }]
  });

  const [add_Task] = useMutation(addTask, {
    onCompleted() {
      TasksResults.refetch();
      inView();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: TasksList }]
  });

  const handleChange = event => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleClickModal = () => {
    setOpen(true);
    inView();
    // noteChat.current.scrollIntoView();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = item => {
    console.log(item);
    if (item.status !== 'Completed') status = 'Completed';
    data = {
      ref: item._id,
      status: status,
      completed_date: moment()
    };
    update_Task({
      variables: data
    });
    setTimeout(() => {
      setValue({});
    }, 100);
  };

  useEffect(() => {
    if (TasksResults.data) setTaskList(TasksResults.data.Tasks);
    inView();
  }, [TasksResults]);

  useEffect(() => {
    if (taskList) {
      taskList.forEach(task => {
        // console.log(task.user_ref);
        members[task.user_ref] = '';
      });
      const taskListCount = taskList.filter(item => {
        return item.status !== 'Completed';
      });
      setCount(taskListCount.length);
    }
  }, [taskList]);

  const handleSubmit = () => {
    if (value) {
      const data = {
        collection_name: props.type,
        title: jobDesc,
        collection_title: props.element.title,
        // subscription_key: userData.subscription_key,
        // assigned_by_ref: userData._id,
        // assigned_by_name: userData.first_name + ' ' + userData.last_name,
        collectionl_ref: props.element._id,
        due_by: value.due_by,
        assigned_to_ref: value.assigned_to_ref || userData._id,
        assigned_to_name:
          value.assigned_to_name ||
          userData.first_name + ' ' + userData.last_name
      };

      add_Task({
        variables: data
      });
      setTimeout(() => {
        setValue({});
      }, 100);
    }
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  let runDate = '';

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  return (
    <>
      <Box style={{ marginRight: '15px' }}>
        <Badge overlap="rectangular" badgeContent={count} color="secondary">
          <Button color="primary" variant="outlined" onClick={handleClickModal}>
            {/* {taskList.length > 0 && (
            <Chip
              label={taskList.length}
              style={{
                marginLeft: '-10px',
                marginRight: '5px',
                cursor: 'pointer'
              }}
              size="small"
            />
          )}{' '} */}
            Tasks
          </Button>
        </Badge>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box className={classes.noteTitle}>Tasks</Box>

        <List
          className={classes.taskList}
          sx={{
            bgcolor: 'background.paper'
          }}
        >
          {/* <Notesvg /> */}
          {taskList &&
            taskList.map(item => {
              let showDate = moment.unix(item.due_by / 1000).format('LL');
              if (runDate === showDate) {
                showDate = '';
              } else {
                runDate = showDate;
              }
              return (
                <div key={item._id}>
                  <ListItem key={value} disablePadding>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={item.status === 'Completed'}
                        tabIndex={-1}
                        disableRipple
                        onClick={() => handleCheck(item)}
                      />
                    </ListItemIcon>
                    <TextEditor
                      textEditorInput={item.title}
                      isInput={false}
                      canEdit={false}
                    />
                    <ListItemSecondaryAction
                      style={{
                        top: '25px',
                        fontSize: '0.8em',
                        opacity: '.75'
                      }}
                    >
                      {moment.unix(item.due_by / 1000).format('LL')}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText
                      secondary={
                        <div style={{ display: 'flex' }}>
                          <div>
                            <strong>Assigned to:</strong>
                            <br />
                            {item.assigned_to_name}
                          </div>
                          <div
                            style={{
                              marginLeft: 'auto',
                              textAlign: 'right',
                              position: 'relative'
                            }}
                          >
                            <strong>Created by:</strong>
                            <br />
                            {item.assigned_by_name}
                            <br />
                            {item.status === 'Completed' && (
                              <div>
                                <strong>Completed: </strong>
                                {moment
                                  .unix(item.completed_date / 1000)
                                  .format('LL')}
                              </div>
                            )}
                          </div>
                          {/* {item.status == 'Completed' && (
                            <div>
                              <strong>Completed:</strong>
                              {item.completed_date}
                            </div>
                          )} */}
                        </div>
                      }
                    />
                  </ListItem>

                  <Divider component="div" />
                </div>
              );
            })}
          <div ref={noteChat}></div>
        </List>
        <br />
        {/* <TextField
          id="standard-multiline-flexible"
          label="Task"
          multiline
          maxRows={4}
          value={value.task ? value.task : ''}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          name="task"
          variant="outlined"
          margin="normal"
          style={{
            width: '100%'
          }}
        /> */}
        <TextEditor textEditorOutput={textEditorOutput} editorLabel={'Task'} />
        <br />
        <Query query={Users}>
          {({ loading, data }) =>
            !loading && (
              <Autocomplete
                id="userlist"
                defaultValue={data.Users.find(
                  item => item._id === userData._id
                )}
                options={data.Users}
                getOptionLabel={option =>
                  option.first_name + ' ' + option.last_name
                }
                onChange={(event, values) => {
                  if (values) {
                    setValue({
                      ...value,
                      assigned_to_ref: values._id,
                      assigned_to_name:
                        values.first_name + ' ' + values.last_name
                    });
                  } else {
                    setValue({
                      ...value,
                      assigned_to_ref: userData._id,
                      assigned_to_name:
                        userData.first_name + ' ' + userData.last_name
                    });
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="Assigned to"
                    name="assigned_name"
                    variant="outlined"
                  />
                )}
              />
            )
          }
        </Query>
        <br />
        <TextField
          className={cx(classes.date)}
          id="date"
          label="To be completed by"
          name="due_by"
          type="date"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
        />
        <br />
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </Drawer>
    </>
  );
};

export default Tasks;
