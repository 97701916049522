import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const Users = gql`
  query UsersList($search: userListArgs, $ops: functionalArgs) {
    UsersList(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        first_name
        last_name
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  // get clients
  const {
    loading: loadingUsers,
    data: dataUsers,
    error: errorUsers,
    refetch
  } = useQuery(Users, {
    variables: { ops: { page: 0, perpage: 10 } }
  });

  // callback for clients query
  useEffect(() => {
    const onCompleted = dataUsers => {
      setLength(dataUsers.UsersList.info.total_records);
    };
    const onError = errorUsers => {
      console.log('query errorUsers', errorUsers);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingUsers && !errorUsers) {
        onCompleted(dataUsers);
      } else if (onError && !loadingUsers && errorUsers) {
        onError(errorUsers);
      }
    }
  }, [loadingUsers, dataUsers, errorUsers]);

  const handleLimitChange = event => {
    setPage(0);
    setLimit(event.target.value);
    refetch({
      ops: { page: 0, perpage: event.target.value }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    refetch({
      ops: { page: newPage, perpage: limit }
    });
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataUsers &&
              dataUsers.UsersList.data.map(element => (
                <TableRow hover key={element._id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={element.avatarUrl}
                      >
                        {getInitials(element.first_name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <Link to={{ pathname: `user/${element._id}` }}>
                          {element.first_name} {element.last_name}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Link to={{ pathname: `user/${element._id}` }}>
                      &#9998;
                    </Link>
                  </TableCell>
                  {/* <TableCell>
                      <Mutation
                        mutation={removeUser}
                        update={(cache, { data: { removeUser } }) => {
                          const { Users } = cache.readQuery({ query: Users });
                          console.log(Users);
                          cache.writeQuery({
                            query: Users,
                            data: {
                              Users: Users.filter(e => e.id !== element._id)
                            }
                          });
                        }}
                      >
                        {mutation => (
                          <p
                            onClick={e => {
                              console.log(element._id);
                              mutation({ variables: { id: element._id } });
                            }}
                          >
                            &#128465;
                          </p>
                        )}
                      </Mutation>
                    </TableCell> */}
                  {/*
                  <TableCell>
                    {`${element.address.city}, ${element.address.state}, ${element.address.country}`}
                  </TableCell>
                  <TableCell>
                    {element.phone}
                  </TableCell>
                  <TableCell>
                    {moment(element.dob).format('DD/MM/YYYY')}
                  </TableCell>
                  */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
