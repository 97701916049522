import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import SubResults from './SubResults';

export const Departments = gql`
  query Departments($search: departmentArgs, $ops: functionalArgs) {
    Departments(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        name
        is_sub_department
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Results = ({ className, ...rest }) => {
  const { classes } = useStyles();

  // get parent departments
  const {
    loading: loadingParentDep,
    data: dataParentDep,
    error: errorParentDep
  } = useQuery(Departments, {
    variables: { search: { is_sub_department: false } }
  });

  // callback for parent departments query
  useEffect(() => {
    const onCompleted = dataParentDep => {
      // console.log('dataParentDep', dataParentDep.Departments.data);
    };
    const onError = errorParentDep => {
      console.log('query errorParentDep', errorParentDep);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingParentDep && !errorParentDep) {
        onCompleted(dataParentDep);
      } else if (onError && !loadingParentDep && errorParentDep) {
        onError(errorParentDep);
      }
    }
  }, [loadingParentDep, dataParentDep, errorParentDep]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {!loadingParentDep && !errorParentDep && (
        <>
          <AppBar
            position="static"
            color="transparent"
            className={classes.appbar}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="Departments"
            >
              {dataParentDep.Departments.data.map((element, index) => (
                <Tab label={element.name} {...a11yProps(index)} />
              ))}
            </Tabs>
          </AppBar>
          {dataParentDep.Departments.data.map((element, index) => (
            <TabPanel value={value} index={index} key={index}>
              <SubResults parent={element._id} index={index} />
            </TabPanel>
          ))}
        </>
      )}
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
