import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const RemoveTimesheet = gql`
  mutation removeTimesheet($ref: ID!) {
    removeTimesheet(ref: $ref) {
      _id
    }
  }
`;

export default function DeleteTimesheet(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    console.log('DeleteTimesheet ' + props.id);
    delete_Timesheet({
      variables: { ref: props.id }
    });
    setOpen(false);
  };

  const [delete_Timesheet] = useMutation(RemoveTimesheet, {
    onCompleted(data) {
      props.postoperationfnc();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  return (
    <div>
      <IconButton aria-label="delete" size="small" onClick={handleClickOpen}>
        <DeleteIcon style={{ cursor: 'pointer' }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>Delete Timesheet entry?</strong>
            <br />
            Job Title: {props.title}
            <br />
            Hours: {props.hours}
            <br />
            Notes: {props.note}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleYes} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
