import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import AuthContext from 'src/components/AuthContext';

import { updateUser } from 'src/queries/query';
import { useMutation } from '@apollo/client';
import SendMail from '../../../components/googleComponents/SendMail';

const testmail = e => {
  e.preventDefault();
  const mailData = {
    from: 'Davis Vilum <ams@reci.pe>',
    to: 'davis@reci.pe',
    subject: 'Hello from gmail using API3',
    text: 'text from Gmail Email using API3',
    html: '<h1>HTML from Gmail Email using API3</h1>'
  };
  console.log(mailData);
  SendMail(mailData);
};

const ProfileDetails = ({ className, ...rest }) => {
  const { userData } = useContext(AuthContext);

  // {userData.first_name} {userData.last_name}
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: 'UK'
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const saveDetails = e => {
    e.preventDefault();
    console.log('values');
    console.log(userData);

    update_User({
      variables: {
        ref: userData._id,
        data: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone
        }
      }
    });
  };

  const [update_User] = useMutation(updateUser, {
    onCompleted() {
      // inView();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: NotesList }]
  });

  useEffect(() => {
    setValues({
      ...values,
      firstName: userData.first_name,
      lastName: userData.last_name,
      email: userData.email,
      phone: userData.phone,
      country: 'UK'
    });
  }, [userData]);

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            {/* <AuthContext.Consumer>
              {({ getUserData, userData }) => {
                var userDataFromFunction = getUserData;
                console.log(userDataFromFunction);
                // var userDataFromFunction = getUserData();
                var user_level;
                if (typeof userDataFromFunction != 'undefined') {
                  user_level = userDataFromFunction.user_level;
                }
                if (user_level <= 2)
                  return (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Country"
                        name="country"
                        onChange={handleChange}
                        required
                        value={values.country}
                        variant="outlined"
                      />
                    </Grid>
                  );
              }}
            </AuthContext.Consumer> */}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={saveDetails}>
            Save details
          </Button>
        </Box>
        {/* <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={testmail}
          >
            Test Mail
          </Button>
        </Box> */}
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
