import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    addButton: {
      marginBottom: theme.spacing(3)
    }
  };
});

const Toolbar = ({ className, id, ...rest }) => {
  const { classes, cx } = useStyles();
  const link = '/users/add-new';

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={link}
          className={classes.addButton}
        >
          Add New User
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
