import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import LinearProgress from '@mui/material/LinearProgress';

import currencyformatter from 'src/utils/currencyformatter';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import EditRoundedIcon from '@mui/icons-material/EditRounded';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
// import Alert from '@mui/lab/Alert';

import Notes from 'src/views/notes/Notes';
import Tasks from 'src/views/tasks/Tasks';
import TextEditor from 'src/components/TextEditor';

import {
  updateJobContainer,
  updateJobContainerBudget,
  updateJobContainerClient
} from 'src/queries/query';

import RatecardsDetails from './RatecardsDetails';

const Client = gql`
  query Client($id: ID) {
    Client(_id: $id) {
      _id
      name
    }
  }
`;

const approveJobContainer = gql`
  mutation approveJobContainer($ref: ID!) {
    approveJobContainer(ref: $ref) {
      _id
    }
  }
`;
const cancelJobContainer = gql`
  mutation cancelJobContainer($ref: ID!) {
    cancelJobContainer(ref: $ref) {
      _id
    }
  }
`;
const removeJobContainer = gql`
  mutation removeJobContainer($ref: ID!) {
    removeJobContainer(ref: $ref) {
      _id
    }
  }
`;
const completeJobContainer = gql`
  mutation completeJobContainer($ref: ID!) {
    completeJobContainer(ref: $ref) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    },
    root: {
      flexGrow: 1,
      maxWidth: 752
    },
    demo: {
      backgroundColor: '#fcfcfc'
    },
    marginList: {
      marginRight: '10px'
    },
    marginListUnits: {
      marginLeft: '10px'
    },
    widthProgress: {
      width: '200px'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({
  className,
  element,
  dialogRatecard,
  fromProfileDetails,
  stage,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [errorbudget, setErrorbudget] = useState(false);
  const [errorclient, setErrorclient] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  let total_allocated = 0;
  element[0].units.forEach(item => {
    total_allocated += item.allocated_budget;
  });

  const [state, setState] = useState({
    isBlocking: false,
    state_total_allocated: total_allocated,
    total_budgetmodal: element[0].total_budget,
    budget_form: !stage === 'complete',
    approved: element[0].stage === 'live',
    stagecompleted: element[0].stage === 'complete',
    pre_to_live_date: element[0].pre_to_live_date
      ? new Date(parseInt(element[0].pre_to_live_date))
      : '',
    complete_date: element[0].complete_date
      ? new Date(parseInt(element[0].complete_date))
      : '',
    owner_reference: element[0].owner_reference
      ? element[0].owner_reference
      : '5fd3d0a5d2522906b70ef18e',
    client_ref: element[0].client.client_ref
      ? element[0].client.client_ref
      : '5fd40586d2522906b70ef208',
    client_name: element[0].client.client_name
      ? element[0].client.client_name
      : 'Client'
  });

  const [openDialogRatecard, setOpenDialogRatecard] = useState(dialogRatecard);
  const [newactiveratecard, setNewactiveratecard] = useState(
    element[0].ratecard
  );
  const [openDialogClient, setOpenDialogClient] = React.useState(false);

  const fromRatecardsDetails = (open, activeratecard) => {
    setOpenDialogRatecard(open);
    setNewactiveratecard(activeratecard);
    console.log('from child', activeratecard);
  };

  useEffect(() => {
    fromProfileDetails(newactiveratecard);
  }, [newactiveratecard]);

  const handleCloseDialogRatecard = () => {
    setOpenDialogRatecard(false);
  };

  useEffect(() => {
    setOpenDialogRatecard(dialogRatecard);
  }, [dialogRatecard]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [opendialog, setOpendialog] = React.useState(false);

  const handleClickOpendialog = () => {
    setOpendialog(true);
  };

  const handleClosedialog = () => {
    setOpendialog(false);
  };

  const [update_JobContainerBudget] = useMutation(updateJobContainerBudget, {
    onCompleted() {
      setOpendialog(false);
      setErrorbudget(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setErrorbudget(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const [update_JobContainerClient] = useMutation(updateJobContainerClient, {
    onCompleted() {
      setOpenDialogClient(false);
      setErrorclient(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setErrorclient(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handleChangeBudget = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
    setErrorbudget(false);
    if (event.target.value < state.state_total_allocated) {
      setErrorbudget(true);
    }
  };

  const submitBudget = e => {
    e.preventDefault();
    console.log('Budget', state.total_budgetmodal);
    update_JobContainerBudget({
      variables: {
        ref: element[0]._id,
        budget: parseFloat(state.total_budgetmodal)
      }
    });
  };

  // update Client

  const handleOpenDialogClient = () => {
    setOpenDialogClient(true);
  };

  const handleCloseDialogClient = () => {
    setOpenDialogClient(false);
  };

  const submitClient = e => {
    e.preventDefault();
    // console.log('Client', state.clientmodal);
    update_JobContainerClient({
      variables: {
        ref: element[0]._id,
        data: {
          client_ref: state.client_ref,
          client_name: state.client_name
        }
      }
    });
  };

  // end update Client

  const [
    update_JobContainer,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateJobContainer, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    },
    onError: err => {
      setError(err);
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const [jobDesc, setJobDesc] = useState('');

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  return (
    <>
      <Grid item xs={12}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {mutationError ? mutationError.toString() : null}
          </Alert>
        </Snackbar>
        <form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            setIsloading(true);
            update_JobContainer({
              variables: {
                ref: element[0]._id,
                data: {
                  title: e.target.title.value,
                  description: jobDesc,
                  owner_reference: state.owner_reference,
                  owner_name: e.target.owner_name.value,
                  start_date: e.target.start_date.value,
                  end_date: e.target.end_date.value
                }
              }
            });
          }}
        >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
              title="Job Container"
              classes={{
                action: classes.cardAction
              }}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}></Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    fullWidth
                    label="Job title"
                    name="title"
                    required
                    defaultValue={element[0].title}
                    variant="outlined"
                    InputProps={{
                      readOnly: stage === 'complete'
                    }}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        required
                        label={
                          state.approved ? 'Total Budget' : 'Estimated Budget'
                        }
                        name="total_budget"
                        variant="outlined"
                        value={currencyformatter.format(
                          state.total_budgetmodal
                        )}
                        InputProps={{
                          readOnly: true
                        }}
                        onClick={
                          stage !== 'complete' ? handleClickOpendialog : null
                        }
                      />
                    </Grid>
                    {stage !== 'complete' && (
                      <Grid item>
                        <EditRoundedIcon onClick={handleClickOpendialog} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextEditor
                    textEditorOutput={textEditorOutput}
                    textEditorInput={element[0].description}
                    canEdit={stage === 'complete' ? false : true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        required
                        label="Client"
                        name="client_name"
                        variant="outlined"
                        value={state.client_name}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        required
                        label="Owner"
                        name="owner_name"
                        variant="outlined"
                        value={element[0].owner_name}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    className={cx(classes.date)}
                    id="date"
                    label="Start Date"
                    name="start_date"
                    defaultValue={
                      element[0].start_date
                        ? new Date(parseInt(element[0].start_date))
                            .toISOString()
                            .slice(0, 10)
                        : null
                    }
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: stage === 'complete'
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    className={cx(classes.date)}
                    id="date"
                    label="End Date"
                    name="end_date"
                    defaultValue={
                      element[0].end_date
                        ? new Date(parseInt(element[0].end_date))
                            .toISOString()
                            .slice(0, 10)
                        : null
                    }
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: stage === 'complete'
                    }}
                  />
                </Grid>

                {state.approved && state.pre_to_live_date && (
                  <Grid item xs={12}>
                    <p>Approved on {state.pre_to_live_date.toLocaleString()}</p>
                  </Grid>
                )}
                {state.stagecompleted && state.complete_date && (
                  <Grid item xs={12}>
                    <p>Completed on {state.complete_date.toLocaleString()}</p>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Notes type="job_container" reference={element[0]._id} />
              <Tasks type="job_container" element={element[0]} />

              {stage !== 'complete' &&
                (askareyousure ? (
                  <button
                    type="submit"
                    id="button"
                    className={`btn-animate ${isloading ? 'onclic' : null} ${
                      !isloading && completed ? 'validate' : null
                    } ${iserror ? 'novalidate' : null}`}
                  ></button>
                ) : (
                  <button
                    onClick={ask}
                    id="ask"
                    className={`btn-animate ask`}
                  ></button>
                ))}
            </Box>
          </Card>
        </form>
      </Grid>

      {/* dialog update budget */}
      <form autoComplete="off">
        <Dialog
          open={opendialog}
          onClose={handleClosedialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle id="alert-dialog-title">{'Update Budget'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className={classes.demo}>
                <List>
                  <ListItem>
                    <ListItemIcon className={classes.marginList}>
                      {state.approved ? 'Total Budget' : 'Estimated Budget'}
                    </ListItemIcon>
                    <ListItemText
                      primary={currencyformatter.format(
                        state.total_budgetmodal
                      )}
                    />
                  </ListItem>
                  <Divider component="li" />
                  {element[0].units[0].jobunit_ref.length > 0 && (
                    <>
                      <ListItem>
                        <ListItemIcon className={classes.marginList}>
                          Allocated Budget for Job Units
                        </ListItemIcon>
                      </ListItem>
                      {element[0].units
                        .filter(
                          item => item.jobunit_ref[0].job_type === 'Normal'
                        )
                        .map((item, index) => (
                          <div key={index} className={classes.marginListUnits}>
                            <ListItem>
                              <ListItemIcon className={classes.marginList}>
                                {item.jobunit_ref[0].title}
                              </ListItemIcon>
                              <ListItemText
                                primary={currencyformatter.format(
                                  item.allocated_budget
                                )}
                              />
                              <ListItemSecondaryAction>
                                <LinearProgress
                                  className={classes.widthProgress}
                                  value={
                                    (item.allocated_budget * 100) /
                                    state.total_budgetmodal
                                  }
                                  variant="determinate"
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          </div>
                        ))}
                      <Divider component="li" />
                    </>
                  )}
                  <ListItem>
                    <ListItemIcon className={classes.marginList}>
                      Remaining Budget
                    </ListItemIcon>
                    <ListItemText
                      primary={currencyformatter.format(
                        state.total_budgetmodal - state.state_total_allocated
                      )}
                    />
                    <ListItemSecondaryAction>
                      <LinearProgress
                        className={classes.widthProgress}
                        value={
                          ((state.total_budgetmodal -
                            state.state_total_allocated) *
                            100) /
                          state.total_budgetmodal
                        }
                        variant="determinate"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
              <br />
              <TextField
                fullWidth
                error={errorbudget}
                helperText={errorbudget}
                required
                label={state.approved ? 'Total Budget' : 'Estimated Budget'}
                name="total_budgetmodal"
                variant="outlined"
                defaultValue={state.total_budgetmodal}
                onChange={handleChangeBudget}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  )
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosedialog} color="primary">
              Close
            </Button>
            <Button onClick={submitBudget} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>

      {/* dialog ratecard */}
      <Dialog
        open={openDialogRatecard}
        onClose={handleCloseDialogRatecard}
        fullWidth
        maxWidth={'md'}
      >
        <DialogContent>
          <RatecardsDetails
            jobref={element[0]._id}
            activeratecard={newactiveratecard}
            clientref={element[0].client.client_ref}
            fromRatecardsDetails={fromRatecardsDetails}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialogRatecard} color="primary">
            Skip
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog to update client */}
      <Dialog
        open={openDialogClient}
        onClose={handleCloseDialogClient}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>Update Job Client</DialogTitle>
        <DialogContent>
          <Query query={Client}>
            {({ loading, data }) =>
              !loading && (
                <Autocomplete
                  id="clientlist"
                  disabled={element[0].ratecard}
                  defaultValue={data.Client.find(
                    item => item.name === element[0].client.client_name
                  )}
                  options={data.Client}
                  getOptionLabel={option => option.name}
                  onChange={(event, values) => {
                    if (values) {
                      setState({
                        ...state,
                        client_ref: values._id,
                        client_name: values.name
                      });
                    } else {
                      setState({
                        ...state,
                        client_ref: '',
                        client_name: ''
                      });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Client"
                      name="client_name"
                      variant="outlined"
                      helperText={
                        element[0].ratecard
                          ? 'Can not update Client if Ratecard is approved'
                          : ''
                      }
                    />
                  )}
                />
              )
            }
          </Query>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialogClient} color="primary">
            Cancel
          </Button>
          <Button onClick={submitClient} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileDetails;
