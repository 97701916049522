import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Query } from '@apollo/client/react/components';

import Autocomplete from '@mui/material/Autocomplete';

import Switch from '@mui/material/Switch';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation } from '@apollo/client';
import { addDepartment } from 'src/queries/query';

import gql from 'graphql-tag';

const get_MainDepartments = gql`
  query Department($is_sub_department: Boolean) {
    Department(is_sub_department: $is_sub_department) {
      _id
      name
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [state, setState] = useState({
    sub_department: false,
    parent_ref: ''
  });

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();
  const timer3 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [
    add_Department,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addDepartment, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        // navigate('/jobs');
      }, 2500);
    },
    onError: err => {
      setError(err);
      // setErrorMessage(error.message.split('GraphQL error: ')[1])
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const handleChangeSwitch = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_Department({
            variables: {
              data: {
                subscription_key: '5df7b2704fa93209ad8eca03',
                name: e.target.name.value,
                parent_ref: state.parent_ref,
                is_sub_department: state.sub_department
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Add New Department"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Department Name"
                  name="name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Department type</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.sub_department}
                          onChange={handleChangeSwitch}
                          name="sub_department"
                        />
                      }
                      label="Sub Department"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {state.sub_department && (
                <Grid item xs={12}>
                  <Query
                    query={get_MainDepartments}
                    variables={{ is_sub_department: false }}
                  >
                    {({ loading, data }) =>
                      !loading && (
                        <Autocomplete
                          id="ParentDepartment"
                          options={data.Department}
                          getOptionLabel={option => option.name}
                          onChange={(event, values) => {
                            if (values) {
                              setState({
                                ...state,
                                parent_ref: values._id
                              });
                            } else {
                              setState({
                                ...state,
                                parent_ref: ''
                              });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              label="Parent Department"
                              name="parent_ref"
                              variant="outlined"
                            />
                          )}
                        />
                      )
                    }
                  </Query>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
