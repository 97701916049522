import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
// import AuthContext from 'src/components/AuthContext';

export const uploadFile = function(props) {
  // console.log(props, "props")
  const formData = new FormData();
  formData.append('file', props.targetFile);
  if (props.folderID) formData.append('folderID', props.folderID);
  if (props.folderName) formData.append('folderName', props.folderName);
  //   console.log('UPDATE Started');

  axios.post('/uploadToDrive', formData).then(res => {
    //   console.log('UPDATED res.data', res.data);
    if (props.uploadCallback) props.uploadCallback(res.data);
  });
};

function GoogleDriveUpload(props) {
  // const { userData } = useContext(AuthContext);
  const [state, setState] = useState({ variant: 'contained' });
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState('0');
  const googleUser = JSON.parse(localStorage.getItem('googleUser'));

  let buttonName = props.buttonName ? props.buttonName : 'Upload File';

  useEffect(() => {
    setState({
      ...state,
      //   buttonName: props.buttonName ? props.buttonName : 'Upload File',
      variant: props.variant ? props.variant : 'contained',
      parent: props.parent ? props.parent : '',
      folderName: props.folderName ? props.folderName : '',
      folderID: props.folderID ? props.folderID : ''
    });
    // console.log(googleUser.email, 'googleUser.email');
    // axios.get('/shareWithEmail', {
    //   params: {
    //     folderID: state.folderID,
    //     email: googleUser.email
    //   }
    // });
  }, [props]);

  useEffect(() => {
    if (state.folderID && googleUser && googleUser.email) {
      axios.get('/shareWithEmail', {
        params: {
          folderID: state.folderID,
          email: googleUser.email
        }
      });
      // console.log('REMOVE SHAR');
      // axios.delete('/removeShareEmail', {
      //   params: {
      //     folderID: state.folderID,
      //     email: 'davisvilums@gmail.com'
      //   }
      // });
    }
  }, [state]);

  const onChangeHandler = event => {
    const eventTargetFile = event.target.files[0];
    setLoading(true);

    // uploadFile(eventTargetFile);
    // uploadFile(eventTargetFile, uploadCallback);
    uploadFile({
      targetFile: eventTargetFile,
      folderID: state.folderID,
      folderName: state.folderName,
      uploadCallback: uploadCallback
    });
  };

  //   const uploadFile = targetFile => {
  //     const formData = new FormData();
  //     formData.append('file', targetFile);
  //     formData.append('folderID', state.folderID);
  //     formData.append('folderName', state.folderName);

  //     axios
  //       .post('/uploadToDrive', formData)
  //       .then(res => {
  //         uploadCallback(res.data);
  //         setState({ ...state, uploaded: true, loading: false });
  //       });
  //   };

  const uploadCallback = fileData => {
    if (typeof props.uploadCallback === 'function') {
      props.uploadCallback(fileData);
    }
    setLoading(false);
    // console.log(loading, 'Full State');
  };

  if (loading) {
    buttonName = 'Loading...';
  }

  return (
    <Button
      color="primary"
      variant={state.variant}
      component="label"
      fullWidth={state.variant === 'text' ? true : false}
      disabled={loading}
    >
      {loading ? 'Loading...' : buttonName}
      <input type="file" name="file" hidden onChange={onChangeHandler} />
    </Button>
  );
}
export default GoogleDriveUpload;
