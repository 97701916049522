import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Switch
} from '@mui/material';

import { Mutation } from '@apollo/client/react/components';

import { addUser } from 'src/queries/query';

const ProfileDetails = ({ className, ...rest }) => {
  const [state, setState] = useState({
    status: false
  });

  const handleChangeSwitch = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <Mutation mutation={addUser}>
      {(addUser, { loading, error }) => (
        <form
          autoComplete="off"
          noValidate
          {...rest}
          onSubmit={e => {
            e.preventDefault();
            addUser({
              variables: {
                ref: '',
                data: {
                  subscription_key: '5df7b2704fa93209ad8eca03',
                  email: e.target.email.value,
                  first_name: e.target.first_name.value,
                  last_name: e.target.last_name.value,
                  password: e.target.password.value,
                  designation: e.target.designation.value,
                  rate: parseFloat(e.target.rate.value),
                  user_level: parseInt(e.target.user_level.value),
                  address: e.target.address.value,
                  status: state.status ? 'Active' : 'Inactive'
                }
              }
            });
          }}
        >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
              title="Add New User"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the name"
                    label="First Name"
                    name="first_name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the name"
                    label="Last Name"
                    name="last_name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    required
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    required
                    variant="outlined"
                  />
                </Grid> */}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Rate"
                    name="rate"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="User Level"
                    name="user_level"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Designation"
                    name="designation"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* <FormControlLabel
                    control={<Checkbox name="active" />}
                    label="Active"
                  /> */}
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={state.status}
                            onChange={handleChangeSwitch}
                            name="status"
                          />
                        }
                        label="Active"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Mutation>
  );
};

export default ProfileDetails;
