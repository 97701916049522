import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Alert, AlertTitle } from '@mui/lab';

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const updateJobStage = gql`
  mutation updateJobStage($ref: ID!, $stage: String!) {
    updateJobStage(ref: $ref, stage: $stage) {
      _id
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    addButton: { marginBottom: theme.spacing(3) },
    box: {
      background: 'white',
      width: 'fit-content',
      marginLeft: 'auto',
      borderRadius: '13px',
      padding: '5px 5px 5px 10px'
    },
    list: {
      boxShadow: '#f2f2f2 0px 4px 5px 5px'
    },
    item: {
      margin: '5px',
      padding: '5px',
      minHeight: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      fontWeight: '600'
    },
    selected: {
      border: 'solid 3px red'
    },
    newbiz: {
      backgroundColor: '#FF9DAC!important'
    },
    live: {
      backgroundColor: '#3AD9B4!important'
    },
    complete: {
      backgroundColor: '#2DB3CE!important'
    },
    cancelled: {
      backgroundColor: '#FB656B!important'
    },
    removed: {
      backgroundColor: '#000000!important'
    },
    formControl: { margin: theme.spacing(1), minWidth: 120 },
    dialogTitle: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      padding: '24px 24px'
    },
    dialogContent: {
      backgroundColor: '#F2F2F2',
      padding: '11px 40px !important'
    },
    IconButton: {
      position: 'absolute',
      right: '5px',
      top: '5px'
    }
  };
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  root: {
    '&.Mui-selected': {
      opacity: '0.8'
    },
    '&:hover': {
      opacity: '0.8'
    }
  }
}));

const Toolbar = ({ className, id, stage, sendChildToParent, ...rest }) => {
  const { classes, cx } = useStyles();
  const link = '/jobs/job/' + id + '/add-new-jobunit';

  const [jobstage, setJobstage] = useState(stage);
  const [errorstage, setErrorstage] = useState(false);
  // console.log('tool stage', jobstage);
  // console.log('tool id', id);

  const handleChange = event => {
    handleOpen();
    setJobstage(event.target.value);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = newstage => {
    setOpen(true);
  };

  const handleClose = () => {
    // reset jobstage
    setJobstage(stage);
    setOpen(false);
  };

  const [update_JobStage] = useMutation(updateJobStage, {
    onCompleted() {
      setOpen(false);
      sendChildToParent(jobstage);
      setErrorstage(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setErrorstage(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handleConfirm = () => {
    update_JobStage({
      variables: {
        ref: id,
        stage: jobstage
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          onClick={handleClose}
          size="large"
          classes={{ root: classes.IconButton }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          <WarningTwoToneIcon fontSize="large" />
          <Typography variant="h4">Update Job Stage</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Update the Job Stage?
          </DialogContentText>
          {errorstage && (
            <Alert severity="error">
              <AlertTitle>{errorstage}</AlertTitle>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button> */}
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {jobstage !== 'newbiz' && (
        <div className={cx(classes.root, className)} {...rest}>
          <Box display="flex" alignItems="center" className={classes.box}>
            <Typography color="textPrimary" variant="h5">
              Stage
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small"
            >
              <Select
                id="demo-simple-select-filled"
                value={jobstage}
                onChange={handleChange}
                MenuProps={{
                  classes: { paper: classes.list }
                }}
                IconComponent={EditIcon}
              >
                <StyledMenuItem
                  className={cx(classes.item, classes.live)}
                  value={'live'}
                >
                  Live
                </StyledMenuItem>

                <StyledMenuItem
                  className={cx(classes.item, classes.complete)}
                  value={'complete'}
                >
                  Complete
                </StyledMenuItem>

                <StyledMenuItem
                  className={cx(classes.item, classes.cancelled)}
                  value={'cancelled'}
                >
                  Cancelled
                </StyledMenuItem>
                <StyledMenuItem
                  className={cx(classes.item, classes.removed)}
                  value={'removed'}
                >
                  Removed
                </StyledMenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      )}
    </>
  );
};
Toolbar.propTypes = { className: PropTypes.string };
export default Toolbar;
