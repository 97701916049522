import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const Ratecards = gql`
  query RatecardsList($search: ratecardListArgs, $ops: functionalArgs) {
    RatecardsList(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        title
        version
        client_name
        stage
        createdAt
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  // get clients
  const {
    loading: loadingRatecards,
    data: dataRatecards,
    error: errorRatecards,
    refetch
  } = useQuery(Ratecards, {
    variables: { ops: { page: 0, perpage: 10 } }
  });

  // callback for clients query
  useEffect(() => {
    const onCompleted = dataRatecards => {
      setLength(dataRatecards.RatecardsList.info.total_records);
    };
    const onError = errorRatecards => {
      console.log('query errorRatecards', errorRatecards);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRatecards && !errorRatecards) {
        onCompleted(dataRatecards);
      } else if (onError && !loadingRatecards && errorRatecards) {
        onError(errorRatecards);
      }
    }
  }, [loadingRatecards, dataRatecards, errorRatecards]);

  const handleLimitChange = event => {
    setPage(0);
    setLimit(event.target.value);
    refetch({
      ops: { page: 0, perpage: event.target.value }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    refetch({
      ops: { page: newPage, perpage: limit }
    });
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRatecards &&
              dataRatecards.RatecardsList.data.map(element => (
                <TableRow hover key={element._id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={element.avatarUrl}
                      >
                        {getInitials(element.title)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <Link to={{ pathname: `ratecard/${element._id}` }}>
                          {element.title}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {element.version}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {element.client_name ? element.client_name : 'N / A'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {element.stage}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {moment
                        .unix(element.createdAt * 0.001)
                        .format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link to={{ pathname: `ratecard/${element._id}` }}>
                      <VisibilityIcon style={{ color: '#bdbdbd' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
