import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useMutation } from '@apollo/client';
import { cloneRatecard, removeRatecard } from 'src/queries/query';
import gql from 'graphql-tag';

const approveRatecard = gql`
  mutation approveRatecard($ref: ID!) {
    approveRatecard(ref: $ref) {
      title
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    addButton: {
      marginBottom: theme.spacing(3)
    }
  };
});

const Toolbar = ({ className, id, var_approved, var_stage, ...rest }) => {
  const [approvedState, setApprovedState] = useState(var_approved);
  const [stageState, setStageState] = useState(var_stage);
  const navigate = useNavigate();

  const [approve_Ratecard] = useMutation(approveRatecard, {
    onCompleted() {
      setApprovedState(true);
      setStageState('Approved');
    },
    onError: err => {
      console.log(err);
    }
  });

  const [clone_Ratecard, { error: mutationError }] = useMutation(
    cloneRatecard,
    {
      onCompleted(data) {
        navigate('/ratecards/ratecard/' + data.cloneRatecard._id);
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  const [remove_Ratecard, { error: mutationError1 }] = useMutation(
    removeRatecard,
    {
      onCompleted() {
        navigate('/ratecards');
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  const { classes, cx } = useStyles();
  const link = '/ratecards/ratecard/' + id + '/add-new-rate';

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        {approvedState === false && (
          <Button
            style={{ marginRight: '10px' }}
            color="primary"
            variant="contained"
            className={classes.addButton}
            onClick={e => {
              e.preventDefault();
              approve_Ratecard({
                variables: {
                  ref: id
                }
              });
            }}
          >
            Approve Ratecard
          </Button>
        )}
        {approvedState === false && (
          <Button
            style={{ marginRight: '10px' }}
            color="primary"
            variant="contained"
            href={link}
            className={classes.addButton}
          >
            Add Rate
          </Button>
        )}
        {/* Pending: restrict this function only for BRC and only for Supper Admin. Normal Cloning will be from Client's page */}
        {!(var_stage === 'Archived' || var_stage === 'Pending') && (
          <>
            <Button
              color="primary"
              variant="contained"
              className={classes.addButton}
              onClick={e => {
                e.preventDefault();
                clone_Ratecard({
                  variables: {
                    ref: id
                  }
                });
              }}
            >
              Clone Ratecard
            </Button>
          </>
        )}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
