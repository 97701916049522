import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import AuthContext from 'src/components/AuthContext';
import gql from 'graphql-tag';

import TextEditor from 'src/components/TextEditor';

import { useMutation, useQuery } from '@apollo/client';

const TasksList = gql`
  query Tasks($assigned_to_ref: String!) {
    Tasks(assigned_to_ref: $assigned_to_ref) {
      _id
      subscription_key
      title
      description
      collection_name
      collectionl_ref
      assigned_to_ref
      assigned_to_name
      assigned_by_ref
      assigned_by_name
      collection_title
      due_by
      completed_date
      status
    }
  }
`;

const updateTask = gql`
  mutation addTask($ref: ID!, $status: String, $completed_date: String) {
    updateTask(
      ref: $ref
      data: { status: $status, completed_date: $completed_date }
    ) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      justifyContent: 'flex-end'
    },
    date: {
      width: 'fit-content',
      backgroundColor: '#f4f6fc',
      padding: '5px 15px',
      borderRadius: '5px',
      display: 'table-caption',
      textAlign: 'center',
      fontWeight: '500',
      lineHeight: '1.1em'
    },
    link: {
      fontWeight: '600',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    }
  };
});

const Tasks = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const [taskList, setTaskList] = useState([]);
  const { userData } = useContext(AuthContext);

  const TasksResults = useQuery(TasksList, {
    variables: {
      assigned_to_ref: userData._id
    },
    fetchPolicy: 'no-cache'
  });

  const [update_Task] = useMutation(updateTask, {
    onCompleted() {
      TasksResults.refetch();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: TasksList }]
  });

  useEffect(() => {
    if (TasksResults.data) setTaskList(TasksResults.data.Tasks);
  }, [TasksResults]);

  const handleCheck = item => {
    // console.log(item);
    let status = 'Pending';
    if (item.status !== 'Completed') status = 'Completed';
    const data = {
      ref: item._id,
      status: status,
      completed_date: moment()
    };
    update_Task({
      variables: data
    });
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <CardHeader title="Tasks" />
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* <TableCell>Status</TableCell> */}

            {/* <TableCell width="5%"></TableCell> */}
            <TableCell>Due Date</TableCell>
            <TableCell>Task</TableCell>
            <TableCell>Assigned by</TableCell>
            <TableCell>Complete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {taskList.map(
            item =>
              item.status !== 'Completed' && (
                <TableRow hover key={item._id}>
                  <TableCell>
                    {/* {moment.unix(item.due_by / 1000).format('DD/MM/YYYY')} */}
                    <Box className={classes.date}>
                      {moment.unix(item.due_by / 1000).format('MMM DD')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/jobunits/jobunit/${item.collectionl_ref}`}
                      className={classes.link}
                    >
                      {item.collection_title}
                    </Link>
                    <TextEditor
                      textEditorInput={item.title}
                      isInput={false}
                      canEdit={false}
                      size={'small'}
                    />
                  </TableCell>

                  <TableCell>{item.assigned_by_name}</TableCell>

                  <TableCell>
                    <Checkbox
                      edge="start"
                      checked={item.status === 'Completed'}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => handleCheck(item)}
                    />
                  </TableCell>

                  {/* <TableCell></TableCell> */}

                  {/* <TableCell>
                        <Chip
                          color="primary"
                          label={item.status}
                          size="small"
                        />
                      </TableCell> */}
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="flex-end" p={2}>
        {/* <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button> */}
      </Box>
    </Card>
  );
};

Tasks.propTypes = {
  className: PropTypes.string
};

export default Tasks;
