import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Logo from 'src/components/Logo';

const useStyles = makeStyles()(() => {
  return {
    root: {},
    toolbar: {
      height: 64
    }
  };
});

const TopBar = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <AppBar className={cx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
