import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const JobsContainers = gql`
  query JobsContainersList($search: JobContainerArgs, $ops: functionalArgs) {
    JobsContainersList(search: $search, ops: $ops) {
      info {
        total_records
        restricted_fields
      }
      data {
        _id
        title
        total_budget
        remaining
        stage
        client {
          client_name
        }
        units {
          _id
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const SubResults = ({ className, stagevalue, ...rest }) => {
  const { classes, cx } = useStyles();

  //limit = how many to show per page
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  const [restrictedfields, setRestrictedfields] = useState('');
  // console.log(stagevalue);

  // get Job Containers by Stage (stagevalue)
  const {
    loading: loadingJobsContainers,
    data: dataJobsContainers,
    error: errorJobsContainers,
    refetch
  } = useQuery(JobsContainers, {
    variables: {
      search: { stage: stagevalue },
      ops: { page: 0, perpage: 10 }
    },
    fetchPolicy: 'no-cache'
  });

  // callback for Job Containers query
  useEffect(() => {
    const onCompleted = dataJobsContainers => {
      setLength(dataJobsContainers.JobsContainersList.info.total_records);
      setRestrictedfields(
        dataJobsContainers.JobsContainersList.info.restricted_fields
      );
    };
    const onError = errorJobsContainers => {
      console.log('query errorJobsContainers', errorJobsContainers);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingJobsContainers && !errorJobsContainers) {
        onCompleted(dataJobsContainers);
      } else if (onError && !loadingJobsContainers && errorJobsContainers) {
        onError(errorJobsContainers);
      }
    }
  }, [loadingJobsContainers, dataJobsContainers, errorJobsContainers]);

  const handleLimitChange = event => {
    setPage(0);
    setLimit(event.target.value);
    refetch({
      search: { stage: stagevalue },
      ops: { page: 0, perpage: event.target.value }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    refetch({
      search: { stage: stagevalue },
      ops: { page: newPage, perpage: limit }
    });
  };

  const currencyformatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0
  });

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Total Budget</TableCell>
              {stagevalue === 'live' && <TableCell>Remaining Budget</TableCell>}
              <TableCell>Units</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataJobsContainers &&
              dataJobsContainers.JobsContainersList.data.map(element => (
                <TableRow hover key={element._id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={element.avatarUrl}
                      >
                        {getInitials(element.title)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <Link to={{ pathname: `job/${element._id}` }}>
                          {element.title}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{element.client.client_name}</TableCell>

                  <TableCell>
                    {currencyformatter.format(element.total_budget)}
                  </TableCell>

                  {stagevalue === 'live' && (
                    <TableCell>
                      {currencyformatter.format(element.remaining)}
                      <LinearProgress
                        value={(element.remaining * 100) / element.total_budget}
                        variant="determinate"
                      />
                    </TableCell>
                  )}

                  <TableCell>{element.units.length}</TableCell>

                  <TableCell>
                    <Link to={{ pathname: `job/${element._id}` }}>
                      <VisibilityIcon style={{ color: '#bdbdbd' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

SubResults.propTypes = {
  className: PropTypes.string
};

export default SubResults;
