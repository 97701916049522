import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import GoogleDriveDeleteIcon from './GoogleDriveDeleteIcon';
import GoogleDriveIcon from '../../icons/GoogleDriveIcons';

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: 240,
      flexGrow: 1,
      maxWidth: 400
    },
    labelIcon: {
      marginRight: '10px'
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center'
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    },
    binIcon: {
      height: '30px'
    }
  };
});

function GoogleDriveFileTree(props) {
  const classes = useStyles();
  const folderID = props.folderID ? props.folderID : '';
  const uploadedFile = props.uploadedFile ? props.uploadedFile : '';

  const [state, setState] = useState({
    folderID,
    uploadedFile,
    fileTree: null
  });

  useEffect(() => {
    if (uploadedFile) {
      updateList();
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (
      props.folderID !== state.folderID ||
      props.uploadedFile !== state.uploadedFile
    ) {
      updateList();
    }
  }, [props.folderID, props.uploadedFile, state.folderID, state.uploadedFile]);

  const updateList = () => {
    const folderID = props.folderID ? props.folderID : '';
    setState({ ...state, folderID });
    axios
      .get('/getFileTree', {
        params: {
          folderID: folderID
        }
      })
      .then(rest => {
        setState({ ...state, fileTree: rest.data });
      });
  };

  const removeFile = fileID => {
    const newList = state.fileTree.filter(item => item.id !== fileID);
    setState({ ...state, fileTree: newList });
  };

  // eslint-disable-next-line
  const processDrive = driveList => {
    driveList.sort((a, b) => (a.name > b.name ? 1 : -1));
    driveList.sort((a, b) =>
      a.mimeType === 'application/vnd.google-apps.folder' &&
      a.mimeType !== b.mimeType
        ? -1
        : 1
    );
    if (typeof driveList === 'object' && Object.keys(driveList).length > 0)
      return driveList.map(item => (
        <TreeItem
          nodeId={item.id}
          key={item.id}
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  marginRight: '10px'
                }}
              >
                <GoogleDriveIcon type={item.mimeType} />
              </Box>

              <Typography
                variant="body2"
                sx={{ fontWeight: 'inherit', flexGrow: 1 }}
              >
                {item.name}
              </Typography>
              <span>
                {/* <DeleteIcon onClick={() => this.removeFile(item.id)} className={classes.binIcon} /> */}
                <GoogleDriveDeleteIcon file={item} response={removeFile} />
              </span>
              <Typography variant="caption" color="inherit">
                <a href={item.webViewLink} target="_blank" rel="noreferrer">
                  {<LaunchIcon />}
                </a>
              </Typography>
            </Box>
          }
        >
          {typeof item.children !== 'undefined' &&
            item.children.length > 0 &&
            processDrive(item.children)}
        </TreeItem>
      ));
  };

  return (
    <TreeView
      // className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {state.fileTree && processDrive(state.fileTree)}
    </TreeView>
  );
}
export default GoogleDriveFileTree;
