import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    importButton: {
      marginRight: theme.spacing(1),
      marginLeft: 'auto'
    },
    exportButton: {
      marginRight: theme.spacing(1)
    }
  };
});

const Toolbar = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box display="flex">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Job Unit List
        </Typography>
        <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
