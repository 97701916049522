import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Container, Grid, Card, CardHeader } from '@mui/material';
import Page from 'src/components/Page';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import RoleDetails from './RoleDetails';
import AddRole from './AddRole';

export const Departments = gql`
  query Departments($search: departmentArgs, $ops: functionalArgs) {
    Departments(search: $search, ops: $ops) {
      data {
        _id
        parent_ref
        name
        is_sub_department
        tags
        roles {
          title
          _id
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    }
  };
});

const SingleDepartment = () => {
  const { classes } = useStyles();
  const elementId = useParams();

  console.log('elementId', elementId);

  const {
    data: dataDepartment,
    loading: loadingDepartment,
    error: errorDepartment
  } = useQuery(Departments, {
    variables: {
      search: { _id: elementId.id }
    }
  });

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Page className={classes.root} title="Department">
      {console.log('elementId', elementId)}
      {!loadingDepartment && dataDepartment && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Profile element={dataDepartment.Departments.data[0]} />
            </Grid>
            <Grid item xs={12}>
              <ProfileDetails element={dataDepartment.Departments.data[0]} />
            </Grid>

            {dataDepartment.Departments.data[0].roles && (
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Roles" />
                  {dataDepartment.Departments.data[0].roles.map(role => (
                    <Accordion
                      key={role._id}
                      expanded={expanded === role._id}
                      onChange={handleChange(role._id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {role.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <RoleDetails
                          id={dataDepartment.Departments.data[0]._id}
                          departmenttrole={role}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Card>
              </Grid>
            )}

            <Grid item xs={12}>
              <AddRole id={dataDepartment.Departments.data[0]._id} />
            </Grid>
          </Grid>
        </Container>
      )}
      {/* <Query query={Department} variables={{ id: elementId.id }}>
        {({ loading, error, data }) => {
          console.log('data', data);
          if (error) console.log('error', data);
          if (loading || !data) return <h1>Loading...</h1>;

          return (
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Profile element={data.Department} />
                </Grid>
                <Grid item xs={12}>
                  <ProfileDetails element={data.Department} />
                </Grid>

                {data.Department[0].roles && (
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Roles" />
                      {data.Department[0].roles.map(role => (
                        <Accordion
                          key={role._id}
                          expanded={expanded === role._id}
                          onChange={handleChange(role._id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className={classes.heading}>
                              {expanded ? 'Edit Role' : role.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RoleDetails
                              id={data.Department[0]._id}
                              departmenttrole={role}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <AddRole id={data.Department[0]._id} />
                </Grid>
              </Grid>
            </Container>
          );
        }}
      </Query> */}

      {/* <Query query={Department} variables={{ id: elementId.id }}>
        {
          (({ loading, error, data }) => error && console.log('error', error),
          !loading && (
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Profile element={data.Department} />
                </Grid>
                <Grid item xs={12}>
                  <ProfileDetails element={data.Department} />
                </Grid>

                {data.Department[0].roles && (
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Roles" />
                      {data.Department[0].roles.map(role => (
                        <Accordion
                          key={role._id}
                          expanded={expanded === role._id}
                          onChange={handleChange(role._id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className={classes.heading}>
                              {expanded ? 'Edit Role' : role.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RoleDetails
                              id={data.Department[0]._id}
                              departmenttrole={role}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <AddRole id={data.Department[0]._id} />
                </Grid>
              </Grid>
            </Container>
          ))
        }
      </Query> */}
    </Page>
  );
};

export default SingleDepartment;
