import React, { useState, useRef } from 'react';
import { useRoutes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import axios from 'axios';
import CheckAuth from 'src/components/CheckAuth';
import AuthContext from 'src/components/AuthContext';

axios.defaults.baseURL = process.env.REACT_APP_GQL_BASE_URL + '/api/';

axios.defaults.headers.common['x-access-token'] = localStorage.getItem(
  'authToken'
);
axios.defaults.headers.post['Content-Type'] = 'application/json';

const App = () => {
  const ref = useRef();
  const routing = useRoutes(routes);
  const [userData, setUserData] = useState({});

  const location = useLocation();

  const restrictedRoutes = localStorage.getItem('restrictedRoutes');
  // const allowedRoutes = localStorage.getItem('allowedRoutes');

  // console.log('location', location.pathname);
  // console.log('allowedRoutes', allowedRoutes);
  // console.log('restrictedRoutes', restrictedRoutes);
  // console.log('is allowed', allowedRoutes.includes(location.pathname));

  const setUserDataSetter = data => {
    setUserData(data);
  };

  const handlelogin = () => {
    ref.current.loginUser();
  };

  const handleLogout = () => {
    ref.current.LogoutUser();
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CheckAuth setUserData={setUserDataSetter} ref={ref} />
        <AuthContext.Provider
          value={{
            userData: userData,
            logoutUser: handleLogout,
            loginUser: handlelogin
          }}
        >
          {routing}

          {/* replace includes() */}
          {/* {restrictedRoutes.includes(location.pathname) ? (
            <Navigate
              to="/restricted"
              state={{
                message: location.pathname + ' is restricted for you'
              }}
            />
          ) : (
            routing
          )} */}
        </AuthContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
