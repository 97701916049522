import React from 'react';
import { Box } from '@mui/material';

function Notesvg() {
  return (
    <Box
      style={{
        width: '150px',
        margin: '0 auto',
        marginBottom: '45px'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 107 73.2"
        version="1.1"
        viewBox="0 0 107 73.2"
        xmlSpace="preserve"
      >
        <path
          fill="#6ED6B6"
          d="M101.5 73.2h-96c-3.1 0-5.5-2.5-5.5-5.5V5.5C0 2.5 2.5 0 5.5 0h95.9c3.1 0 5.5 2.5 5.5 5.5v62.1c.1 3.1-2.4 5.6-5.4 5.6z"
        ></path>
        <path
          fill="#FFF"
          d="M91.2 58.7H15.8c-.4 0-.6-.3-.6-.6v-.2c0-.4.3-.6.6-.6h75.5c.4 0 .6.3.6.6v.1c0 .4-.3.7-.7.7zM91.2 47.8H15.8c-.4 0-.6-.3-.6-.6V47c0-.4.3-.6.6-.6h75.5c.4 0 .6.3.6.6v.2c0 .3-.3.6-.7.6zM91.2 36.9H15.8c-.4 0-.6-.3-.6-.6V36c0-.4.3-.6.6-.6h75.5c.4 0 .6.3.6.6v.2c0 .4-.3.7-.7.7zM91.2 26H15.8c-.4 0-.6-.3-.6-.6v-.2c0-.4.3-.6.6-.6h75.5c.4 0 .6.3.6.6v.2c0 .3-.3.6-.7.6z"
        ></path>
        <path
          fill="none"
          stroke="#FFF"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M30.6 17.7H16.9c-1 0-1.7-.8-1.7-1.7v-.9c0-1 .8-1.7 1.7-1.7h13.7c1 0 1.7.8 1.7 1.7v.9c0 .9-.8 1.7-1.7 1.7zM89.8 17.4h-5.9c-.9 0-1.5-.7-1.5-1.5v-.5c0-.9.7-1.5 1.5-1.5h5.9c.9 0 1.5.7 1.5 1.5v.5c.1.8-.6 1.5-1.5 1.5z"
        ></path>
      </svg>
    </Box>
  );
}

export default Notesvg;
