import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { makeStyles } from 'tss-react/mui';
import { Container, Grid, Card, CardHeader, Divider } from '@mui/material';
import Page from 'src/components/Page';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Ratecard } from 'src/queries/query';
import ProfileDetails from './ProfileDetails';
import RatesDetails from './RatesDetails';
import Toolbar from './Toolbar';

export const Departments = gql`
  query Departments($search: departmentArgs) {
    Departments(search: $search) {
      data {
        _id
        name
        is_sub_department
        roles {
          title
          _id
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  };
});

const RatecardView = () => {
  const { classes } = useStyles();
  const ratecardId = useParams();
  // console.log(ratecardId);
  const [expanded, setExpanded] = useState(false);

  // edit rate cell
  const [isEditMode, setIsEditMode] = useState(false);
  const onToggleEditMode = value => {
    setIsEditMode(value);
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const is_sub_department = true;
  const { data } = useQuery(Departments, {
    variables: { search: { is_sub_department } }
  });
  const dataDep = data;
  // console.log('dataDep', dataDep);

  return (
    <Page className={classes.root} title="Ratecard">
      <Query query={Ratecard} variables={{ id: ratecardId.id }}>
        {({ loading, data }) =>
          !loading && (
            <Container maxWidth="lg">
              <Toolbar
                id={ratecardId.id}
                var_approved={data.Ratecard[0].approved}
                var_stage={data.Ratecard[0].stage}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProfileDetails element={data.Ratecard} />
                </Grid>

                {data.Ratecard[0].rates ? (
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Rates" />
                    </Card>

                    <Divider />
                    {dataDep &&
                      dataDep.Departments.data.map(department => (
                        <Accordion
                          key={department._id}
                          expanded={expanded === department._id}
                          onChange={handleChange(department._id)}
                          TransitionProps={{ unmountOnExit: true }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className={classes.heading}>
                              {department.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div key={department._id}>
                              <RatesDetails
                                id={ratecardId.id}
                                rates={data.Ratecard[0].rates.filter(
                                  element =>
                                    element.department_name === department.name
                                )}
                                department={department.name}
                                var_approved={data.Ratecard[0].approved}
                              />
                              <Divider />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Grid>
                ) : null}
              </Grid>
            </Container>
          )
        }
      </Query>
    </Page>
  );
};

export default RatecardView;
