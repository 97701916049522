import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const RestrictedView = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const location = useLocation();
  const message = location.state?.message;

  return (
    <Page className={classes.root} title="Restricted">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          Access Denied. You dont have permission to view this page.
          <br />
          {message && message}
        </Container>
      </Box>
    </Page>
  );
};

export default RestrictedView;
