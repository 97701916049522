import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const IsOperationAllowed = gql`
  query IsOperationAllowed($caller_function: String!) {
    IsOperationAllowed(caller_function: $caller_function)
  }
`;

const Restricted = ({ operation, children }) => {
  const {
    data: dataRight,
    loading: loadingRight,
    error: errorRight
  } = useQuery(IsOperationAllowed, {
    variables: { caller_function: operation },
    fetchPolicy: 'no-cache'
  });

  // callback for IsOperationAllowed query
  useEffect(() => {
    const onCompleted = dataRight => {
      // console.log('dataRight', dataRight.Right);
    };
    const onError = errorRight => {
      console.log('errorRight', errorRight);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRight && !errorRight) {
        onCompleted(dataRight);
      } else if (onError && !loadingRight && errorRight) {
        onError(errorRight);
      }
    }
  }, [loadingRight, dataRight, errorRight]);

  return (
    // If dataRight = true, render the children
    // Otherwise, do not render anything

    dataRight ? children : null
  );
};

export default Restricted;
