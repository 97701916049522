import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Alert, AlertTitle } from '@mui/lab';
import { useQuery, useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import gql from 'graphql-tag';

const SelectRatecard = gql`
  query SelectRatecard($client_ref: ID) {
    SelectRatecard(client_ref: $client_ref) {
      _id
      title
      approved
    }
  }
`;

const containerRatecard = gql`
  mutation containerRatecard($ref: ID!, $container_ref: ID!, $title: String) {
    containerRatecard(ref: $ref, container_ref: $container_ref, title: $title) {
      title
      _id
    }
  }
`;

const Ratecard = gql`
  query Ratecard($id: ID, $container_reference: String) {
    Ratecard(_id: $id, container_reference: $container_reference) {
      _id
      title
      approved
    }
  }
`;

const removeContainerRatecard = gql`
  mutation removeContainerRatecard($ref: ID!, $container_ref: ID!) {
    removeContainerRatecard(ref: $ref, container_ref: $container_ref) {
      title
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    pushright: { marginLeft: 'auto' },
    marginbottom: { marginBottom: '15px' },
    sidepadding: {
      padding: '0 15px'
    },
    approved: {
      borderColor: theme.palette.green.main,
      color: theme.palette.green.main,
      pointerEvents: 'none'
    },
    marginleft: { marginLeft: '15px' }
  };
});

const RatecardsDetails = ({
  className,
  jobref,
  activeratecard,
  clientref,
  fromRatecardsDetails,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const [errorallert, setErrorallert] = useState('');
  const [childactiveratecard, setChildactiveratecard] = useState('');
  const [showratecard, setShowratecard] = useState(false);
  const [ratecardtitle, setRatecardtitle] = useState('');
  const [ratecardlink, setRatecardlink] = useState('');
  const [ratecardapproved, setRatecardapproved] = useState(false);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.RatecardsDetails === false ? false : true
  );

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  useEffect(() => {
    setChildactiveratecard(activeratecard);
  }, [activeratecard]);

  const [state, setState] = useState({
    approved: false,
    ref: '',
    title: ''
  });

  // console.log('clientref', clientref);
  // console.log('activeratecard', activeratecard);
  // console.log('childactiveratecard', childactiveratecard);

  // get active Ratecard
  const {
    data: dataRatecard,
    loading: loadingRatecard,
    error: errorRatecard,
    refetch
  } = useQuery(Ratecard, {
    variables: { container_reference: jobref },
    fetchPolicy: 'no-cache'
  });
  // callback for active Ratecard
  useEffect(() => {
    const onCompleted = dataRatecard => {
      //
      // console.log('dataRatecard', dataRatecard.Ratecard.length);
      if (dataRatecard.Ratecard.length > 0) {
        setShowratecard(true);
        setRatecardtitle(dataRatecard.Ratecard[0].title);
        setRatecardlink(dataRatecard.Ratecard[0]._id);
        setRatecardapproved(dataRatecard.Ratecard[0].approved);
        // console.log('dataRatecard', dataRatecard);
      }
    };
    const onError = errorRatecard => {
      console.log('query error Acive Ratecard', errorRatecard);
      setErrorallert(
        String(errorRatecard).replace('Error: GraphQL error: ', '')
      );
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRatecard && !errorRatecard) {
        onCompleted(dataRatecard);
      } else if (onError && !loadingRatecard && errorRatecard) {
        onError(errorRatecard);
      }
    }
  }, [dataRatecard, loadingRatecard, errorRatecard]);

  // get ratecards for dropdown
  const {
    loading: loadingSelectRatecard,
    data: dataSelectRatecard,
    error: errorSelectRatecard
  } = useQuery(SelectRatecard, {
    variables: { client_ref: clientref },
    fetchPolicy: 'no-cache'
  });

  // callback for ratecards in dropdown
  useEffect(() => {
    const onCompleted = dataSelectRatecard => {
      // console.log('dataSelectRatecard', dataSelectRatecard.SelectRatecard);
    };
    const onError = errorSelectRatecard => {
      console.log('query errorSelectRatecard', errorSelectRatecard);
      setErrorallert(
        String(errorSelectRatecard).replace('Error: GraphQL error: ', '')
      );
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingSelectRatecard && !errorSelectRatecard) {
        onCompleted(dataSelectRatecard);
      } else if (onError && !loadingSelectRatecard && errorSelectRatecard) {
        onError(errorSelectRatecard);
      }
    }
  }, [loadingSelectRatecard, dataSelectRatecard, errorSelectRatecard]);

  // update ratecard
  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [
    container_Ratecard,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(containerRatecard, {
    onCompleted(data) {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);

      // console.log('ratecard added', data);
      // console.log('ratecard added id', data.containerRatecard._id);
      fromRatecardsDetails(false, data.containerRatecard._id); // close dialog ratecard
      // update selectedratecard
      setChildactiveratecard(data.containerRatecard._id);
      refetch();
    },
    onError: err => {
      setErrorallert(String(err).replace('Error: GraphQL error: ', ''));
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const [remove_ContainerRatecard] = useMutation(removeContainerRatecard, {
    onCompleted() {
      setChildactiveratecard('');
      setShowratecard(false);
    },
    onError: err => {
      setErrorallert(String(err).replace('Error: GraphQL error: ', ''));
      console.log(err);
    }
  });

  const removeRatecard = event => {
    // console.log('removeRatecard');

    remove_ContainerRatecard({
      variables: {
        ref: childactiveratecard,
        container_ref: jobref
      }
    });
  };

  useEffect(() => {
    // console.log('update childactiveratecard', childactiveratecard);
    refetch();
  }, [childactiveratecard]);

  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.RatecardsDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  return (
    <Card>
      <CardHeader
        title="Ratecard"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent>
          {errorallert && (
            <Alert className={classes.marginbottom} severity="error">
              <AlertTitle>{errorallert}</AlertTitle>
            </Alert>
          )}
          {childactiveratecard
            ? showratecard && (
                <Box display="flex" alignItems="center" p={2}>
                  <Link
                    to={{
                      pathname: `/ratecards/ratecard/${ratecardlink}`
                    }}
                    target="_blank"
                  >
                    {ratecardtitle}
                  </Link>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={cx(
                      classes.pushright,
                      ratecardapproved ? classes.approved : null
                    )}
                    disabled={!ratecardapproved}
                    startIcon={ratecardapproved ? <CheckRoundedIcon /> : null}
                  >
                    {ratecardapproved ? 'Approved' : 'Not Approved'}
                  </Button>

                  {!ratecardapproved && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={removeRatecard}
                      className={classes.marginleft}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              )
            : dataSelectRatecard && (
                // dataSelectRatecard.SelectRatecard.map(item => <div>{item.title}</div>)
                <form
                  autoComplete="off"
                  onSubmit={e => {
                    e.preventDefault();
                    setIsloading(true);
                    container_Ratecard({
                      variables: {
                        ref: state.ref,
                        container_ref: jobref,
                        title: state.title
                      }
                    });
                  }}
                >
                  <Autocomplete
                    id="SelectRatecardlist"
                    options={dataSelectRatecard.SelectRatecard}
                    getOptionLabel={option => option.title}
                    onChange={(event, values) => {
                      if (values) {
                        setState({
                          ...state,
                          ref: values._id,
                          title: values.title
                        });
                      } else {
                        setState({
                          ...state,
                          ref: '',
                          title: ''
                        });
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Ratecard"
                        name="ratecard"
                        variant="outlined"
                      />
                    )}
                  />

                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    p={2}
                  >
                    {askareyousure ? (
                      <>
                        <Alert
                          className={classes.sidepadding}
                          severity="warning"
                        >
                          <AlertTitle>
                            It can not be changed once approved
                          </AlertTitle>
                        </Alert>
                        <button
                          type="submit"
                          id="button"
                          className={`btn-animate ${
                            isloading ? 'onclic' : null
                          } ${!isloading && completed ? 'validate' : null} ${
                            iserror ? 'novalidate' : null
                          }`}
                        ></button>
                      </>
                    ) : (
                      <button
                        onClick={ask}
                        id="ask"
                        className={`btn-animate ask`}
                      ></button>
                    )}
                  </Box>
                </form>
              )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

RatecardsDetails.defaultProps = {
  fromRatecardsDetails: ''
};

export default RatecardsDetails;
