import React from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import GoogleDriveFolder from '../../../components/googleComponents/GoogleDriveFolder';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const SettingsView = () => {
  const { classes } = useStyles();

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <GoogleDriveFolder folderName="Uploads" />
        {/* <GoogleDriveFolder folderID="1O-ztuy2G2o1qiFmirwRrjlI4scyfQgfz"/> AMS base folder */}
        {/* <GoogleDriveFolder folderID="1QCnO9TklGRZV5avxpO4Xu9HSRKrEC4O7"/> */}
      </Container>

      <Container maxWidth="lg">
        <Notifications />
        <Box mt={3}>
          <Password />
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
