import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';

import SubResults from './SubResults';

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}

const Results = ({ className, elements, ...rest }) => {
  const { classes } = useStyles();

  //define possible values for stage (and tabs order)
  const stagevalues = ['newbiz', 'live', 'complete'];

  //set default selected tab
  const [value, setValue] = React.useState('live');

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  // Mui Tab component
  // https://mui.com/material-ui/react-tabs/#main-content

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        position="static"
        color="transparent"
        className={classes.appbar}
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          variant="fullWidth"
          aria-label="Jobs"
        >
          <Tab label="New Biz" {...a11yProps('newbiz')} />
          <Tab label="Live" {...a11yProps('live')} />
          <Tab label="Completed" {...a11yProps('complete')} />
        </Tabs>
      </AppBar>

      {stagevalues.map((stagevalue, index) => (
        <TabPanel value={value} index={stagevalue} key={index}>
          <SubResults stagevalue={stagevalue} index={index} />
        </TabPanel>
      ))}
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
