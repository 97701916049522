import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

// https://www.material-react-table.com/
import MaterialReactTable from 'material-react-table';

import { makeStyles } from 'tss-react/mui';
import { Button, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AuthContext from 'src/components/AuthContext';

import Notes from 'src/views/notes/Notes';

const addJobForecast = gql`
  mutation addJobForecast($ref: ID!, $data: revrecInput!) {
    addJobForecast(ref: $ref, data: $data) {
      allotted_budget
    }
  }
`;

const updateJobForecast = gql`
  mutation updateJobForecast($ref: ID!, $amount: Float!) {
    updateJobForecast(ref: $ref, amount: $amount) {
      allotted_budget
    }
  }
`;

const RecogniseMonth = gql`
  mutation RecogniseMonth($data: revrecStatusInput!) {
    RecogniseMonth(data: $data) {
      _id
    }
  }
`;

const RevRec = gql`
  query Jobs($id: ID, $job_type: String) {
    Jobs(_id: $id, job_type: $job_type) {
      _id
      title
      container_title
      client {
        client_ref
        client_name
      }
      allotted_budget
      estimated_ftc
      total_revrec
      total_forecast
      available_for_estimate
      available_for_revrec
      total_revrec_and_forecast
      revrec {
        _id
        column_title
        month
        amount
      }
    }
  }
`;

const RecognisedMonths = gql`
  query RecognisedMonths {
    RecognisedMonths {
      column_title
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& table': {
        borderCollapse: 'separate',
        borderSpacing: 0
      }
    }
  };
});

const Results = () => {
  const [open, setOpen] = React.useState(false);
  const [hidecol, setHidecol] = React.useState(true);
  const { userData } = useContext(AuthContext);
  const [datatable, setDatatable] = useState([]);
  const [dRecognisedMonths, setDRecognisedMonths] = useState([]);
  const [totalallotted_budget, setTotalallotted_budget] = useState();

  const currencyFormat = {
    Cell: ({ cell }) => (
      <>
        {cell.getValue()?.toLocaleString?.('en-UK', {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}
      </>
    )
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [
    add_JobForecast,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addJobForecast, {
    onCompleted() {
      console.log('added forecast');
    },
    onError: err => {
      console.log(err);
    },
    refetchQueries: [{ query: RevRec }]
  });

  const [
    update_JobForecast,
    { loading: mutationupdateLoading, error: mutationupdateError }
  ] = useMutation(updateJobForecast, {
    onCompleted() {
      console.log('updated forecast');
    },
    onError: err => {
      console.log(err);
    },
    refetchQueries: [{ query: RevRec }]
  });

  const [
    Recognise_Month,
    { loading: mutationRecogniseLoading, error: mutationRecogniseError }
  ] = useMutation(RecogniseMonth, {
    onCompleted() {
      console.log(' Recognise_Month done');
    },
    onError: err => {
      console.log(err);
    },
    refetchQueries: [{ query: RevRec }]
  });

  ////////////////////////////////
  //
  // to be considered for infinite scroll
  // https://www.material-react-table.com/docs/guides/row-virtualization#what-is-virtualization?
  //
  //
  // good grouping example
  //https://codesandbox.io/s/github/KevinVandy/material-react-table/tree/main/material-react-table-docs/examples/aggregation-and-grouping/sandbox?file=/src/JS.js:3666-3728
  //
  ////////////////////////////////

  //********************************************** */
  // get jobs
  const {
    loading: loadingRecognisedMonths,
    data: dataRecognisedMonths,
    error: errorRecognisedMonths
  } = useQuery(RecognisedMonths);

  // callback for above query
  useEffect(() => {
    const onCompleted = dataRecognisedMonths => {
      //chage data to prepare for the table here
      console.log('dataRecognisedMonths', dataRecognisedMonths);
      const newData = [];
      const tempData = [];
      dataRecognisedMonths.RecognisedMonths.map((rmonth, index) => {
        tempData.push(rmonth.column_title);
      });
      setDRecognisedMonths(tempData);
    };
    const onError = errorRecognisedMonths => {
      console.log('query errorRecognisedMonths', errorRecognisedMonths);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRecognisedMonths && !errorRecognisedMonths) {
        onCompleted(dataRecognisedMonths);
      } else if (onError && !loadingRecognisedMonths && errorRecognisedMonths) {
        onError(errorRecognisedMonths);
      }
    }
  }, [loadingRecognisedMonths]);

  //********************************************** */

  // get jobs
  const {
    loading: loadingRevRec,
    data: dataRevRec,
    error: errorRevRec,
    refetch
  } = useQuery(RevRec, {
    variables: { job_type: 'Normal' }
  });

  // callback for jobs query
  useEffect(() => {
    const onCompleted = dataRevRec => {
      //chage data to prepare for the table here
      console.log('called');
      const newData = [];
      let tempData = [];
      dataRevRec.Jobs.map((job, index) => {
        tempData = { ...job };
        job.revrec.map((revrec, index) => {
          const month = revrec.column_title;
          const amount = revrec.amount;
          tempData = { ...tempData, [month]: amount };
        });
        newData.push(tempData);
      });
      setDatatable(newData);
      console.log('awais', datatable);
      // console.log('Datatable', datatable);
      // const totalb = dataRevRec.Jobs.reduce(
      //   (acc, row) => acc + row.allotted_budget,
      //   0
      // );
      // setTotalallotted_budget(totalb);
    };
    const onError = errorRevRec => {
      console.log('query errorRevRec', errorRevRec);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRevRec && !errorRevRec) {
        onCompleted(dataRevRec);
      } else if (onError && !loadingRevRec && errorRevRec) {
        onError(errorRevRec);
      }
    }
  }, [loadingRevRec, dataRevRec, errorRevRec]);

  // calculate the totals for all columns in the table in a useMemo hook
  // see https://www.w3schools.com/react/react_usememo.asp
  const totalBudget = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.allotted_budget, 0);
  }, [datatable]);

  const totalConsumed = useMemo(() => {
    return datatable.reduce(
      (acc, row) => acc + row.total_revrec_and_forecast,
      0
    );
  }, [datatable]);

  const totalRemaining = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.available_for_revrec, 0);
  }, [datatable]);

  const totalRec = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.total_revrec, 0);
  }, [datatable]);

  const totalForecast = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.total_forecast, 0);
  }, [datatable]);

  // useEffect(() => {

  //   console.log(totalb);
  // }, [datatable]);

  const getnextMonthtitle = month => {
    const createdDate = new Date();
    createdDate.setDate(1);
    createdDate.setMonth(createdDate.getMonth() + month);
    return createdDate.toLocaleString('en', {
      month: 'short',
      year: 'numeric'
      // year: '2-digit'
    });
  };

  const getprevMonthtitle = month => {
    const createdDate = new Date();
    createdDate.setDate(0);
    createdDate.setMonth(createdDate.getMonth() - month);
    return createdDate.toLocaleString('en', {
      month: 'short',
      year: 'numeric'
      // year: '2-digit'
    });
  };

  const nextMonths = [];
  for (let i = 0; i < 7; i++) {
    ///////////////////////
    // ---------------------------------------------------------
    // *** to do:
    // - get list of RecognisedMonths and make enableEditing conditional based on list of RecognisedMonths
    // - calculate totals for months columns here:
    // const month = getnextMonthtitle(i);
    // const {total+month] = useMemo(() => {
    //   return datatable.reduce((acc, row) => acc + row.month, 0);
    // }, [datatable]);
    // ---------------------------------------------------------
    ///////////////////////

    // get array of all the recognised months
    console.log('recognised months list', dRecognisedMonths);
    const next_month_title = getnextMonthtitle(i);
    nextMonths.push({
      header: next_month_title,
      accessorKey: next_month_title,
      //enableEditing will be false if the month is recognised based on getnextMonthtitle with in the array of recognised months
      enableEditing: dRecognisedMonths.includes(next_month_title)
        ? false
        : true,
      enableColumnFilter: false,
      enableGrouping: false,
      ...currencyFormat,
      // add custom column action to Recognise and lock a Month
      renderColumnActionsMenuItems: ({ closeMenu, column }) => {
        return [
          <MenuItem
            key={column.id}
            onClick={() => {
              // // run mutation to recognise month and setColumns on mutation completed (+ find a better way to setColumns without .map() )
              blockMonth(column.id);
              // console.log('column', column);
              closeMenu();
            }}
          >
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Recognise Month</ListItemText>
          </MenuItem>
        ];
      }
    });
  }

  const prevMonths = [];
  for (let i = 0; i < 3; i++) {
    prevMonths.push({
      header: getprevMonthtitle(i),
      accessorKey: getprevMonthtitle(i),
      enableEditing: false,
      columnVisibility: hidecol,
      enableColumnFilter: false,
      enableGrouping: false,
      ...currencyFormat
    });
  }

  prevMonths.reverse();

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    console.log('columns updated');
    setColumns([
      {
        accessorKey: 'client.client_name',
        header: 'Client',
        enableEditing: false,
        enableGrouping: true,
        maincol: true,
        Footer: () => <div>Total</div>
      },
      {
        accessorKey: 'title',
        header: 'Job Title',
        enableEditing: false,
        enableGrouping: false,
        maincol: true
      },
      {
        accessorKey: 'container_title',
        header: 'Job Container',
        enableEditing: false,
        enableGrouping: true,
        maincol: true
      },
      {
        accessorKey: 'allotted_budget',
        header: 'Budget',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'left'
        },
        // to add additional information based on grouping
        AggregatedCell: ({ cell }) => (
          <div>
            Group Budget {/* title of that section */}
            {cell.getValue().toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        ),
        Footer: () => (
          <div style={{ textAlign: 'left' }}>
            {totalBudget.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'total_revrec_and_forecast',
        header: 'Consumed',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'left'
        },
        Footer: () => (
          <div style={{ textAlign: 'left' }}>
            {totalConsumed.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'available_for_revrec',
        header: 'Remaining',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ cell }) => (
          <>
            {/* Example to add notes.
              Add additional prop to show a different and smaller btn
              <Notes type="job_unit" reference={cell.getValue()} /> */}
            {cell.getValue()}
          </>
        ),

        Footer: () => (
          <div style={{ textAlign: 'left' }}>
            {totalRemaining.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'total_revrec',
        header: 'Rec',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'left'
        },
        Footer: () => (
          <div style={{ textAlign: 'left' }}>
            {totalRec.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'total_forecast',
        header: 'Forecast',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'left'
        },
        Footer: () => (
          <div style={{ textAlign: 'left' }}>
            {totalForecast.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      ...prevMonths,
      ...nextMonths
    ]);
  }, [datatable, totalBudget]);

  const handleSaveCell = (cell, value) => {
    // console.log('cell.column.columnDef', cell.column.columnDef);
    // console.log('cell.row.original.revrec', cell.row.original.revrec);
    const var_column_title = cell.column.columnDef.accessorKey;
    let var_cell_id = '';
    cell.row.original.revrec.forEach(obj => {
      console.log('obj', obj.column_title);
      if (obj.column_title === var_column_title) {
        var_cell_id = obj._id;
      }
    });
    if (var_cell_id) {
      console.log('update');
      update_JobForecast({
        variables: {
          ref: var_cell_id,
          amount: parseFloat(value)
        }
      });
    } else {
      console.log('add');
      add_JobForecast({
        variables: {
          ref: cell.row.original._id,
          data: {
            // financial_year:"2022-2023" # Financial year
            // month:"December" # Month
            column_title: var_column_title,
            amount: parseFloat(value)
            // user_ref: userData._id,
            // user_name: userData.first_name + ' ' + userData.last_name
          }
        }
      });
    }

    //if the _id exist against "cell.column.columnDef.accessorKey" within cell.row.original.revrec then call update mutation
    // else add_JobForecast

    console.log('cell', cell);
    console.log('column_title', var_column_title);
    console.log('value', value);
    datatable[cell.row.index][cell.column.id] = value;
    setDatatable([...datatable]); //set new data
  };

  // blockMonth is actually recognise month
  const blockMonth = columnid => {
    setColumns(
      [...columns].map(object => {
        if (object.accessorKey === columnid) {
          return {
            ...object,
            enableEditing: false
          };
        }
        return object;
      })
    );
    // *** to do: get list of RecognisedMonths
    Recognise_Month({
      variables: {
        data: {
          financial_year: '2022-2023',
          // *** to do - make month name a variable
          month: 'November',
          column_title: columnid
        }
      }
    });
  };

  return (
    <div>
      {dataRevRec && (
        <MaterialReactTable
          columns={columns}
          data={datatable}
          editingMode="cell"
          enableEditing
          enableGrouping
          enablePinning
          enablePagination={false}
          enableDensityToggle={false}
          positionActionsColumn="last"
          // onEditingCellChange={cell => {
          //   console.log('cell', cell);
          // }}
          initialState={{
            //grouping: ['client.client_name'], //column to group by by default
            expanded: true, //expand all groups by default
            columnPinning: {
              left: ['client.client_name', 'title', 'container_title']
            },
            columnVisibility: {
              // hiding previous months
              [prevMonths[0].accessorKey]: false,
              [prevMonths[1].accessorKey]: false,
              [prevMonths[2].accessorKey]: false
            }
          }}
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              // '& tr:nth-of-type(odd)': {
              //   backgroundColor: '#f5f5f5'
              // }
            }
          }}
          muiTableHeadCellProps={({ column }) => ({
            sx: theme => ({
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between'
              },
              // borderRight: !column.columnDef.maincol
              //   ? '1px solid #e0e0e0'
              //   : 'none'
              borderRight: '1px solid #e0e0e0'
            })
          })}
          muiTableBodyCellProps={({ cell }) => ({
            sx: theme => ({
              borderRight: !cell.column.columnDef.maincol
                ? '1px solid #e0e0e0'
                : 'none',
              // borderRight: '1px solid #e0e0e0',
              backgroundColor:
                cell.column.columnDef.enableEditing ||
                cell.column.columnDef.maincol
                  ? '#fff'
                  : theme.palette.disabled.pink
            })
          })}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: event => {
              handleSaveCell(cell, event.target.value);
            }
          })}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="note"
            multiline
            maxRows={Infinity}
            type="textarea"
            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Results;
