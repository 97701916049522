import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';

import { Mutation } from '@apollo/client/react/components';

import { updateContact } from 'src/queries/query';

const ContactDetails = ({ className, id, clientcontact, ...rest }) => {
  return (
    <Mutation mutation={updateContact}>
      {(updateContact, { loading, error }) => (
        <form
          autoComplete="off"
          noValidate
          {...rest}
          onSubmit={e => {
            e.preventDefault();
            updateContact({
              variables: {
                contact_id: clientcontact._id,
                ref: id,
                data: {
                  email: e.target.email.value,
                  first_name: e.target.first_name.value,
                  last_name: e.target.last_name.value,
                  phone: e.target.phone.value,
                  mobile: e.target.mobile.value,
                  linkedin: e.target.linkedin.value
                }
              }
            });
          }}
        >
          <Card>
            <CardHeader
              subheader="The information can be edited"
              title="Client Contact"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    defaultValue={clientcontact.email}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Client First Name"
                    name="first_name"
                    defaultValue={clientcontact.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Client Last Name"
                    name="last_name"
                    defaultValue={clientcontact.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    defaultValue={clientcontact.phone}
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    defaultValue={clientcontact.mobile}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Linkedin"
                    name="linkedin"
                    defaultValue={clientcontact.linkedin}
                    type="text"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Mutation>
  );
};

export default ContactDetails;
