import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Divider, Button, Box, TextField, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import currencyformatter from 'src/utils/currencyformatter';

import { Alert, AlertTitle } from '@mui/lab';

import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';

const JobsContainers = gql`
  query JobsContainers($id: ID) {
    JobsContainers(_id: $id) {
      _id
      title
      total_budget
      units {
        _id
        allocated_budget
        jobunit_ref {
          title
          stage
          _id
        }
      }
      remaining
    }
  }
`;

const updateJobBudget = gql`
  mutation updateJobBudget($ref: ID!, $budget: Float!) {
    updateJobBudget(ref: $ref, budget: $budget) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    marginList: {
      marginRight: '10px'
    },
    marginLeft: {
      marginLeft: '10px'
    },
    txtRight: {
      marginLeft: '10px',
      textAlign: 'right'
    },
    widthProgress: {
      width: '100%'
    },
    fullProgress: {
      width: '100%',
      margin: '0 auto',
      '& $widthProgress': {
        width: '100%'
      }
    },
    marginListUnits: {
      marginLeft: '10px'
    }
  };
});

const JUBudgetDetails = ({
  className,
  budget,
  elementID,
  containerID,
  showForm,
  sendChildToParent,
  available_for_estimate,
  estimated_ftc,
  estimated_time,
  showContainer,
  ...rest
}) => {
  const { classes } = useStyles();

  const [opendialog, setOpendialog] = React.useState(false);
  const [errorbudget, setErrorbudget] = useState(false);
  const [container_allocated, setContainer_allocated] = useState('');
  const [initialsingle_budget, setInitialsingle_budget] = useState(budget);

  const [initialtotal_allocated, setInitialtotal_allocated] = useState(0);
  const [updatedtotal_allocated, setUpdatedtotal_allocated] = useState(0);

  // get container info
  const { data: dataJobContainer, loading: loadingJobContainer } = useQuery(
    JobsContainers,
    {
      skip: !showContainer,
      variables: { id: containerID },
      fetchPolicy: 'no-cache'
    }
  );

  // callback for container query
  useEffect(() => {
    if (showContainer) {
      const onCompleted = dataJobContainer => {
        setContainer_allocated(dataJobContainer.JobsContainers[0].total_budget);

        const total_allocated = () => {
          let total = 0;
          dataJobContainer.JobsContainers[0].units.forEach(item => {
            total += item.allocated_budget;
          });
          return total;
        };

        setInitialtotal_allocated(total_allocated());
      };
      if (onCompleted) {
        if (onCompleted && !loadingJobContainer) {
          onCompleted(dataJobContainer);
        }
      }
    }
  }, [loadingJobContainer, dataJobContainer, showContainer]);

  const [state, setState] = useState({
    allotted_budget: budget
  });

  //update (recalculate) remaining budget
  useEffect(() => {
    // (container budget - total allotted to all units)
    setUpdatedtotal_allocated(
      initialtotal_allocated -
        initialsingle_budget +
        parseInt(state.allotted_budget)
    );
  }, [
    state.allotted_budget,
    initialsingle_budget,
    initialtotal_allocated,
    budget
  ]);

  const [update_JobBudget] = useMutation(updateJobBudget, {
    onCompleted() {
      setOpendialog(false);
      sendChildToParent(false, parseFloat(state.allotted_budget));
      setErrorbudget(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setErrorbudget(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handleChangeBudget = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
    setErrorbudget(false);
    if (event.target.value < state.state_total_allocated) {
      setErrorbudget(true);
    }
  };

  const handleClosedialog = () => {
    sendChildToParent(false, parseFloat(state.allotted_budget));
  };

  const submitBudget = e => {
    e.preventDefault();
    update_JobBudget({
      variables: {
        ref: elementID,
        budget: parseFloat(state.allotted_budget)
      }
    });
  };

  //update budget if parent changes
  useEffect(() => {
    setState({
      ...state,
      allotted_budget: budget
    });
  }, [budget]);

  return (
    <>
      <List>
        {dataJobContainer &&
          dataJobContainer.JobsContainers[0].units.length > 0 && (
            <>
              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  Container Budget
                </ListItemIcon>
                <ListItemText
                  className={classes.txtRight}
                  primary={currencyformatter.format(
                    dataJobContainer.JobsContainers[0].total_budget
                  )}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  Remaining Container Budget
                </ListItemIcon>
                <ListItemText
                  className={classes.txtRight}
                  primary={currencyformatter.format(
                    container_allocated - updatedtotal_allocated
                  )}
                />
              </ListItem>

              <Divider component="li" />
            </>
          )}

        <ListItem>
          <ListItemIcon className={classes.marginList}>
            Allotted Budget
          </ListItemIcon>
          <ListItemText
            className={classes.txtRight}
            primary={currencyformatter.format(state.allotted_budget)}
          />
        </ListItem>
        {!showContainer && <Divider component="li" />}
        <ListItem>
          <ListItemIcon className={classes.marginList}>
            Estimated Time Cost
          </ListItemIcon>
          <ListItemText
            className={classes.txtRight}
            primary={currencyformatter.format(estimated_time)}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.marginList}>
            Estimated Fixed Cost
          </ListItemIcon>
          <ListItemText
            className={classes.txtRight}
            primary={currencyformatter.format(estimated_ftc)}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.marginList}>
            Remaining for Estimate
          </ListItemIcon>
          <ListItemText
            className={classes.txtRight}
            primary={currencyformatter.format(
              state.allotted_budget - estimated_time - estimated_ftc
            )}
          />
        </ListItem>

        <Divider component="li" />

        {!showContainer && (
          <>
            <ListItem>
              <ListItemIcon className={classes.marginList}>
                Estimated Costs
              </ListItemIcon>
            </ListItem>

            <ListItem>
              <ListItemIcon className={classes.fullProgress}>
                <LinearProgress
                  className={classes.widthProgress}
                  // (updatedtotal_costs * 100) / updatedtotal_allocated
                  value={
                    ((estimated_time + estimated_ftc) * 100) /
                    state.allotted_budget
                  }
                  variant="determinate"
                />
              </ListItemIcon>
            </ListItem>

            <ListItem>
              <ListItemIcon className={classes.marginList}>
                {currencyformatter.format(estimated_time + estimated_ftc) +
                  ' of ' +
                  currencyformatter.format(state.allotted_budget)}
              </ListItemIcon>
              <ListItemText
                className={classes.txtRight}
                primary={
                  currencyformatter.format(
                    state.allotted_budget - estimated_time - estimated_ftc
                  ) + ' left'
                }
              />
            </ListItem>
          </>
        )}

        {dataJobContainer &&
          dataJobContainer.JobsContainers[0].units.length > 0 && (
            <>
              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  Allotted Budget
                </ListItemIcon>
              </ListItem>

              <ListItem>
                <ListItemIcon className={classes.fullProgress}>
                  <LinearProgress
                    className={classes.widthProgress}
                    value={(updatedtotal_allocated * 100) / container_allocated}
                    variant="determinate"
                  />
                </ListItemIcon>
              </ListItem>

              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  {currencyformatter.format(state.allotted_budget) +
                    ' of ' +
                    currencyformatter.format(
                      dataJobContainer.JobsContainers[0].total_budget
                    )}
                </ListItemIcon>
                <ListItemText
                  className={classes.txtRight}
                  primary={
                    currencyformatter.format(
                      container_allocated - updatedtotal_allocated
                    ) + ' left'
                  }
                />
              </ListItem>
            </>
          )}

        <Divider component="li" />
      </List>
      {showForm && (
        <>
          <TextField
            fullWidth
            required
            label="Allotted Budget"
            name="allotted_budget"
            variant="outlined"
            margin="normal"
            defaultValue={state.allotted_budget}
            onChange={handleChangeBudget}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              )
            }}
          />

          {errorbudget && (
            <Alert severity="error">
              <AlertTitle>{errorbudget}</AlertTitle>
            </Alert>
          )}
          <Grid container justifyContent="flex-end">
            <Box m={2}>
              <Button onClick={handleClosedialog} color="primary">
                Close
              </Button>
            </Box>
            <Box mt={2} mb={2}>
              <Button
                onClick={submitBudget}
                color="primary"
                variant="contained"
                autoFocus
              >
                Save
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

JUBudgetDetails.defaultProps = {
  showForm: false,
  showContainer: true,
  sendChildToParent: '',
  budget: ''
};

export default JUBudgetDetails;
