import React, { useState, useEffect, useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { updateJob } from 'src/queries/query';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import currencyformatter from 'src/utils/currencyformatter';
import Autocomplete from '@mui/material/Autocomplete';
// import Alert from '@mui/lab/Alert';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import EditRoundedIcon from '@mui/icons-material/EditRounded';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import TextEditor from 'src/components/TextEditor';

import Notes from 'src/views/notes/Notes';
import Tasks from 'src/views/tasks/Tasks';
import JUBudgetDetails from 'src/components/JUBudgetDetails';

const useStyles = makeStyles()(() => {
  return {
    date: {
      width: '100%'
    },
    marginList: {
      marginRight: '10px'
    },
    widthProgress: {
      width: '200px'
    },
    box: {
      padding: '5px 10px 15px 10px',
      border: 'solid 2px #c4c4c4',
      borderRadius: '5px'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({
  className,
  element,
  stage,
  fromProfileDetails,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();
  const timer3 = React.useRef();
  const isPitch = element[0].job_type === 'Internal-Pitch' ? true : false;

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const [state, setState] = useState({
    department_reference: '',
    ratecard_reference: '',
    allotted_budget: element[0].allotted_budget,
    budget_form: stage === 'complete' ? false : true
    // contact_email : '',
    // client_ref : element[0].client_ref ? element[0].client_ref : '5fd40586d2522906b70ef208',
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [opendialog, setOpendialog] = React.useState(false);

  const handleClickOpendialog = () => {
    setOpendialog(true);
  };

  const handleClosedialog = () => {
    setOpendialog(false);
  };

  const sendChildToParent = (openModal, newBudget) => {
    console.log('from child', openModal, newBudget);
    setOpendialog(openModal);
    setState({
      ...state,
      allotted_budget: newBudget
    });
    fromProfileDetails(newBudget);
  };

  const [
    update_Job,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateJob, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    },
    onError: err => {
      setError(err);
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const [jobDesc, setJobDesc] = useState('');

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          update_Job({
            variables: {
              ref: element[0]._id,
              data: {
                title: e.target.title.value,
                description: jobDesc,
                department: isPitch ? '' : e.target.department.value,
                start_date: e.target.start_date.value,
                end_date: e.target.end_date.value
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Job Unit Details"
            // action={<Notes type="jobs" reference={element[0]._id} />}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={isPitch ? 12 : 8} xs={12}>
                <TextField
                  fullWidth
                  // helperText="Please specify the title"
                  label="Job title"
                  name="title"
                  defaultValue={element[0].title}
                  required
                  variant="outlined"
                  InputProps={{
                    readOnly: stage === 'complete' ? true : false
                  }}
                />
              </Grid>

              {!isPitch && (
                <Grid item md={4} xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        required
                        label="Allotted Budget"
                        name="allotted_budget"
                        value={currencyformatter.format(state.allotted_budget)}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        onClick={
                          stage !== 'complete' ? handleClickOpendialog : null
                        }
                      />
                    </Grid>
                    {stage !== 'complete' && (
                      <Grid item>
                        <EditRoundedIcon onClick={handleClickOpendialog} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item md={isPitch ? 6 : 3} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  required
                  label="Start Date"
                  name="start_date"
                  defaultValue={
                    element[0].start_date
                      ? new Date(parseInt(element[0].start_date))
                          .toISOString()
                          .slice(0, 10)
                      : null
                  }
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete' ? true : false
                  }}
                />
              </Grid>

              <Grid item md={isPitch ? 6 : 3} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  required
                  label="End Date"
                  name="end_date"
                  defaultValue={
                    element[0].end_date
                      ? new Date(parseInt(element[0].end_date))
                          .toISOString()
                          .slice(0, 10)
                      : null
                  }
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete' ? true : false
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextEditor
                  textEditorOutput={textEditorOutput}
                  textEditorInput={element[0].description}
                  canEdit={stage === 'complete' ? false : true}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Notes type="jobs" reference={element[0]._id} />
            <Tasks type="jobs" element={element[0]} />
            {/* pending: stage with state variable */}
            {stage !== 'complete' &&
              (askareyousure ? (
                <button
                  type="submit"
                  id="button"
                  className={`btn-animate ${isloading ? 'onclic' : null} ${
                    !isloading && completed ? 'validate' : null
                  } ${iserror ? 'novalidate' : null}`}
                ></button>
              ) : (
                <button
                  onClick={ask}
                  id="ask"
                  className={`btn-animate ask`}
                ></button>
              ))}
          </Box>
        </Card>
      </form>
      {/* dialog update budget */}
      <form autoComplete="off">
        <Dialog
          open={opendialog}
          onClose={handleClosedialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="div">Update Budget</Typography>
              <IconButton onClick={handleClosedialog} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <JUBudgetDetails
                budget={state.allotted_budget}
                estimated_time={element[0].estimated_time}
                estimated_ftc={element[0].estimated_ftc}
                available_for_estimate={element[0].available_for_estimate}
                containerID={element[0].container_ref}
                elementID={element[0]._id}
                showForm={state.budget_form}
                sendChildToParent={sendChildToParent}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </form>
    </>
  );
};

export default ProfileDetails;
