import React from 'react';
import { createRoot } from 'react-dom/client';

import { ApolloProvider } from '@apollo/client';

import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import client from './apollo';
import './styles/Main.scss';

import ScrollToTop from './utils/ScrollToTop';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
if (module.hot) module.hot.accept();
