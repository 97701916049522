import React, { Component, useState } from 'react';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { GoogleLogin, GoogleLogout } from 'react-google-login';
// import { refreshTokenSetup } from '../utils/refreshToken';

import GoogleIcon from 'src/icons/Google';
import { CLIENT_ID, SCOPES } from './GoogleApiAccess';

function GoogleApiLogin(props) {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const onLoginSuccessful = res => {
    const parentProps = props;
    const googleToken = res.tokenId;
    const gResp = res.profileObj;

    const response = axios
      .post('googleLogin', {
        googleTokenID: googleToken
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('authToken', response.data.token);

          const googleUser = JSON.stringify({
            firstName: gResp.givenName,
            lastName: gResp.familyName,
            email: gResp.email,
            thumbnail: gResp.imageUrl
          });

          localStorage.setItem('googleUser', googleUser);
          setTimeout(function() {
            parentProps.onChange(true);
          }, 1000);
        }
      })
      .catch(function(error) {
        parentProps.onChange(false);
        return error;
      });
  };

  const onLoginFailed = res => {
    setIsSignedIn(false);
  };

  // const onLogOut = res => {
  //   setIsSignedIn(false);
  // };

  const button = (
    <GoogleLogin
      clientId={CLIENT_ID}
      render={renderProps => (
        <Button
          fullWidth
          disabled={renderProps.disabled}
          startIcon={<GoogleIcon />}
          onClick={renderProps.onClick}
          size="large"
          variant="contained"
        >
          Login with Google
        </Button>
      )}
      approvalPrompt="force"
      prompt="consent"
      disabled={isSignedIn}
      buttonText="Login"
      onSuccess={onLoginSuccessful}
      onFailure={onLoginFailed}
      cookiePolicy={'single_host_origin'}
      scope={SCOPES}
    />
  );
  return <div>{button}</div>;
}

export default GoogleApiLogin;
