import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
const Ratecards = gql`
  query ClientRatecard($client_ref: ID) {
    ClientRatecard(client_ref: $client_ref) {
      _id
      subscription_key
      title
      version
      parent_reference
      client_reference
      client_name
      container_reference
      note
      stage
      approved
      approved_by_reference
      approval_date
      parent_title
      container_title
      createdAt
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    avatar: {
      marginRight: theme.spacing(2)
    },
    CloneButton: {
      marginRight: theme.spacing(1),
      marginLeft: 'auto'
    }
  };
});

const Ratecard = ({ className, element, ...rest }) => {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();

  //   cloneClientRatecard
  const cloneClientRatecard = gql`
    mutation cloneClientRatecard($ref: ID!, $name: String!) {
      cloneClientRatecard(ref: $ref, name: $name) {
        _id
      }
    }
  `;
  const [clone_Ratecard, { error: mutationError }] = useMutation(
    cloneClientRatecard,
    {
      onCompleted(data) {
        navigate('/ratecards/ratecard/' + data.cloneClientRatecard._id);
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  // get clients
  const {
    loading: loadingRatecards,
    data: dataRatecards,
    error: errorRatecards,
    refetch
  } = useQuery(Ratecards, {
    variables: { client_ref: element[0]._id }
  });

  // callback for clients query
  useEffect(() => {
    const onCompleted = dataRatecards => {
      //console.log('dataRatecards', dataRatecards.ClientRatecard.client_name);
    };
    const onError = errorRatecards => {
      console.log('query errorRatecards', errorRatecards);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRatecards && !errorRatecards) {
        onCompleted(dataRatecards);
      } else if (onError && !loadingRatecards && errorRatecards) {
        onError(errorRatecards);
      }
    }
  }, [loadingRatecards, dataRatecards, errorRatecards]);

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* <TableCell>Version</TableCell> */}
              <TableCell>Client Name</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>Date Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRatecards && (
              <TableRow hover key={dataRatecards.ClientRatecard._id}>
                <TableCell>
                  <Box alignItems="center" display="flex">
                    <Avatar
                      className={classes.avatar}
                      src={dataRatecards.ClientRatecard.avatarUrl}
                    >
                      {getInitials(dataRatecards.ClientRatecard.title)}
                    </Avatar>
                    <Typography color="textPrimary" variant="body1">
                      <Link
                        to={{
                          pathname: `/ratecards/ratecard/${dataRatecards.ClientRatecard._id}`
                        }}
                      >
                        {dataRatecards.ClientRatecard.title}
                      </Link>
                    </Typography>
                  </Box>
                </TableCell>
                {/* <TableCell>
                  <Typography color="textPrimary" variant="body1">
                    {dataRatecards.ClientRatecard.version}
                  </Typography>
                </TableCell> */}

                <TableCell>
                  <Typography color="textPrimary" variant="body1">
                    {dataRatecards.ClientRatecard.client_name
                      ? dataRatecards.ClientRatecard.client_name
                      : 'N / A'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="body1">
                    {dataRatecards.ClientRatecard.stage}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="body1">
                    {moment
                      .unix(dataRatecards.ClientRatecard.createdAt * 0.001)
                      .format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          variant="contained"
          className={classes.addButton}
          onClick={e => {
            e.preventDefault();
            clone_Ratecard({
              variables: {
                ref: element[0]._id,
                name: element[0].name
              }
            });
          }}
        >
          Clone Ratecard
        </Button>
      </Box>
    </Card>
  );
};

Ratecard.propTypes = {
  className: PropTypes.string
};

export default Ratecard;
