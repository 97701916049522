import React from 'react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import TableChartIcon from '@mui/icons-material/TableChart';
import ArchiveIcon from '@mui/icons-material/Inventory';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const GoogleDriveIcons = ({ type }) => {
  switch (type) {
    case 'application/vnd.google-apps.folder':
      return <FolderOpenIcon />;
    case 'application/pdf':
      return <PictureAsPdfIcon />;
    case 'application/vnd.google-apps.drawing':
    case 'image/x-photoshop':
      return <ImageIcon />;
    case 'application/vnd.google-apps.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <DescriptionIcon />;
    case 'application/vnd.google-apps.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <ViewCarouselIcon />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.google-apps.spreadsheet':
      return <TableChartIcon />;
    case 'application/zip':
      return <ArchiveIcon />;
    default:
      return <InsertDriveFileIcon />;
  }
};

export default GoogleDriveIcons;
