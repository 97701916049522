import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_BASE_URL + '/graphql'
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authToken');
  return {
    headers: {
      ...headers,
      'x-access-token': token ? `${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (
        extensions &&
        extensions.exception &&
        (extensions.exception.code === 16 ||
          extensions.code === 'UNAUTHENTICATED')
      ) {
        // UNAUTHENTICATED
        response.errors = null;
        console.log(`[Error]: UNAUTHENTICATED`);
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache()
});
