import React, { useContext, useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import GoogleApiLogin from 'src/components/googleComponents/GoogleApiLogin';
import AuthContext from 'src/components/AuthContext';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

function LoginView(props) {
  const { classes } = useStyles();
  const { loginUser, logoutUser, userData } = useContext(AuthContext);
  const [error, setError] = useState();

  const loginStatus = userData && userData._id ? true : false;

  const handleSubmit = async values => {
    const response = await axios
      .post('login', {
        email: values.email,
        password: values.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('authToken', response.data.token);
          loginUser();
        } else {
          setError(response.data);
        }
        return response.data;
      })
      .catch(function(error) {
        setError(error);
        return error;
      });
    return response;
  };

  const logoutPage = (
    <Page className={classes.root} title="Logout">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h2">
            Log out
          </Typography>
          <Box my={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                logoutUser();
              }}
            >
              Log out
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
  // var loginPage =
  const loginPage = (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: 'demo@reci.pe',
              password: 'Password123'
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              const resp = await handleSubmit(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12}>
                    <GoogleApiLogin
                      onChange={() => {
                        loginUser();
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Box mt={3} mb={1}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    or login with email address
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {error && (
                  <Box my={2}>
                    <Alert severity="error">
                      <AlertTitle>{error}</AlertTitle>
                    </Alert>
                  </Box>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {!isSubmitting ? 'Sign in now' : 'Loading...'}
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?{' '}
                  <Link component={RouterLink} to="/register" variant="h6">
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        minHeight: '75vh'
      }}
    >
      {loginStatus ? logoutPage : loginPage}
    </div>
  );
}

export default LoginView;
