import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Client, updateRatecard } from 'src/queries/query';

const ProfileDetails = ({ className, element, ...rest }) => {
  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);

  const [client_reference, setclient_reference] = useState('');
  const [approved, setapproved] = useState(element[0].approved);

  const timer = React.useRef();
  const timer2 = React.useRef();
  const timer3 = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const [
    update_Ratecard,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateRatecard, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
      }, 2500);
    }
  });

  return (
    <form
      autoComplete="off"
      {...rest}
      onSubmit={e => {
        e.preventDefault();
        update_Ratecard({
          variables: {
            ref: element[0]._id,
            data: {
              title: e.target.title.value,
              client_reference: client_reference ? client_reference : null,
              client_name: e.target.client_name.value
                ? e.target.client_name.value
                : null,
              note: e.target.note.value
            }
          }
        });
        setIsloading(true);
      }}
    >
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title={'Ratecard - [' + element[0].stage + ']'}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                required
                defaultValue={element[0].title}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Query query={Client}>
                {({ loading, data }) =>
                  !loading && (
                    <Autocomplete
                      id="clientlist"
                      disabled
                      defaultValue={data.Client.find(
                        element1 => element1.name === element[0].client_name
                      )}
                      options={data.Client}
                      getOptionLabel={option => option.name}
                      onChange={(event, values) => {
                        if (values) {
                          setclient_reference(values._id);
                        } else {
                          setclient_reference('');
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Client Name"
                          name="client_name"
                          variant="outlined"
                        />
                      )}
                    />
                  )
                }
              </Query>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Note"
                name="note"
                type="textarea"
                variant="outlined"
                defaultValue={element[0].note}
                disabled={approved}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {!approved && (
            <button
              type="submit"
              id="button"
              className={`btn-animate ${
                !mutationError && isloading ? 'onclic' : null
              } ${
                !mutationError && !isloading && completed ? 'validate' : null
              }`}
            ></button>
          )}
        </Box>
      </Card>
    </form>
  );
};

export default ProfileDetails;
