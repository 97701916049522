import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';

import { Mutation } from '@apollo/client/react/components';

import { addContact } from 'src/queries/query';

const ProfileDetailsContact = ({ className, id, onContactAdd, ...rest }) => {
  return (
    <Mutation mutation={addContact}>
      {(addContact, { loading, error }) => (
        <form
          autoComplete="off"
          noValidate
          {...rest}
          onSubmit={e => {
            e.preventDefault();
            addContact({
              variables: {
                ref: id,
                data: {
                  email: e.target.email.value,
                  first_name: e.target.first_name.value,
                  last_name: e.target.last_name.value,
                  phone: e.target.phone.value,
                  mobile: e.target.mobile.value,
                  linkedin: e.target.linkedin.value
                }
              }
            });
            try {
              onContactAdd({
                email: e.target.email.value,
                first_name: e.target.first_name.value,
                last_name: e.target.last_name.value
              });
            } catch (err) {
              // console.log(err);
            }
          }}
        >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
              title="Add New Client Contact"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Client First Name"
                    name="first_name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Client Last Name"
                    name="last_name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Linkedin"
                    name="linkedin"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Mutation>
  );
};

export default ProfileDetailsContact;
