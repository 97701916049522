import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMutation, useLazyQuery } from '@apollo/client';
import { updateJobContainer } from 'src/queries/query';
import gql from 'graphql-tag';
import GoogleDriveFolder from '../../../components/googleComponents/GoogleDriveFolder';

const JobsContainers = gql`
  query JobsContainers($id: ID) {
    JobsContainers(_id: $id) {
      _id
      cloud_portal
    }
  }
`;

export default function CloudPortal(props) {
  //   const folderID = props.job.cloud_portal ? props.job.cloud_portal : '';
  const folderName = props.job.title ? props.job.title : '';
  const jobID = props.job._id ? props.job._id : '';
  const parent = props.parent ? props.parent : 'Uploads';
  const container_ref = props.job.container_ref;

  const [folderID, setFolderID] = useState(props.job.cloud_portal);
  const [parentID, setParentID] = useState(props.parentID);

  const [getParent, { loading, error, data }] = useLazyQuery(JobsContainers, {
    variables: { id: container_ref }
  });

  useEffect(() => {
    if (data !== undefined) {
      const getParentID = data.JobsContainers[0].cloud_portal;
      // console.log('parentID', parentID);
      // console.log('jobContainer', data.JobsContainers[0].cloud_portal);
      setParentID(getParentID);
    }
  }, [data]);
  // useEffect(() => {
  //   if (jobContainer !== undefined)
  //     console.log(
  //       'jobContainer',
  //       jobContainer.data.JobsContainers[0].cloud_portal
  //     );
  //   // if (
  //   //   jobContainer &&
  //   //   !jobContainer.loading &&
  //   //   jobContainer.data &&
  //   //   jobContainer.data.JobsContainers
  //   // ) {
  //   //   // console.log(jobContainer.data.JobsContainers);
  //   //   var portalID = jobContainer.data.JobsContainers[0].cloud_portal;
  //   //   console.log('jobContainer', portalID);
  //   //   setPortalRef(portalID);
  //   // }
  // }, [jobContainer]);

  // useEffect(() => {
  //   if (!loading) {
  //     var container = data.Jobs[0].container_ref;
  //     setUserRef(container);
  //   }
  // }, [data]);

  useEffect(() => {
    if (!folderID) {
      if (!parentID) {
        getParent();
      } else {
        axios
          .get('/getFolderIDByName', {
            params: {
              folderName: folderName,
              parentID: parentID
            }
          })
          .then(rest => {
            if (rest.data) {
              addFolderID(rest.data, jobID);
              setFolderID(rest.data);
            }
          });
      }
    }
  }, [folderID, parentID]);

  const addFolderID = (folderID, jobID) => {
    update_JobContainer({
      variables: {
        ref: jobID,
        data: {
          cloud_portal: folderID
        }
      }
    });
  };

  const [
    update_JobContainer,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateJobContainer, {
    onCompleted() {},
    onError: err => {
      console.log(err);
    }
  });

  return <>{folderID && <GoogleDriveFolder folderID={folderID} />}</>;
}
