import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import TeamDetails from './TeamDetails';
import TimesheetDetails from './TimesheetDetails';
import FixedTaskCostInputDetails from './FixedTaskCostInputDetails';
import SupplierPoDetails from './SupplierPoDetails';
import TimeEstimateDetails from './TimeEstimateDetails';

import Toolbar from './Toolbar';
import CloudPortal from './CloudPortal';

const Jobs = gql`
  query Jobs($id: ID, $container_ref: String) {
    Jobs(_id: $id, container_ref: $container_ref) {
      _id
      subscription_key
      title
      url
      description
      cloud_portal
      job_type
      retainer_budget
      retained_jobs
      allotted_budget
      container_ref
      container_title
      stage
      department
      start_date
      end_date
      owner_reference
      owner_name
      ratecard_reference

      spo_switch
      spo_restriction
      supplier_po_total

      estimated_time
      estimated_ftc
      available_for_estimate

      team {
        _id
        user_reference
        user_name
        email
        designation
        jobs_designation
      }
      fixed_task_cost {
        _id
        title
        cost
        created
        user_reference
        user_name
      }
      time_estimate {
        _id
        department_name
        role
        rate
        hours
        note
        created
        user_reference
        user_name
        amount
      }
      supplier_po {
        _id
        supplier_po
        amount
        description
        suppliers_reference
        company_name
        po_by_reference
        po_by_name
        created_date
        approved
        approved_by_reference
        approved_by_name
        approved_date
        emailed
        email_date
        invoices {
          _id
          amount
          description
          invoice
          invoice_by_name
          invoice_date
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(1.5)
    }
  };
});

const Account = () => {
  const { classes } = useStyles();
  const elementId = useParams();

  const [jobstage, setJobstage] = useState('');
  const [jobBudget, setJobBudget] = useState('');

  const { data, loading } = useQuery(Jobs, {
    variables: { id: elementId.id }
  });

  useEffect(() => {
    const onCompleted = data => {
      setJobstage(data.Jobs[0].stage);
      setJobBudget(data.Jobs[0].allotted_budget);
    };
    if (onCompleted) {
      if (onCompleted && !loading) {
        onCompleted(data);
      }
    }
  }, [data, loading]);

  const sendChildToParent = stage => {
    setJobstage(stage);
  };

  const fromProfileDetails = budget => {
    setJobBudget(budget);
  };

  return (
    <Page elevation={0} className={classes.root} title="Job Unit">
      {!loading && data && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Profile element={data.Jobs} />
            </Grid>
            <Grid item xs={4}>
              <Toolbar
                id={data.Jobs[0]._id}
                stage={data.Jobs[0].stage}
                sendChildToParent={sendChildToParent}
              />
            </Grid>
            <Grid item xs={12}>
              <ProfileDetails
                element={data.Jobs}
                stage={jobstage}
                fromProfileDetails={fromProfileDetails}
              />
            </Grid>

            <Grid item xs={12}>
              <TeamDetails element={data.Jobs} stage={jobstage} />
            </Grid>

            <Grid item xs={12}>
              <TimesheetDetails element={data.Jobs} stage={jobstage} />
            </Grid>
            <Grid item xs={12}>
              <SupplierPoDetails
                job={data.Jobs[0]}
                jobBudgetstate={jobBudget}
                ftcTotal={data.Jobs[0].estimated_ftc}
              />
            </Grid>

            {(jobstage === 'live' || jobstage === 'complete') && (
              <>
                <Grid item xs={12}>
                  <FixedTaskCostInputDetails
                    element={data.Jobs}
                    stage={jobstage}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TimeEstimateDetails
                    element={data.Jobs}
                    stage={jobstage}
                    updatedsingleAllocated={jobBudget}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <CloudPortal job={data.Jobs[0]} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default Account;
