import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import gql from 'graphql-tag';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from 'tss-react/mui';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import TextEditor from 'src/components/TextEditor';
import AuthContext from 'src/components/AuthContext';

import currencyformatter from 'src/utils/currencyformatter';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import LinearProgress from '@mui/material/LinearProgress';

import { Client, addJob } from 'src/queries/query';
import ProfileDetailsContact from '../../client/AddClientContactView/ProfileDetails';

const get_ClientDetail = gql`
  query Client($id: ID) {
    Client(_id: $id) {
      contacts {
        first_name
        last_name
        email
      }
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    },
    marginList: {
      marginRight: '10px'
    },
    marginListUnits: {
      marginLeft: '10px'
    },
    widthProgress: {
      width: '200px'
    }
  };
});

const ProfileDetails = ({ className, id, container, ...rest }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const { data: dataClient } = useQuery(get_ClientDetail, {
    variables: { id: container.client.client_ref }
  });

  const contactsResults = useQuery(Client, {
    variables: { id: container.client.client_ref }
  });

  const [activeContact, setActiveContacts] = useState();
  const [contacts, setContacts] = useState([]);

  const myDefaultOption = {
    first_name: 'Add New',
    last_name: '...',
    email: 'addnew'
  };
  useEffect(() => {
    if (!contactsResults.loading) {
      const newContacts = contactsResults.data.Client[0].contacts;
      // const newContactsList = [...newContacts, myDefaultOption];
      setContacts(newContacts);
    }
  }, [contactsResults.data]);

  let total_allocated = 0;
  container.units.forEach(item => {
    total_allocated += item.allocated_budget;
  });

  const [state, setState] = useState({
    approved: true,
    stage: 'live',
    department_reference: '',
    contact_email: '',
    allotted_budget: 0,
    state_total_allocated: total_allocated,
    total_budgetmodal: container.total_budget,
    stagecompleted: container.stage === 'complete',
    pre_to_live_date: container.pre_to_live_date
      ? new Date(parseInt(container.pre_to_live_date))
      : '',
    complete_date: container.complete_date
      ? new Date(parseInt(container.complete_date))
      : '',
    owner_reference: container.owner_reference
      ? container.owner_reference
      : '5fd3d0a5d2522906b70ef18e',
    client_ref: container.client.client_ref
      ? container.client.client_ref
      : '5fd40586d2522906b70ef208',
    client_name: container.client.client_name
      ? container.client.client_name
      : 'Client'
  });

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [errorbudget, setErrorbudget] = useState({ state: false, msg: '' });
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [opendialog, setOpendialog] = React.useState(false);
  const [opendialogClient, setOpendialogClient] = React.useState(false);

  const handleClickOpendialog = () => {
    setOpendialog(true);
  };

  const handleClosedialog = () => {
    if (errorbudget.state) {
      setState({
        ...state,
        allotted_budget: 0
      });
    }
    setErrorbudget({ msg: '', state: false });
    setOpendialog(false);
    setOpendialogClient(false);
  };

  const handleClickOpendialogClient = () => {
    setOpendialogClient(true);
  };

  const handleChangeBudget = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
    // to close the budget model

    if (
      event.target.value >
      state.total_budgetmodal - state.state_total_allocated
    ) {
      setErrorbudget({ msg: 'Error in budget', state: true });
    } else {
      setErrorbudget({ msg: '', state: false });
    }
  };

  const handleChangeBudget1 = event => {
    if (event.target.value === '0') {
      event.target.value = '';
    }
  };

  const submitBudget = e => {
    e.preventDefault();
    console.log('state value', errorbudget.state);
    // if error keep dialog open
    setOpendialog(errorbudget.state);
  };

  const [
    add_Job,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addJob, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        // navigate('/jobs');
        navigate(-1);
      }, 2500);
    },
    onError: err => {
      setError(String(err).replace('Error: GraphQL error: ', ''));
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [jobDesc, setJobDesc] = useState('');

  const textEditorOutput = textoutput => {
    //setting returned value to the jobdec state variable
    setJobDesc(textoutput);
  };

  const _filterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state);

    if (!results.includes(myDefaultOption)) {
      results.push(myDefaultOption);
    }

    return results;
  };

  const [inputValueTemp, setInputValueTemp] = useState('');
  const [inputValue, setInputValue] = useState('');
  const onContactAdd = contact => {
    if (contact) {
      const contName = contact.first_name + ' ' + contact.last_name;

      setState({
        ...state,
        contact_email: contact.email
      });
      setInputValueTemp(contName);
      setInputValue(contName);

      contactsResults.refetch();
      handleClosedialog();
    }
  };

  return (
    <>
      <Collapse in={mutationError || open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Error</AlertTitle>
          {mutationError ? String(mutationError).replace('Error: ', '') : null}
        </Alert>
      </Collapse>

      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_Job({
            variables: {
              ref: '',
              data: {
                subscription_key: '5df7b2704fa93209ad8eca03',
                title: e.target.title.value,
                description: jobDesc,
                allotted_budget: parseFloat(state.allotted_budget),
                container_ref: id,
                container_title: container.title,
                owner_reference: userData._id,
                owner_name: userData.first_name + ' ' + userData.last_name,
                ratecard_reference: container.ratecard,
                client: {
                  client_ref: container.client.client_ref,
                  client_name: container.client.client_name,
                  contact_name: e.target.contact_name.value,
                  contact_email: state.contact_email,
                  client_po: e.target.client_po.value
                }
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Add New Job Unit"
            classes={{
              action: classes.cardAction
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  label="Job title"
                  name="title"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      required
                      label="Allotted Budget"
                      name="allotted_budget"
                      value={state.allotted_budget}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                      onClick={handleClickOpendialog}
                    />
                  </Grid>

                  <Grid item>
                    <EditRoundedIcon onClick={handleClickOpendialog} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextEditor textEditorOutput={textEditorOutput} />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="ClientContactlist"
                  options={contacts}
                  filterOptions={filterOptions}
                  getOptionLabel={option =>
                    option.first_name + ' ' + option.last_name
                  }
                  onChange={(event, values) => {
                    if (values.email === 'addnew') {
                      handleClickOpendialogClient();
                    }
                    if (values) {
                      setState({
                        ...state,
                        contact_email: values.email
                      });
                    } else {
                      setState({
                        ...state,
                        contact_email: ''
                      });
                    }
                  }}
                  noOptionsText={
                    <Box
                      onMouseDown={() => {
                        handleClickOpendialogClient();
                      }}
                    >
                      Add new ...
                    </Box>
                  }
                  inputValue={inputValue}
                  onHighlightChange={(event, newInputValue) => {
                    setInputValueTemp(null);
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (inputValueTemp) {
                      setInputValue(inputValueTemp);
                    } else {
                      setInputValue(newInputValue);
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Client Contact Name"
                      name="contact_name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Client PO"
                  name="client_po"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Button
                  sx={{ mt: 1 }}
                  onClick={handleClickOpendialogClient}
                  variant="contained"
                >
                  Add a new Client Contact
                </Button>
              </Grid> */}
            </Grid>
          </CardContent>
          <Divider />

          {/* <Divider /> */}
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
      <Dialog
        open={opendialogClient}
        onClose={handleClosedialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'sm'}
      >
        <ProfileDetailsContact
          id={container.client.client_ref}
          onContactAdd={onContactAdd}
        />
      </Dialog>
      {/* dialog update budget */}
      <form autoComplete="off">
        <Dialog
          open={opendialog}
          onClose={handleClosedialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle id="alert-dialog-title">{'Update Budget'}</DialogTitle>
          <DialogContent>
            <div className={classes.demo}>
              <List>
                <ListItem>
                  <ListItemIcon className={classes.marginList}>
                    Container Budget
                  </ListItemIcon>
                  <ListItemText
                    primary={currencyformatter.format(state.total_budgetmodal)}
                  />
                </ListItem>
                <Divider component="li" />
                {container.units[0].jobunit_ref.length > 0 && (
                  <>
                    <ListItem>
                      <ListItemIcon className={classes.marginList}>
                        Allocated Budget for Job Units
                      </ListItemIcon>
                    </ListItem>

                    {container.units
                      .filter(item => item.jobunit_ref[0].job_type === 'Normal')
                      .map((item, index) => (
                        <div key={index} className={classes.marginListUnits}>
                          <ListItem>
                            <ListItemIcon className={classes.marginList}>
                              {item.jobunit_ref[0].title}
                            </ListItemIcon>
                            <ListItemText
                              primary={currencyformatter.format(
                                item.allocated_budget
                              )}
                            />
                            <ListItemSecondaryAction>
                              <LinearProgress
                                className={classes.widthProgress}
                                value={
                                  (item.allocated_budget * 100) /
                                  state.total_budgetmodal
                                }
                                variant="determinate"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      ))}
                    <Divider component="li" />
                  </>
                )}
                <ListItem>
                  <ListItemIcon className={classes.marginList}>
                    Remaining Budget
                  </ListItemIcon>
                  <ListItemText
                    primary={currencyformatter.format(
                      state.total_budgetmodal -
                        state.state_total_allocated -
                        state.allotted_budget
                    )}
                  />
                  <ListItemSecondaryAction>
                    {/* *** revisit*/}
                    <LinearProgress
                      className={classes.widthProgress}
                      value={
                        ((state.total_budgetmodal -
                          state.state_total_allocated -
                          state.allotted_budget) *
                          100) /
                        state.total_budgetmodal
                      }
                      variant="determinate"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
            <br />
            <TextField
              fullWidth
              error={errorbudget.state}
              helperText={errorbudget.msg}
              required
              label="Allotted Budget"
              name="allotted_budget"
              variant="outlined"
              defaultValue={state.allotted_budget}
              onChange={handleChangeBudget}
              onClick={handleChangeBudget1}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosedialog} color="primary">
              Close
            </Button>
            <Button onClick={submitBudget} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default ProfileDetails;
