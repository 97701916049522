import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: '100%'
    },
    avatar: {
      backgroundColor: '#FB656B',
      height: 56,
      width: 56,
      marginTop: '10px',
      marginBottom: '-10px'
    },
    avatarDone: {
      backgroundColor: '#3AD9B4',
      height: 56,
      width: 56,
      marginTop: '10px',
      marginBottom: '-10px'
    },
    progress: {
      '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#A2EDDE'
      },
      '& .MuiLinearProgress-bar1Determinate': {
        backgroundColor: '#3AD9B4'
      }
    }
  };
});

const maxHours = 37.5;

const TasksProgress = ({ className, progress, weekChange, ...rest }) => {
  const prg = progress ? progress : 0;
  const { classes, cx } = useStyles();
  const [weekText, setWeekText] = useState('');
  const [currentDay, setCurrentDay] = useState(moment());

  useEffect(() => {
    changeWeek(0);
  }, []);

  const changeWeek = n => {
    const newDay = moment(currentDay).add(n, 'week');
    const md = newDay.day('monday');
    setCurrentDay(md);
    setWeek(md);
    weekChange(md);
  };

  const setWeek = week => {
    const thisDay = moment(week);
    const md = thisDay.day('monday');
    const monday = md.format('D MMM');

    const fd = thisDay.day('friday');
    const friday = fd.format('D MMM');

    const fullWeek = monday + ' - ' + friday;

    setWeekText(fullWeek);
  };

  const totalHours = Math.min(prg, maxHours) / maxHours;

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <CardContent>
        <Box
          style={{
            display: 'flex',
            paddingBottom: '5px'
          }}
        >
          <Typography
            style={{
              fontSize: '0.875rem',
              textTransform: 'uppercase',
              fontWeight: '500'
            }}
          >
            Week: {weekText}
          </Typography>
          <ButtonGroup
            color="primary"
            variant="contained"
            size="small"
            style={{ marginLeft: 'auto' }}
          >
            <Button
              style={{
                padding: '5px 0 5px 7px',
                border: 'none',
                minWidth: '28px'
              }}
              onClick={() => changeWeek(-1)}
            >
              <ArrowBackIosIcon style={{ fontSize: '16px' }} />
            </Button>
            <Button
              style={{
                padding: '5px 5px 5px',
                border: 'none',
                minWidth: '28px'
              }}
              onClick={() => changeWeek(1)}
            >
              <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
            </Button>
          </ButtonGroup>
        </Box>
        <Grid container spacing={3}>
          <Grid item style={{ marginRight: 'auto' }}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Total Time
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {prg}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={totalHours < 1 ? classes.avatar : classes.avatarDone}
            >
              {totalHours < 1 ? <InsertChartIcon /> : <InsertEmoticonIcon />}
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={totalHours * 100}
            variant="determinate"
            className={totalHours < 1 ? '' : classes.progress}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;
