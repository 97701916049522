import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { useMutation, useLazyQuery } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import gql from 'graphql-tag';
import moment from 'moment';
import TimesheetForm from '../../reports/DashboardView/TimeSheets/TimesheetForm';

// Setting Up Queries

const Users = gql`
  query Users {
    Users {
      _id
      first_name
      last_name
      email
      designation
    }
  }
`;
const TimesheetQuery = gql`
  query Timesheet($job_reference: String!) {
    Timesheet(job_reference: $job_reference) {
      _id
      subscription_key
      user_reference
      user_name
      user_designation
      job_container_reference
      job_reference
      jobs_designation
      note
      user_rate
      hours
      amount
      for_week
      created
      job_state
      job_info {
        title
        client {
          client_name
        }
      }
      tags
    }
  }
`;
const addTimesheet = gql`
  mutation addTimesheet(
    $job_reference: String!
    $note: String
    $hours: Float!
    $mm_dd_yyyy: String!
  ) {
    addTimesheet(
      data: {
        job_reference: $job_reference
        note: $note
        mm_dd_yyyy: $mm_dd_yyyy
        hours: $hours
      }
    ) {
      _id
    }
  }
`;
const updateTimesheet = gql`
  mutation updateTimesheet(
    $ref: ID!
    $note: String
    $hours: Float!
    $mm_dd_yyyy: String! #
  ) {
    updateTimesheet(
      ref: $ref
      data: { note: $note, mm_dd_yyyy: $mm_dd_yyyy, hours: $hours }
    ) {
      _id
    }
  }
`;
const removeTimesheet = gql`
  mutation removeTimesheet($ref: ID!) {
    removeTimesheet(ref: $ref) {
      _id
    }
  }
`;

const TimesheetDetails = ({ className, element, stage, ...rest }) => {
  const [timeSheetList, setTimeSheetList] = useState([]);
  const [getData, { loading, refetch, data }] = useLazyQuery(TimesheetQuery);

  useEffect(() => {
    getData({
      variables: { job_reference: element[0]._id }
    });
  }, []);

  useEffect(() => {
    if (!loading && data) setTimeSheetList(data.Timesheet);
  }, [loading, data]);

  // const TimeSheetResult = useQuery(TimesheetQuery, {
  //   variables: { job_reference: element[0]._id }
  // });

  // useEffect(() => {
  //   if (!TimeSheetResult.loading) {
  //     setTimeSheetList(TimeSheetResult.data.Timesheet);
  //   }
  // }, [[ ].data]);

  // Default form's state values for adding a team member

  const formDefaults = {
    title: '',
    cost: 0
  };
  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [formState, setFormState] = useState(formDefaults);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };
  const [activeRow, setActiveRow] = useState('');
  const [job_id, setJob_id] = useState('');
  const [job_title, setJob_title] = useState('');
  const [open, setOpen] = useState(false);
  const [var_operation, setVar_operation] = useState('Add');

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.TimesheetDetails === false ? false : true
  );

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [editRow, setEditRow] = useState(false);

  //-----------------------
  const [rowID, setrowID] = useState(null);
  const [openDel, setOpenDel] = useState(false);

  //-----------------------

  const jobref = element[0]._id;

  const handleClickOpenEdit = row => {
    setJob_id(element[0]._id);
    setJob_title(element[0].title);
    setActiveRow('');
    setActiveRow(row);
    setOpen(true);
    setVar_operation('Edit');
  };
  const handleClickOpenAdd = row => {
    setJob_id(row.job_id);
    setJob_title(row.title);
    setActiveRow(row);
    setOpen(true);
    setVar_operation('Add');
  };
  const handleClose = () => {
    setActiveRow('');
    setOpen(false);
  };
  //---------------------------------------
  // Remove
  //---------------------------------------
  const handleClickOpenDel = id => {
    setrowID(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setrowID(null);
    setOpenDel(false);
  };
  const postoperationfnc = () => {
    console.log('postoperationfnc called');
    if (!loading && data) refetch();
  };

  const handleYesDel = () => {
    if (rowID) {
      console.log(' delete row called for ' + rowID);
      remove_Timesheet({
        variables: {
          ref: rowID
        }
      });
    }
    setOpenDel(false);
  };

  const [remove_Timesheet] = useMutation(removeTimesheet, {
    onCompleted() {
      console.log('row deleted');
      handleCloseDel();
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
    }
  });
  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.TimesheetDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  //---------------------------------------
  // Remove Ends
  //---------------------------------------

  return (
    <Card>
      <CardHeader
        // subheader="The information can be edited"
        title="Time Sheet"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {timeSheetList.length > 0 && (
          <>
            <Divider />
            <Grid item md={12} xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Week</TableCell>
                    <TableCell>Notes</TableCell>
                    {stage !== 'complete' && <TableCell></TableCell>}
                  </TableRow>
                  {timeSheetList.map((item, i) => (
                    <TableRow hover key={item.user_name}>
                      <TableCell>{item.user_name}</TableCell>
                      <TableCell>{item.hours}</TableCell>
                      <TableCell>
                        {moment(item.year)
                          .week(item.for_week)
                          .day('monday')
                          .format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>{item.note}</TableCell>
                      {stage !== 'complete' && (
                        <TableCell>
                          <IconButton aria-label="edit" size="small">
                            <DeleteIcon
                              onClick={() => handleClickOpenDel(item._id)}
                              style={{ cursor: 'pointer' }}
                            />
                          </IconButton>
                          <IconButton aria-label="edit" size="small">
                            <EditRoundedIcon
                              onClick={() => handleClickOpenEdit(item)}
                            />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableHead>
              </Table>
            </Grid>
          </>
        )}

        {/* Dialog For Delete Confirmation */}
        <Dialog
          open={openDel}
          onClose={handleCloseDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this row
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDel} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleYesDel} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog For Delete Confirmation End */}

        {/* <Divider /> */}
        {stage !== 'complete' && ( // Timesheet can be added only if the job is live
          <CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleClickOpenAdd({
                    job_id: element[0]._id,
                    title: element[0].title
                  })
                }
              >
                Add Timesheet
              </Button>
            </Box>
            <Divider />
          </CardContent>
        )}
        {activeRow && (
          <TimesheetForm
            open={open}
            handleClose={handleClose}
            activeRow={activeRow}
            operation={var_operation}
            postoperationfnc={postoperationfnc}
            job_id_param={job_id}
            job_title_param={job_title}
          />
        )}
        {/* <Divider /> */}
      </Collapse>
    </Card>
  );
};

export default TimesheetDetails;
