import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const Suppliers = gql`
  query SuppliersList($search: supplierListArgs, $ops: functionalArgs) {
    SuppliersList(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        company_name
        first_name
        last_name
        email
        phone
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  // get Suppliers
  const {
    loading: loadingSuppliers,
    data: dataSuppliers,
    error: errorSuppliers,
    refetch
  } = useQuery(Suppliers, {
    variables: { ops: { page: 0, perpage: 50 } }
  });

  // callback for Suppliers query
  useEffect(() => {
    const onCompleted = dataSuppliers => {
      setLength(dataSuppliers.SuppliersList.info.total_records);
    };
    const onError = errorSuppliers => {
      console.log('query errorSuppliers', errorSuppliers);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingSuppliers && !errorSuppliers) {
        onCompleted(dataSuppliers);
      } else if (onError && !loadingSuppliers && errorSuppliers) {
        onError(errorSuppliers);
      }
    }
  }, [loadingSuppliers, dataSuppliers, errorSuppliers]);

  const handleLimitChange = event => {
    setPage(0);
    setLimit(event.target.value);
    refetch({
      ops: { page: 0, perpage: event.target.value }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    refetch({
      ops: { page: newPage, perpage: limit }
    });
  };

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Company Name </TableCell>
              <TableCell> Contact Name </TableCell>
              <TableCell> Email </TableCell>
              <TableCell> Phone </TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSuppliers &&
              dataSuppliers.SuppliersList.data.map(element => (
                <TableRow hover key={element._id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={element.avatarUrl}
                      >
                        {getInitials(element.company_name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <Link to={{ pathname: `supplier/${element._id}` }}>
                          {element.company_name}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {element.first_name} {element.last_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {element.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {element.phone}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link to={{ pathname: `supplier/${element._id}` }}>
                      <VisibilityIcon style={{ color: '#bdbdbd' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
