import gql from 'graphql-tag';

// Users

export const Users = gql`
  query Users {
    Users {
      _id
      subscription_key
      email
      first_name
      last_name
      designation
      rate
      tags
      address
      user_level {
        create
        read
        update
        delete
        execute
      }
      active
    }
  }
`;

export const User = gql`
  query User($id: ID!) {
    User(_id: $id) {
      _id
      subscription_key
      email
      first_name
      last_name
      thumbnail
      thumbnail_id
      department
      designation
      rate
      cost
      holidays
      days_in_lieu
      tags
      address
      phone
      active
      status
      user_level {
        create
        read
        update
        delete
        execute
      }
    }
  }
`;

export const addUser = gql`
  mutation addUser($data: UserInput!) {
    addUser(data: $data) {
      first_name
    }
  }
`;
export const updateUser = gql`
  mutation updateUser($ref: ID!, $data: UserUpdate!) {
    updateUser(ref: $ref, data: $data) {
      first_name
    }
  }
`;

// Clients

export const Clients = gql`
  query Clients {
    Clients {
      _id
      subscription_key
      name
      company_contact_info {
        email
        phone
        fax
        address_line1
        address_line2
        city
        county
        country
        postcode
        url
        fb
        twitter
        instagram
        youtube
        other
      }
      contacts {
        _id
        first_name
        last_name
        email
        phone
        mobile
        linkedin
      }
    }
  }
`;

export const Client = gql`
  query Client($id: ID) {
    Client(_id: $id) {
      _id
      name
      use_po
      company_contact_info {
        email
        phone
        fax
        address_line1
        address_line2
        city
        county
        country
        postcode
        url
        fb
        twitter
        instagram
        youtube
        other
      }
      contacts {
        _id
        first_name
        last_name
        email
        phone
        mobile
        linkedin
      }
    }
  }
`;

export const updateClient = gql`
  mutation updateClient($ref: ID!, $data: ClientInput!) {
    updateClient(ref: $ref, data: $data) {
      name
    }
  }
`;
export const addClient = gql`
  mutation addClient($data: ClientInput!) {
    addClient(data: $data) {
      name
    }
  }
`;

export const addContact = gql`
  mutation addContact($ref: ID!, $data: ClientContactInput!) {
    addContact(ref: $ref, data: $data) {
      name
    }
  }
`;

export const updateContact = gql`
  mutation updateContact(
    $ref: ID!
    $contact_id: ID!
    $data: ClientContactInput!
  ) {
    updateContact(ref: $ref, contact_id: $contact_id, data: $data) {
      _id
    }
  }
`;

export const removeClient = gql`
  mutation removeClient($id: ID!) {
    removeClient(id: $id) {
      name
    }
  }
`;

////---- Department ----////

export const Departments = gql`
  query Department($id: ID, $is_sub_department: Boolean) {
    Department(_id: $id, is_sub_department: $is_sub_department) {
      _id
      subscription_key
      parent_ref
      name
      is_sub_department
      tags
      roles {
        title
        _id
      }
    }
  }
`;

export const Department = gql`
  query Department($id: ID) {
    Department(_id: $id) {
      _id
      subscription_key
      parent_ref
      name
      is_sub_department
      tags
      roles {
        title
        _id
      }
    }
  }
`;

export const updateDepartment = gql`
  mutation updateDepartment($ref: ID!, $data: departmentInput!) {
    updateDepartment(ref: $ref, data: $data) {
      name
    }
  }
`;

export const addDepartment = gql`
  mutation addDepartment($data: departmentInput!) {
    addDepartment(data: $data) {
      name
    }
  }
`;

export const removeDepartment = gql`
  mutation removeDepartment($id: ID!) {
    removeDepartment(id: $id) {
      name
    }
  }
`;

export const updateRole = gql`
  mutation updateRole($ref: ID!, $data: SubDepartmentRolesInput!) {
    updateRole(ref: $ref, data: $data) {
      _id
    }
  }
`;

export const addRole = gql`
  mutation addRole($ref: ID!, $data: SubDepartmentRolesInput!) {
    addRole(ref: $ref, data: $data) {
      name
    }
  }
`;

/////////////////////////////////

////---- Timesheet ----////

export const Timesheet = gql`
  query Timesheet($id: ID, $job_reference: String) {
    Timesheet(_id: $id, job_reference: $job_reference) {
      _id
      subscription_key
      user_reference
      user_name
      user_designation
      job_reference
      jobs_designation
      note
      user_rate
      hours
      amount
      for_week
      created
      job_state
      tags
    }
  }
`;

export const updateupdateTimesheet = gql`
  mutation updateupdateTimesheet($ref: ID!, $data: timesheetInput!) {
    updateupdateTimesheet(ref: $ref, data: $data) {
      user_name
    }
  }
`;

export const addTimesheet = gql`
  mutation addTimesheet($data: timesheetInput!) {
    addTimesheet(data: $data) {
      user_name
    }
  }
`;

export const removeTimesheet = gql`
  mutation removeTimesheet($id: ID!) {
    removeTimesheet(id: $id) {
      user_name
    }
  }
`;

/////////////////////////////////

////---- Notes ----////

export const Notes = gql`
  query Notes($id: ID, $collectionl_ref: String) {
    Notes(_id: $id, collectionl_ref: $collectionl_ref) {
      _id
      subscription_key
      collection_name
      collectionl_ref
      user_ref {
        _id
        first_name
      }
      user_name
      note
      note_type
      tags
    }
  }
`;

export const addNote = gql`
  mutation addNote($data: notesInput!) {
    addNote(data: $data) {
      collection_name
    }
  }
`;

/////////////////////////////////

////---- Ratecard ----////

export const Ratecard = gql`
  query Ratecard($id: ID, $container_reference: String) {
    Ratecard(_id: $id, container_reference: $container_reference) {
      _id
      subscription_key
      title
      version
      parent_reference
      client_reference
      client_name
      container_reference
      note
      approved
      stage
      createdAt
      rates {
        _id
        department_reference
        department_name
        role
        perhour
      }
    }
  }
`;

export const updateRatecard = gql`
  mutation updateRatecard($ref: ID!, $data: RatecardInput!) {
    updateRatecard(ref: $ref, data: $data) {
      title
    }
  }
`;

export const addRatecard = gql`
  mutation addRatecard($data: RatecardInput!) {
    addRatecard(data: $data) {
      title
    }
  }
`;

export const removeRatecard = gql`
  mutation removeRatecard($id: ID!) {
    removeRatecard(id: $id) {
      title
    }
  }
`;

export const containerRatecard = gql`
  mutation containerRatecard($ref: ID!, $container_ref: ID!, $title: String) {
    containerRatecard(ref: $ref, container_ref: $container_ref, title: $title) {
      title
      _id
    }
  }
`;

export const addRates = gql`
  mutation addRates($ref: ID!, $data: RatesInput!) {
    addRates(ref: $ref, data: $data) {
      _id
    }
  }
`;

export const updateRates = gql`
  mutation updateRates($ref: ID!, $value: Float!) {
    updateRates(ref: $ref, value: $value) {
      _id
    }
  }
`;

export const removeRate = gql`
  mutation removeRate($ref: ID!) {
    removeRate(ref: $ref) {
      _id
    }
  }
`;

export const cloneRatecard = gql`
  mutation cloneRatecard($ref: ID!) {
    cloneRatecard(ref: $ref) {
      _id
    }
  }
`;

/////////////////////////////////

////---- Job ----////

export const JobsContainers = gql`
  query JobsContainers($id: ID) {
    JobsContainers(_id: $id) {
      _id
      subscription_key
      title
      description
      cloud_portal
      start_date
      end_date
      total_budget
      stage
      pre_to_live_date
      complete_date
      parent_ref
      owner_reference
      owner_name
      tags
      ratecard
      client {
        client_ref
        client_name
      }
    }
  }
`;

export const updateJobContainerBudget = gql`
  mutation updateJobContainerBudget($ref: ID!, $budget: Float!) {
    updateJobContainerBudget(ref: $ref, budget: $budget) {
      _id
    }
  }
`;

export const updateJobBudget = gql`
  mutation updateJobBudget($ref: ID!, $budget: Float!) {
    updateJobBudget(ref: $ref, budget: $budget) {
      _id
    }
  }
`;

export const updateJobContainerStage = gql`
  mutation updateJobContainerStage($ref: ID!, $stage: String!) {
    updateJobContainerStage(ref: $ref, stage: $stage) {
      _id
    }
  }
`;

export const updateJobContainerClient = gql`
  mutation updateJobContainerClient(
    $ref: ID!
    $data: JobContainerClientInput!
  ) {
    updateJobContainerClient(ref: $ref, data: $data) {
      _id
    }
  }
`;

export const updateJobContainer = gql`
  mutation updateJobContainer($ref: ID!, $data: JobContainerUpdateInput!) {
    updateJobContainer(ref: $ref, data: $data) {
      title
    }
  }
`;

export const addJobContainer = gql`
  mutation addJobContainer($data: JobContainerInput!) {
    addJobContainer(data: $data) {
      title
    }
  }
`;

export const removeJobContainer = gql`
  mutation removeJobContainer($id: ID!) {
    removeJobContainer(id: $id) {
      title
    }
  }
`;

export const addJob = gql`
  mutation addJob($data: JobInput!) {
    addJob(data: $data) {
      title
    }
  }
`;

export const addJobSubDocs = gql`
  mutation addJobSubDocs(
    $ref: ID!
    $subdoc: String!
    $data: jobSubDocInputType!
  ) {
    addJobSubDocs(ref: $ref, subdoc: $subdoc, data: $data) {
      title
    }
  }
`;

export const JobsRevRec = gql`
  query Jobs($id: ID) {
    Jobs(_id: $id) {
      _id
      subscription_key
      title
      allotted_budget
      container_ref
      client {
        client_ref
        client_name
      }
      revrec {
        financial_year
        amount
        note
      }
    }
  }
`;

export const Jobs = gql`
  query Jobs($id: ID, $container_ref: String) {
    Jobs(_id: $id, container_ref: $container_ref) {
      _id
      subscription_key
      title
      url
      description
      cloud_portal
      job_type
      retainer_budget
      retained_jobs
      allotted_budget
      container_ref
      container_title
      stage
      department
      start_date
      end_date
      owner_reference
      owner_name
      ratecard_reference
      supplier_po {
        _id
        supplier_po
        amount
        description
        suppliers_reference
        company_name
        po_by_reference
        po_by_name
        created_date
        approved
        approved_by_reference
        approved_by_name
        approved_date
        emailed
        email_date
        invoices {
          _id
          amount
          description
          invoice
          invoice_by_name
          invoice_date
        }
      }
    }
  }
`;

export const updateJob = gql`
  mutation updateJob($ref: ID!, $data: JobUpdateInput!) {
    updateJob(ref: $ref, data: $data) {
      title
    }
  }
`;

/////////////////////////////////

////---- Supplier ----////

export const Suppliers = gql`
  query Suppliers {
    Suppliers {
      _id
      subscription_key
      company_name
      first_name
      last_name
      email
      phone
      fax
      address_line1
      address_line2
      city
      county
      country
      postcode
      url
      other
      tags
    }
  }
`;

export const Supplier = gql`
  query Supplier($id: ID) {
    Supplier(_id: $id) {
      _id
      subscription_key
      company_name
      first_name
      last_name
      email
      phone
      fax
      address_line1
      address_line2
      city
      county
      country
      postcode
      url
      other
      tags
    }
  }
`;

export const updateSupplier = gql`
  mutation updateSupplier($ref: ID!, $data: SupplierInput!) {
    updateSupplier(ref: $ref, data: $data) {
      company_name
    }
  }
`;

export const addSupplier = gql`
  mutation addSupplier($data: SupplierInput!) {
    addSupplier(data: $data) {
      company_name
    }
  }
`;

export const removeSupplier = gql`
  mutation removeSupplier($id: ID!) {
    removeSupplier(id: $id) {
      company_name
    }
  }
`;
