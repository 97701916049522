import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    root: {
      backgroundColor: 'transparent'
    },
    avatar: {
      height: 100,
      width: 100
    },
    rootCard: {
      paddingBottom: '10px!important'
    }
  };
});

const Profile = ({ className, element, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <Card className={cx(classes.root, className)} {...rest} elevation={0}>
      <CardContent className={cx(classes.rootCard, className)}>
        <Box alignItems="start" display="flex" flexDirection="column">
          <Typography color="textPrimary" gutterBottom variant="h3">
            {element[0].title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
