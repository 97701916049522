import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Hidden,
  Typography,
  IconButton,
  Toolbar
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import Logo from 'src/components/Logo';
import AuthContext from 'src/components/AuthContext';

const useStyles = makeStyles()(theme => {
  return {
    btn: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    btn1: {
      marginRight: '12px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    avatar: {
      width: 40,
      height: 40
    }
  };
});

const user = {
  avatar: '',
  jobTitle: 'Senior Developer',
  name: 'Doriana Ciccarelli'
};

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const { classes } = useStyles();
  const [notifications] = useState([]);
  const { userData, logoutUser } = useContext(AuthContext);
  const [userAvatar, setUserAvatar] = useState(user.avatar);

  useEffect(() => {
    if (userData.thumbnail) {
      const thumbnail = JSON.parse(userData.thumbnail);
      setUserAvatar(thumbnail.src);
    }
  }, [userData]);

  return (
    <AppBar style={{ background: '#f4f6fb' }} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgDown>
          <Typography color="textSecondary" variant="body2">
            Hi, {userData.first_name}
            {/* <br />
            {userData.thumbnail} */}
            {/* {userAvatar} */}
          </Typography>
          <IconButton color="inherit" size="large">
            {/* <img className={classes.avatar} src={userAvatar} /> */}
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              src={userAvatar}
              to="/account"
            />
          </IconButton>
          <IconButton
            color="inherit"
            className={classes.btn1}
            onClick={logoutUser}
            size="large"
          >
            <InputIcon />
          </IconButton>
          <IconButton color="inherit" className={classes.btn} size="large">
            <Badge
              overlap="rectangular"
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
